import { QRCodeSVG } from "qrcode.react";
import React from "react";

export default function QRCodeBox({
  shouldOpenQRCodeBox,
  quizId,
  toggleShowQRCode,
}) {
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        toggleShowQRCode();
      }}
      className={`${
        shouldOpenQRCodeBox
          ? "rounded-xl sm:scale-90 scale-75"
          : "scale-[.22] short:scale-[0.2] p-4 rounded-2xl"
      } absolute z-10 duration-500 transition-all flex-col p-2 right-0 bottom-1 sm:bottom-2 origin-bottom-right flex items-center justify-center aspect-square bg-white hover:bg-gray-200 `}
    >
      <div className="relative w-full">
        <QRCodeSVG
          value={"https://tknight.live/battle/" + quizId}
          level="M"
          bgColor="transparent"
          className="w-full h-full rounded"
          imageSettings={{
            src: "https://tknight-media.s3.amazonaws.com/tknight_qr_code_logo.png",
            width: 40,
            height: 40,
          }}
        />
        {/* <div className="absolute hover:opacity-100 opacity-0 transition-all flex items-center justify-center inset-0 w-full h-full">
          <svg
            className="w-1/4 bg-white hover:text-red-800 hover:scale-90 transition-all rounded-full h-1/4 fill-current text-red-600"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 8.59L13.59 5L15 6.41L11.41 10L15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59Z"
            />
          </svg>
        </div> */}
      </div>
    </div>
  );
}
