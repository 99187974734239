import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { customerClient } from "../../GraphqlApolloClients";

import {
  ADD_PRODUCT_TO_CART_MUTATION,
  CHANGE_CUSTOMER_PRODUCT_QUANTITY_MUTATION,
  FETCH_CART_PRICE_SUMMARY,
  FETCH_CUSTOMER_PRODUCTS,
  FETCH_CUSTOMER_PRODUCT_BY_PRODUCT,
} from "../../util/gqlFunctions";
import PieTimer from "./PieTimer";

export default function ProductAdBox({
  product,
  size,
  isMainAd,
  nextQStartsAt,
  lastXSeconds,
  triviaNight,
  customer,
}) {
  let navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const [addProductToCart, { loading: loadingAddProductToCart }] = useMutation(
    ADD_PRODUCT_TO_CART_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_CUSTOMER_PRODUCT_BY_PRODUCT,
          variables: { productId: product.id },
        },
        {
          query: FETCH_CUSTOMER_PRODUCTS,
          variables: { status: 0 },
        },
        {
          query: FETCH_CART_PRICE_SUMMARY,
          variables: { deliverySpeed: 0 },
        },
      ],
      update(_, { data: { addProductToCart: productAddedToCart } }) {
        setErrors({});
      },
      onError(err) {
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: customerClient,
      variables: { productId: product.id },
    }
  );

  const [
    changeCustomerProductQuantity,
    { loading: loadingChangeCustomerProductQuantity },
  ] = useMutation(CHANGE_CUSTOMER_PRODUCT_QUANTITY_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_CUSTOMER_PRODUCT_BY_PRODUCT,
        variables: { productId: product.id },
      },
      {
        query: FETCH_CUSTOMER_PRODUCTS,
        variables: { status: 0 },
      },
      {
        query: FETCH_CART_PRICE_SUMMARY,
        variables: { deliverySpeed: 0 },
      },
    ],
    update(
      _,
      { data: { changeCustomerProductQuantity: changedCustomerProduct } }
    ) {
      setErrors({});
    },
    onError(err) {
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    client: customerClient,
    // variables: { customerProduct: customerProduct?customerProduct:.id },
  });

  const {
    data: { getCustomerProductByProductId: customerProduct } = {},
    // loading: loadingCustProduct,
  } = useQuery(FETCH_CUSTOMER_PRODUCT_BY_PRODUCT, {
    client: customerClient,
    variables: { productId: product.id },
  });

  return (
    <button
      className={`${isMainAd ? "w-full" : "w-3/4"} ${
        !isMainAd &&
        triviaNight &&
        (triviaNight.status === 5 || triviaNight.status === 0)
          ? "group"
          : ""
      } text-left  rounded-3xl shrink-0 relative overflow-hidden flex flex-col`}
      onClick={(e) => {
        if (triviaNight.status === 5 || triviaNight.status === 0) {
          navigate("/product/" + product.id);
        }
      }}
      disabled={
        !(
          !isMainAd &&
          triviaNight &&
          (triviaNight.status === 5 || triviaNight.status === 0)
        )
      }
    >
      <div
        className={`w-full group-hover:bg-opacity-80 transition-all bg-gray-50 p-3 shrink-0 relative flex space-x-4 items-center snap-x snap-mandatory justify-start rounded-2xl overflow-x-auto`}
      >
        {product && product.imageUrls && product.imageUrls[0] && (
          <div className="snap-center bg-white min-w-full rounded-2xl w-full overflow-hidden">
            <img
              src={product.imageUrls[0]}
              className="w-full aspect-video object-contain mr-2"
              alt={product.title}
            />
          </div>
        )}
        <p className="absolute bg-white px-3 py-1 rounded-xl font-bold text-xl sm:text-3xl top-4 right-4 sm:top-5 sm:right-5 text-right">
          ${Math.round(product.discountedPrice / 100)}
        </p>
      </div>
      <div className="flex absolute bottom-0 items-center space-x-2 justify-between pr-3 sm:pr-4 w-full">
        <div
          className={`${
            isMainAd ? "rounded-bl-none sm:-ml-2 sm:-mb-2" : ""
          } rounded-full p-1 sm:p-2 group-hover:bg-white transition-all bg-white`}
        >
          <PieTimer
            endTime={
              isMainAd
                ? nextQStartsAt
                : product && product.status === 3
                ? product.endSaleAt
                : new Date()
            }
            imgUrl={
              (product && product.businessLogoUrl) ||
              "https://tknight-media.s3.amazonaws.com/profile.png"
            }
            alt={product?.title || "product"}
            width={
              isMainAd
                ? size.width >= 640
                  ? 70
                  : 60
                : size.width >= 640
                ? 50
                : 50
            }
            lastXSeconds={isMainAd ? lastXSeconds : 0}
          />
        </div>

        {/* <p className="text-xl flex-1 truncate">
          {product ? product.title : ""}
        </p> */}

        <div
          className={`${
            isMainAd ? "sm:-mr-2 sm:-mb-2" : ""
          } flex items-center justify-center`}
        >
          {customerProduct ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                changeCustomerProductQuantity({
                  variables: {
                    customerProductId: customerProduct.id,
                    quantity: customerProduct.quantity - 1,
                  },
                });
              }}
              className={`${
                !loadingChangeCustomerProductQuantity &&
                errors &&
                Object.keys(errors).length > 0
                  ? "motion-safe:animate-shake "
                  : ""
              }  disabled:bg-red-300 disabled:hover:bg-red-300 bg-red-600 hover:bg-red-700 shrink-0 relative font-semibold overflow-hidden sm:text-lg w-full transition-all rounded-3xl sm:rounded-2xl shadow-sm text-white px-4`}
              disabled={loadingChangeCustomerProductQuantity}
            >
              <div
                className={`${
                  !loadingChangeCustomerProductQuantity &&
                  errors &&
                  Object.keys(errors).length > 0
                    ? "motion-safe:animate-fadeOut bg-red-600"
                    : ""
                } inset-0 absolute`}
              ></div>
              <div
                className={`${
                  !loadingChangeCustomerProductQuantity
                    ? "motion-safe:animate-fadeOutUpFast"
                    : "motion-safe:animate-fadeDownFast"
                } absolute inset-0 flex items-center justify-center`}
              >
                <svg
                  className={`w-6 motion-safe:animate-spin`}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.636 3.636A9 9 0 0119 10"
                    stroke="#fff"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                </svg>
              </div>

              <p
                className={`${
                  loadingChangeCustomerProductQuantity
                    ? "motion-safe:animate-fadeOutDownFast"
                    : "motion-safe:animate-fadeUpFast"
                } h-full py-1`}
              >
                Remove
              </p>
            </button>
          ) : (
            <button
              onClick={(e) => {
                if (customer) {
                  e.preventDefault();
                  e.stopPropagation();

                  addProductToCart();
                } else {
                  navigate("/register");
                }
              }}
              className={`${
                !loadingAddProductToCart &&
                errors &&
                Object.keys(errors).length > 0
                  ? "motion-safe:animate-shake "
                  : ""
              }  disabled:bg-blue-300 disabled:hover:bg-blue-300 bg-blue-600 hover:bg-blue-700 shrink-0 relative font-semibold overflow-hidden sm:text-lg w-full transition-all rounded-3xl sm:rounded-2xl shadow-sm text-white px-4`}
              disabled={loadingAddProductToCart}
            >
              <div
                className={`${
                  !loadingAddProductToCart &&
                  errors &&
                  Object.keys(errors).length > 0
                    ? "motion-safe:animate-fadeOut bg-red-600"
                    : ""
                } inset-0 absolute`}
              ></div>
              <div
                className={`${
                  !loadingAddProductToCart
                    ? "motion-safe:animate-fadeOutUpFast"
                    : "motion-safe:animate-fadeDownFast"
                } absolute inset-0 flex items-center justify-center`}
              >
                <svg
                  className={`w-6 motion-safe:animate-spin`}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.636 3.636A9 9 0 0119 10"
                    stroke="#fff"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                </svg>
              </div>

              <p
                className={`${
                  loadingAddProductToCart
                    ? "motion-safe:animate-fadeOutDownFast"
                    : "motion-safe:animate-fadeUpFast"
                } h-full py-1`}
              >
                Add to cart
              </p>
            </button>
          )}
        </div>
      </div>
    </button>
  );
}
