import React, { useEffect, useState } from "react";

function CountdownTimer({ endDate, hideSpace, isFloating }) {
  const [timeRemaining, setTimeRemaining] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    let timerInterval;

    function calculateTimeRemaining() {
      const now = new Date().getTime();
      const timeDifference = new Date(endDate) - now;
      // console.log(new Date(endDate));
      if (timeDifference <= 0) {
        // Timer has expired
        return {
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        };
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      return {
        days: days.toString().padStart(2, "0"),
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      };
    }

    timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [endDate]);

  return (
    <span>
      {!isFloating || (isFloating && timeRemaining.days !== "00")
        ? `${timeRemaining.days}${hideSpace ? ":" : " : "}`
        : ""}
      {timeRemaining.hours}
      {hideSpace ? ":" : " : "}
      {timeRemaining.minutes}
      {!isFloating || (isFloating && timeRemaining.days === "00")
        ? `${hideSpace ? ":" : " : "}${timeRemaining.seconds}`
        : ""}
    </span>
  );
}

export default CountdownTimer;
