import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
// import  backVid from "../../photos/background-vid.mp4";

export default function ProductImageInputField({
  value,
  name,

  disabled,
  previewPhotoURL,
  setPreviewPhotoURL,

  editFunc,
}) {
  const onDrop = async (files) => {
    if (!disabled && files && files.length > 0) {
      const photoTempUrl = URL.createObjectURL(files[0]);

      setPreviewPhotoURL(photoTempUrl);

      editFunc({ variables: { [name]: files[0] } });
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
    },
    maxSize: 104857600, //10 MB
    multiple: false,
  });

  useEffect(() => {
    if (value) {
      setPreviewPhotoURL(value);
    }
  }, [value, setPreviewPhotoURL]);

  return (
    <button
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
      className={`${
        isDragActive ? "bg-blue-700 animate-pulse" : ""
      } flex-shrink flex-grow-0 overflow-hidden group relative w-full h-32 bg-white hover:bg-gray-50 disabled:hover:bg-white transition-all rounded-xl shadow border-gray-200 border`}
      // onClick={(e) => {
      //   e.preventDefault();
      // }}
      disabled={disabled}
      type="button"
    >
      <input {...getInputProps()} />

      {!(previewPhotoURL || value) && (
        <div className="inset-0 z-0 m-auto items-center flex justify-center absolute opacity-100 transition-opacity">
          <svg
            className="w-10 fill-current group-hover:text-gray-500 text-gray-400"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </div>
      )}
      {(previewPhotoURL || value) && (
        <div className="inset-0 m-auto z-10 items-center flex justify-center absolute transition-opacity">
          <svg
            className={`h-12 text-gray-400 group-hover:text-gray-500 stroke-current m-auto motion-safe:animate-spin`}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.636 3.636A9 9 0 0119 10"
              strokeWidth={2}
              strokeLinecap="round"
            />
          </svg>
        </div>
      )}

      {(previewPhotoURL || value) && (
        <img
          className="flex-grow-0 w-full h-full top-0 object-cover z-10"
          src={previewPhotoURL || value}
          alt={name}
        />
      )}
    </button>
  );
}
