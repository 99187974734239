import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { adminClient, customerClient } from "../../GraphqlApolloClients";
import { AdminAuthContext } from "../../context/adminAuth";

import { useForm } from "../../util/hooks";
import TextInputField from "./TextInputField";

import {
  EDIT_CITY_MUTATION,
  FETCH_RECOMMENDED_CITIES,
} from "../../util/gqlFunctions";
import ProfilePicInputField from "./ProfilePicInputField";

function EditCityForm({ targetCity, setTargetCity, navigate }) {
  const [errors, setErrors] = useState({});
  const { admin } = useContext(AdminAuthContext);
  const { values, setValues, onChangeMedia, onChange } = useForm(
    editCityCallback,
    {
      cityId: targetCity.id,
      logoFile: null,
      ...targetCity,
      fieldsToEdit: [],
    }
  );

  const [editCity, { loading: loadingEditCity }] = useMutation(
    EDIT_CITY_MUTATION,
    {
      refetchQueries: admin
        ? [
            {
              query: FETCH_RECOMMENDED_CITIES,
              variables: { pageSize: 20, seenIds: [] },
            },
          ]
        : [],
      update(_, { data: { editCity: editC } }) {
        setValues({
          ...values,
          ...editC,
          logoFile: null,
          fieldsToEdit: [],
        });

        setErrors({});
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: admin ? adminClient : customerClient,
    }
  );

  function editCityCallback(e) {
    e.preventDefault();
    e.stopPropagation();
    editCity();
  }

  function setFieldsToEdit(name) {
    if (
      values.fieldsToEdit &&
      values.fieldsToEdit.includes(name) &&
      targetCity
    ) {
      setValues({
        ...values,
        fieldsToEdit: [],
        [name]: targetCity[name],
      });
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }
  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setTargetCity(null);
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl short:h-full short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setTargetCity(null);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          Edit city
        </p>
        <p className="text-gray-500 w-full leading-tight ">Edit city info</p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6">
          {/* <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="topic" className="font-semibold text-gray-700 ">
                Topic(s){" "}
                {errors.topic && (
                  <span className="text-red-500">{errors.topic}</span>
                )}
              </label>
            </div>
            <select
              id="topic"
              name="topic"
              value={values.topic}
              onChange={onChange}
              disabled={loadingEditCity}
              className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              {topics.map((topicName, index) => (
                <option key={index} value={topicName}>
                  {topicName}
                </option>
              ))}
            </select>
          </div> */}

          <div className="flex w-full items-start justify-start space-x-4 sm:space-x-8">
            <ProfilePicInputField
              title="Logo (optional)"
              name="logoFile"
              onChangeMedia={onChangeMedia}
              value={values.logoUrl}
              editFunc={editCity}
              values={values}
              error={errors.logoFile}
            />

            <div className="w-full flex flex-col flex-grow-0  items-start justify-center space-y-2 sm:space-y-4">
              <TextInputField
                title={"City"}
                name="name"
                type="text"
                error={errors.name}
                disabled={loadingEditCity}
                onChange={onChange}
                maxLen={100}
                setFieldsToEdit={setFieldsToEdit}
                fieldsToEdit={values.fieldsToEdit}
                editFunc={editCity}
                value={values.name}
              />
              <TextInputField
                title={"Province (optional)"}
                name="province"
                type="text"
                error={errors.province}
                disabled={loadingEditCity}
                onChange={onChange}
                maxLen={100}
                setFieldsToEdit={setFieldsToEdit}
                fieldsToEdit={values.fieldsToEdit}
                editFunc={editCity}
                value={values.province}
              />
            </div>
          </div>

          <TextInputField
            title={"Province short form (optional)"}
            name="provinceShortForm"
            type="text"
            error={errors.provinceShortForm}
            disabled={loadingEditCity}
            onChange={onChange}
            maxLen={100}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editCity}
            value={values.provinceShortForm}
          />

          <TextInputField
            title={"Country"}
            name="country"
            type="text"
            error={errors.country}
            disabled={loadingEditCity}
            onChange={onChange}
            maxLen={100}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editCity}
            value={values.country}
          />

          <TextInputField
            title={"Country short form"}
            name="countryShortForm"
            type="text"
            error={errors.countryShortForm}
            disabled={loadingEditCity}
            onChange={onChange}
            maxLen={100}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editCity}
            value={values.countryShortForm}
          />
        </div>
      </form>
    </div>
  );
}

export default EditCityForm;
