import React, { useEffect, useState } from "react";

export default function CustomerSubscriptionPaymentSuccessfulForm({
  togglePaymentSuccessfulForm,
  targetCustomer,
  message,
  buttonMsg,
  popUpTitle,
  customerSubscription,
  navigate,
}) {
  const [isCaptionCopied, setIsCaptionCopied] = useState(false);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const [hasCaptionBeenCopiedBefore, setHasCaptionBeenCopiedBefore] =
    useState(false);
  const shareCustSubInvite = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = null;

    textToShare =
      customerSubscription.subscriptionType === 1
        ? "Join my TKnight Pro duo with code: " + customerSubscription.id
        : "Join our TKnight Pro family with code: " + customerSubscription.id;

    if (navigator.share) {
      navigator
        .share({
          title: "Your TKnight Pro Invite",
          text: textToShare,
        })
        .then(() => {
          setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);

          setIsCaptionCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }
  };

  useEffect(() => {
    let timeout;
    if (isCaptionCopied) {
      timeout = setTimeout(() => setIsCaptionCopied(false), 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied]);
  return (
    <div className="fixed pt-16 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center short:pt-12">
      <button
        onClick={(e) => {
          e.preventDefault();

          togglePaymentSuccessfulForm();
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden  text-center sm:max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-center justify-center z-40 rounded-xl px-6 pb-6 sm:p-8 sm:pb-0 bg-white shadow-xl rounded-b-none sm:rounded-3xl `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();

            togglePaymentSuccessfulForm();
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          {popUpTitle ? popUpTitle : "Payment Successful!"}
        </p>
        <p className="text-gray-500 w-full leading-tight text-lg mt-1">
          {message}
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}

        {customerSubscription.subscriptionType !== 0 &&
        customerSubscription.numOfMembers <
          customerSubscription.maxNumOfMembers ? (
          <button
            onClick={(e) => {
              shareCustSubInvite(e);
            }}
            type="button"
            className={`${
              isCaptionCopied ? " motion-safe:animate-popUp " : ""
            } text-white relative mt-4 text-xl 2xl:text-2xl hover:bg-black overflow-hidden shadow-sm  group w-full font-bold uppercase flex transition-all items-center justify-center bg-blue-600 rounded-full pl-4 pr-3 py-2`}
          >
            <p
              className={`${
                isCaptionCopied
                  ? "motion-safe:animate-fadeDownFast"
                  : hasCaptionBeenCopiedBefore
                  ? "motion-safe:animate-fadeOutUpFast"
                  : "hidden"
              } bg-black pb-3 text-white inset-0 pt-3.5 w-full absolute`}
            >
              {isSharingToApps ? "Shared!" : "Copied"}
            </p>

            <p
              className={`${
                isCaptionCopied
                  ? "motion-safe:animate-fadeOutDownFast"
                  : hasCaptionBeenCopiedBefore
                  ? "motion-safe:animate-fadeUpFast"
                  : ""
              } py-2`}
            >
              Share Pro Code
            </p>
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault();
              togglePaymentSuccessfulForm();
            }}
            className="text-lg font-semibold mt-4 uppercase sm:text-xl w-full bg-blue-600 hover:bg-blue-700 transition-all rounded-full shadow-sm text-white py-3 px-2"
          >
            Thanks!
          </button>
        )}
        {customerSubscription.subscriptionType !== 0 &&
        customerSubscription.numOfMembers <
          customerSubscription.maxNumOfMembers ? (
          <p className="text-center w-full text-gray-600 mt-4 mb-0 sm:mb-6 text-base">
            Access your Pro Code any time through{" "}
            <button
              onClick={(e) => {
                navigate("/settings");
              }}
              className="text-blue-600 hover:text-black transition-all"
            >
              Settings
            </button>
          </p>
        ) : (
          <p className="text-center w-full text-gray-600 mt-4 mb-0 sm:mb-6 text-base">
            Have a question?{" "}
            <a
              href="mailto:info@tknight.live"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:text-black transition-all"
            >
              Contact support
            </a>
          </p>
        )}
      </form>
    </div>
  );
}
