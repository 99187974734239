import React from "react";
import { formatViewCount } from "../../util/hooks";

export default function PlayerCoinCounter({
  targetCustomer,
  targetGuestCustomer,
  loading,
  toggleBuyCoinsForm,
}) {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        toggleBuyCoinsForm();
      }}
      className="group h-[1.9rem] sm:h-auto flex-shrink-0 flex hover:bg-opacity-80 transition-all items-center justify-center bg-white rounded-full sm:px-2 py-1 text-sm sm:text-base px-1"
    >
      <svg
        className="h-5 shrink-0 fill-current"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
          fill="#F4900C"
        />
        <path
          d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
          fill="#FFCC4D"
        />
        <path
          d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
          fill="#FFE8B6"
        />
        <path
          d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
          fill="#FFAC33"
        />
        <path
          d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
          fill="#FFD983"
        />
      </svg>

      <p
        className={`${
          targetCustomer || targetGuestCustomer || !loading
            ? "animate-fadeInFast"
            : "invisible w-0"
        } shrink-0 font-bold text-black ml-1 mr-2`}
      >
        {formatViewCount(
          targetCustomer
            ? targetCustomer.balance
            : targetGuestCustomer
            ? targetGuestCustomer.balance
            : loading
            ? 10
            : 100
        )}
      </p>
      <div className="px-2 shrink-0 flex items-center justify-center cursor-pointer hover:bg-black transition-all bg-blue-600 rounded-full h-full py-1">
        <svg
          className="h-3 fill-current shrink-0"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.1 7.7H7.7V13.1H5.9V7.7H0.5V5.9H5.9V0.5H7.7V5.9H13.1V7.7Z"
            fill="white"
          />
        </svg>
      </div>
    </button>
  );
}
