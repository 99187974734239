import { CardElement } from "@stripe/react-stripe-js";
import React from "react";
import { useWindowSize } from "../../util/hooks";

export default function CardInputField({
  cardElemIsFocused,

  setCardElemIsFocused,
  setIsCardComplete,

  error,
  title,
}) {
  const size = useWindowSize();

  return (
    <div className="block w-full bg-white shadow-sm max-h-10 mt-1 overflow-hidden flex-shrink-0 px-3 py-2 border rounded-md border-gray-200 focus:border-indigo-500 focus:ring-indigo-500">
      <CardElement
        options={{
          hidePostalCode: true,

          style: {
            base: {
              color: "black",
              fontFamily: "sans-serif",

              fontSize: size.width >= 640 ? "18px" : "16px",

              "::placeholder": {
                color: "#9ca3af",
              },
              iconColor: "#6b7280",
            },
            complete: {
              color: "black",
              fontFamily: "sans-serif",

              fontSize: size.width >= 640 ? "18px" : "16px",

              "::placeholder": {
                color: "#9ca3af",
              },
              iconColor: "black",
            },
            invalid: {
              color: "#991b1b",
              iconColor: "#991b1b",
            },
          },
        }}
        id="paymentInfo"
        onFocus={() => {
          setCardElemIsFocused(true);
        }}
        onBlur={() => {
          setCardElemIsFocused(false);
        }}
        onChange={(e) => {
          if (e.complete) {
            setIsCardComplete(true);

            // if (error === "Required") {
            //   setHideErrorMsg(true);
            // }
          }
          if (!e.complete) {
            setIsCardComplete(false);
            // setHideErrorMsg(false);
          }
        }}
        //   className="w-full"
      />
    </div>
  );
}
