import React from "react";
import { useNavigate } from "react-router-dom";
import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";

export default function NotFound() {
  let navigate = useNavigate();
  return (
    <div
      className={`h-full w-screen overflow-hidden relative min-h-screen bg-gray-50`}
    >
      <LiveStreamGradBack gradIndex={1} />
      <div
        className={`relative z-10 space-y-2 overflow-hidden flex flex-col items-center justify-center text-center h-screen max-h-screen short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-4 sm:pb-10`}
      >
        <p className="text-xl z-50 text-white">Page Not Found</p>
        <button
          onClick={(e) => {
            navigate("/");
          }}
          className="text-sm  h-[1.9rem] sm:text-base group  flex hover:bg-opacity-30 transition-all items-center justify-center space-x-2 bg-white bg-opacity-20 rounded-full pl-4 pr-3 py-1  text-white"
        >
          <p className="font-bold">Home</p>
          <svg
            className="h-2.5 fill-current"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className=" -translate-x-1 group-hover:translate-x-0 transition-all"
              d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
            />
            <rect
              className="opacity-0 group-hover:opacity-100 transition-all"
              y="4"
              width="9"
              height="2"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
