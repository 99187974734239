import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

export default function EmptyCircularProgressBar({
  radius,
  shouldShowCaption,
}) {
  const [timerKey, setTimerKey] = useState(0);

  useEffect(() => {
    if (!timerKey) {
      const randomInteger = Math.floor(Math.random() * 9000000) + 1000000 + 1;
      setTimerKey(randomInteger);
    }
  }, [timerKey]);

  return (
    <div className="group relative">
      {shouldShowCaption && (
        <div className="sm:flex hidden font-semibold flex-col absolute items-center justify-center  top-full -mt-1 left-1/2 transform -translate-x-1/2 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 z-10 transition-opacity duration-300">
          <svg
            className="h-1.5 rotate-180 fill-current text-gray-800"
            viewBox="0 0 20 10"
          >
            <path d="M0 0L20 0L10 10Z" />
          </svg>
          <span className="bg-gray-800 px-2 py-1  rounded-md shadow">
            Your level
          </span>
        </div>
      )}
      {/* <div className="flex shrink-0 items-center justify-center overflow-hidden rounded-full"> */}

      <CountdownCircleTimer
        timerKey={timerKey}
        isPlaying={false}
        initialRemainingTime={0}
        duration={1}
        size={radius * 2}
        strokeLinecap="butt"
        rotation={"counterclockwise"}
        colors={["#2563eb"]}
        isSmoothColorTransition={false}
        strokeWidth={2.5}
        trailColor={"#f3f4f6"}
      >
        {({ elapsedTime, color }) => (
          <span className="text-sm sm:text-lg lg:text-sm text-black">0</span>
        )}
      </CountdownCircleTimer>
    </div>
  );
}
