import { jwtDecode } from "jwt-decode";
import React, { createContext, useReducer, useState } from "react";
import { customerClient, guestClient } from "../GraphqlApolloClients";
import { isRunningInRNWebView } from "../util/hooks";

const initialState = {
  customer: null,
  friendIds: [],
};

if (localStorage.getItem("customerJwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("customerJwtToken"));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("customerJwtToken");
  } else {
    initialState.customer = decodedToken;
  }
}

if (localStorage.getItem("friendIds")) {
  initialState.friendIds = localStorage.getItem("friendIds").split(",") || [];
}

const CustomerAuthContext = createContext({
  customer: null,
  loginCustomer: (customerData) => {},
  logoutCustomer: () => {},
  onFriendIdsChange: () => {},
  friendIds: [],
});

function customerAuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN_CUSTOMER":
      return {
        ...state,
        customer: action.payload,
      };
    case "LOGOUT_CUSTOMER":
      return {
        ...state,
        customer: null,
      };
    default:
      return state;
  }
}

function CustomerAuthProvider(props) {
  const [state, dispatch] = useReducer(customerAuthReducer, initialState);
  const [savedFriendIds, setSavedFriendIds] = useState(initialState.friendIds);

  function loginCustomer(customerData) {
    localStorage.setItem("customerJwtToken", customerData.token);
    const decodedToken = jwtDecode(customerData.token);

    dispatch({
      type: "LOGIN_CUSTOMER",
      payload: decodedToken,
    });

    if (isRunningInRNWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "customerLogin",
          ...customerData,
        })
      );
    }
  }

  function logoutCustomer() {
    guestClient.cache.reset();
    customerClient.cache.reset();
    localStorage.removeItem("guestJwtToken");
    localStorage.removeItem("customerJwtToken");
    localStorage.removeItem("friendIds");

    setSavedFriendIds([]);

    dispatch({ type: "LOGOUT_CUSTOMER" });
    if (isRunningInRNWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "customerLogout",
        })
      );
    }
  }

  async function onFriendIdsChange(friendId) {
    let list = [...savedFriendIds];
    if (!list.includes(friendId)) {
      list.push(friendId);
    } else {
      list = list.filter((id) => id !== friendId);
    }
    localStorage.setItem("friendIds", list.join(","));
    setSavedFriendIds(list);
    return list;
  }

  return (
    <CustomerAuthContext.Provider
      value={{
        customer: state.customer,
        loginCustomer,
        logoutCustomer,
        onFriendIdsChange,
        friendIds: savedFriendIds,
      }}
      {...props}
    />
  );
}

export { CustomerAuthContext, CustomerAuthProvider };
