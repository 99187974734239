import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { adminClient } from "../../GraphqlApolloClients";

import { useForm } from "../../util/hooks";

import {
  ADD_PRODUCT_IMAGE_TO_PRODUCT_MUTATION,
  CREATE_PRODUCT_IMAGE_MUTATION,
  DELETE_PRODUCT_IMAGE_MUTATION,
  FETCH_ALL_PRODUCT_IMAGES,
  FETCH_PRODUCT_IMAGES_BY_USER,
  FETCH_PRODUCTS,
} from "../../util/gqlFunctions";
import ProductImageInputField from "./ProductImageInputField";

function ProductImagesGallery({
  setShouldOpenAddImages,
  productId,
  productImages,
}) {
  const [errors, setErrors] = useState({});

  const { values, setValues } = useForm(addProductImageToProductCallback, {
    productId,
    productImageId: "",
    imageFile: {},
  });
  const [previewImageUrl, setPreviewImageUrl] = useState("");

  const [
    addProductImageToProduct,
    { loading: loadingAddProductImageToProduct },
  ] = useMutation(ADD_PRODUCT_IMAGE_TO_PRODUCT_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_ALL_PRODUCT_IMAGES,
      },
      {
        query: FETCH_PRODUCTS,
      },
    ],
    update(_, { data: { addProductImageToProduct: addedProductImage } }) {
      setValues({
        ...values,
        productImageId: "",
      });
      setShouldOpenAddImages(false);
      setErrors({});
    },
    onError(err) {
      console.log(err);

      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0].extensions.errors);

        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: adminClient,
  });

  function addProductImageToProductCallback(e) {
    e.preventDefault();
    addProductImageToProduct();
  }

  const [createProductImage, { loading: loadingCreateProductImage }] =
    useMutation(CREATE_PRODUCT_IMAGE_MUTATION, {
      refetchQueries: [
        {
          query: FETCH_PRODUCT_IMAGES_BY_USER,
        },
      ],
      update(_, { data: { createProductImage: createdProductImage } }) {
        setValues({
          ...values,
          imageFile: {},
        });
        setPreviewImageUrl("");

        setErrors({});
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },

      client: adminClient,
    });

  const [deleteProductImage, { loading: loadingDeleteProductImage }] =
    useMutation(DELETE_PRODUCT_IMAGE_MUTATION, {
      refetchQueries: [
        {
          query: FETCH_PRODUCT_IMAGES_BY_USER,
        },
        {
          query: FETCH_PRODUCTS,
        },
      ],
      update(_, { data: { deleteProductImage: deletedProductImage } }) {
        setValues({
          ...values,
          productImageId: "",
        });
        setErrors({});
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: adminClient,
    });

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setShouldOpenAddImages(false);
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false || loadingAddProductImageToProduct}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl short:h-full short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setShouldOpenAddImages(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          Your images
        </p>
        <p className="text-gray-500 w-full ">
          Upload images and add them to your product
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6 mb-4">
          <div className="w-full">
            <ProductImageInputField
              name="imageFile"
              editFunc={createProductImage}
              error={errors.image}
              disabled={loadingCreateProductImage || loadingDeleteProductImage}
              previewPhotoURL={previewImageUrl}
              setPreviewPhotoURL={setPreviewImageUrl}
            />
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {productImages &&
              productImages.length > 0 &&
              productImages.map((productImage, index) => (
                <div className="relative w-full aspect-video">
                  <img
                    src={productImage.imageUrl}
                    className="w-full aspect-video object-cover rounded-md mr-2"
                    alt={"product"}
                  />

                  <div className="flex absolute bottom-0 inset-x-0 items-center  text-gray-600 justify-start w-full">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        deleteProductImage({
                          variables: { productImageId: productImage.id },
                        });
                      }}
                      className="px-1 py-1 hover:text-gray-200 transition-all"
                    >
                      {loadingDeleteProductImage ? (
                        <svg
                          className={`h-4 text-gray-200 stroke-current m-auto motion-safe:animate-spin`}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.636 3.636A9 9 0 0119 10"
                            strokeWidth={2}
                            strokeLinecap="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="fill-current h-5 m-auto"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM16 9V19H8V9H16ZM6 7H18V19C18 20.1 17.1 21 16 21H8C6.9 21 6 20.1 6 19V7Z"
                          />
                        </svg>
                      )}
                    </button>

                    <button
                      // onClick={copyTrackingLink}
                      onClick={(e) => {
                        e.preventDefault();
                        addProductImageToProduct({
                          variables: {
                            productImageId: productImage.id,
                            productId,
                          },
                        });
                      }}
                      className={`px-2 py-1 hover:text-gray-200 flex flex-1 items-center justify-center relative font-semibold transition-all`}
                    >
                      {loadingAddProductImageToProduct ? (
                        <svg
                          className={`h-4 text-gray-200 stroke-current m-auto motion-safe:animate-spin`}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.636 3.636A9 9 0 0119 10"
                            strokeWidth={2}
                            strokeLinecap="round"
                          />
                        </svg>
                      ) : (
                        <p>Add to product</p>
                      )}
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </form>
    </div>
  );
}

export default ProductImagesGallery;
