import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";

import {
  CREATE_AD_VIEW_MUTATION,
  FETCH_AD_BOOKMARK_BY_CUSTOMER,
  SEND_AD_CLICK_MUTATION,
  TOGGLE_AD_BOOKMARK_MUTATION,
} from "../../util/gqlFunctions";
import { defaultPicUrl, useWindowSize } from "../../util/hooks";

import PieTimer from "./PieTimer";

function HorAdOverlayBox({
  quiz,
  triviaNight,
  loading,
  personalQuiz,
  questionView,
  imgUrl,
  shouldOpenAdPopup,
  toggleAdPopup,
  overlayAd,
  values,
  targetCustomer,
  targetAdBookmark,
  isPaused,
  setIsPaused,

  setTempElapsedTime,
  togglePauseQuiz,

  isOutOfLives,
  endTime,
  lastXSeconds,
  togglePausePersonalQuiz,
}) {
  const [targetAdView, setTargetAdView] = useState(null);
  const [viewSentForAd, setViewSentForAd] = useState(null);
  const [sentAdClick, setSentAdClick] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(null);
  const size = useWindowSize();
  useEffect(() => {
    if (targetAdBookmark) {
      setIsBookmarked(
        targetAdBookmark && targetAdBookmark.status === 0 ? true : false
      );
    }
  }, [targetAdBookmark]);

  const [valuesForAd, setValuesForAd] = useState({
    adBookmarkId: targetAdBookmark ? targetAdBookmark.id : null,
    adId: overlayAd.id,
    adViewId: targetAdView ? targetAdView : null,
    triviaNightId: triviaNight ? triviaNight.id : null,
    quizId: quiz ? quiz.id : null,
    viewedAfterQuestion: triviaNight
      ? triviaNight.lastCompletedQ
      : quiz
      ? quiz.lastCompletedQ
      : questionView
      ? questionView.questionId
      : null,
  });

  const [createAdView] = useMutation(CREATE_AD_VIEW_MUTATION, {
    update(_, { data: { createAdView: adView } }) {
      setTargetAdView(adView.id);

      setViewSentForAd(overlayAd.id);
    },
    onError(err) {
      console.log(err);
    },
    client: targetCustomer ? customerClient : guestClient,
    variables: valuesForAd,
  });

  const [sendAdClick] = useMutation(SEND_AD_CLICK_MUTATION, {
    update(_, { data: { sendAdClick: adClick } }) {
      setSentAdClick(true);
    },
    onError(err) {
      console.log(err);
    },
    client: targetCustomer ? customerClient : guestClient,
    variables: { adViewId: targetAdView || "" },
  });

  useEffect(() => {
    if (
      targetAdBookmark &&
      (!valuesForAd.adBookmarkId ||
        valuesForAd.adBookmarkId !== targetAdBookmark.id)
    ) {
      setValuesForAd({ ...valuesForAd, adBookmarkId: targetAdBookmark.id });
    } else if (
      targetAdView &&
      (!valuesForAd.adViewId || valuesForAd.adViewId !== targetAdView)
    ) {
      setValuesForAd({ ...valuesForAd, adViewId: targetAdView });
    }
  }, [targetAdView, targetAdBookmark, valuesForAd]);
  const [adBookmarkErr, setAdBookmarkErr] = useState("");

  useEffect(() => {
    let timeout;
    if (adBookmarkErr) {
      timeout = setTimeout(() => setAdBookmarkErr(""), 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [adBookmarkErr]);

  const [isFirstAdShowComplete, setIsFirstAdShowComplete] = useState(false);

  const [shouldShowHideAdErr, setShouldShowHideAdErr] = useState(false);

  useEffect(() => {
    let timeout;
    if (shouldShowHideAdErr) {
      timeout = setTimeout(() => {
        setShouldShowHideAdErr(false);
        setIsFirstAdShowComplete(true);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [shouldShowHideAdErr]);

  const [toggleBookmarkAd, { loading: loadingToggleBookmark }] = useMutation(
    TOGGLE_AD_BOOKMARK_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_AD_BOOKMARK_BY_CUSTOMER,
          variables: { adId: overlayAd.id },
        },
        // {
        //   query: FETCH_TRIVIA_VIEW_COUNT,
        //   variables: { triviaNightId: triviaNight.id },
        // },
      ],
      update(_, { data: { toggleBookmarkAd: adBookmark } }) {},
      onError(err) {
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors.bookmarkErr);
          setAdBookmarkErr(err.graphQLErrors[0].extensions.errors.bookmarkErr);
          setIsBookmarked(!isBookmarked);
        }
      },
      client: targetCustomer ? customerClient : guestClient,
      variables: valuesForAd,
    }
  );

  // State to track whether the component has been displayed for 5 seconds
  const [hasDisplayedFor5Seconds, setHasDisplayedFor5Seconds] = useState(false);

  // Set a timer to mark that the component has been displayed for 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setHasDisplayedFor5Seconds(true);
    }, 5000); // 5 seconds

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // Call createAdView if the timer has expired
  useEffect(() => {
    if (hasDisplayedFor5Seconds && viewSentForAd !== valuesForAd.adId) {
      createAdView();
    }
  }, [hasDisplayedFor5Seconds, viewSentForAd, valuesForAd, createAdView]);

  return (
    <div
      className={`${
        questionView
          ? "px-8 inset-x-0 inset-y-0 short:pt-10 pt-12"
          : "inset-0 w-screen h-screen short:pt-9 pt-16 "
      } flex fixed pointer-events-none xshort:pt-0 xshort:-mt-4 sm:py-16 lg:py-10 z-50 overscroll-contain  max-h-screen max-w-screen overflow-hidden  items-start justify-start sm:items-center sm:justify-center`}
    >
      {(questionView || size.width >= 1024) && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // setErrors({});
            // if (personalQuiz || quiz) {
            // }
            if (targetCustomer && targetCustomer.isPaidSubscriber) {
              toggleAdPopup(false);
            } else {
              setShouldShowHideAdErr(true);
            }
            // toggleAdPopup(false);
          }}
          className="z-40 pointer-events-auto bg-gray-50 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute sm:backdrop-blur-sm"
          disabled={false}
        />
      )}
      <div
        // onSubmit={buyBandCallback} top-28
        className={`${
          overlayAd.regularVerAdUrl && size.width < 1024
            ? // size.height > (size.width / 9) * 16
              `xshort:aspect-[18/22] aspect-[9/14] ${
                quiz || triviaNight ? "" : "top-14 xshort:top-24"
              }`
            : "aspect-square sm:aspect-video"
        } ${
          (((triviaNight || quiz) && (triviaNight || quiz).status === 5) ||
            isOutOfLives ||
            (!triviaNight && !quiz)) &&
          overlayAd.link
            ? "cursor-pointer"
            : ""
        } overflow-visible hide-button-flash pointer-events-auto group  sm:top-0 relative w-full text-center sm:max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto mt-0 max-w-5xl 2xl:max-w-7xl sm:h-auto flex flex-col items-center justify-center z-40 rounded-xl border-8 border-white box-border bg-white sm:shadow-xl sm:rounded-3xl`}
        onClick={(e) => {
          if (
            (((triviaNight || quiz) && (triviaNight || quiz).status === 5) ||
              isOutOfLives ||
              (!triviaNight && !quiz)) &&
            overlayAd.link
          ) {
            if (!sentAdClick && targetAdView) {
              sendAdClick();
            }
            window.open("https://" + overlayAd.link, "_blank");
          }
        }}
      >
        <div className="absolute top-0 overflow-hidden pt-4">
          <div
            className={`${
              shouldShowHideAdErr
                ? "motion-safe:animate-fadeDownFast"
                : isFirstAdShowComplete
                ? "motion-safe:animate-fadeOutUpFast"
                : "hidden"
            } sm:max-w-xl z-50 font-semibold py-2 px-4 text-center bg-red-600 text-white rounded-lg inset-x-4 md:inset-x-auto top-4 md:top-6 short:inset-x-2 short:top-2 md:w-auto short:text-sm`}
          >
            Get TKnight Pro to avoid ads
          </div>
        </div>

        {((overlayAd.regularHorAdUrl &&
          overlayAd.regularHorAdUrl.includes(".mp4")) ||
          (overlayAd.squareAdUrl &&
            overlayAd.squareAdUrl.includes(".mp4"))) && (
          <video
            className="flex-grow-0 bg-black w-full h-full top-0 object-cover rounded-xl sm:rounded-2xl "
            controls={false}
            autoPlay={true}
            muted={true}
            loop={false}
            onEnded={(e) => {
              e.preventDefault();
              toggleAdPopup(false);
            }}
          >
            <source
              src={
                size.width < 1024 && overlayAd.squareAdUrl
                  ? overlayAd.squareAdUrl
                  : overlayAd.regularHorAdUrl
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}

        {size.width < 1024 &&
          overlayAd.regularVerAdUrl &&
          overlayAd.regularVerAdUrl.includes(".mp4") && (
            <video
              className="flex-grow-0 bg-black w-full h-full top-0 object-cover rounded-xl sm:rounded-2xl"
              controls={false}
              autoPlay={true}
              muted={true}
              loop={false}
              onEnded={(e) => {
                e.preventDefault();
                toggleAdPopup(false);
              }}
            >
              <source src={overlayAd.regularVerAdUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}

        {size.width < 1024 && overlayAd.regularVerAdUrl ? (
          <img
            className="flex-grow-0 w-full h-full rounded-xl sm:rounded-2xl top-0 object-cover"
            src={overlayAd.regularVerAdUrl}
            alt={"Ad"}
          />
        ) : (
          ((overlayAd.regularHorAdUrl &&
            !overlayAd.regularHorAdUrl.includes(".jpg")) ||
            (overlayAd.squareAdUrl &&
              !overlayAd.squareAdUrl.includes(".jpg"))) && (
            <img
              className="flex-grow-0 w-full h-full rounded-xl sm:rounded-2xl top-0 object-cover"
              src={
                size.width < 1024 && overlayAd.squareAdUrl
                  ? overlayAd.squareAdUrl
                  : overlayAd.regularHorAdUrl
              }
              alt={"Ad"}
            />
          )
        )}

        <div className="absolute bottom-0 sm:shadow-xl flex items-center justify-center m-auto -mb-9 sm:-mb-11 rounded-full p-1.5 sm:p-2 bg-white">
          <PieTimer
            //position:"absolute", bottom:0, alignItems:"center",justifyContent:"center", margin:"auto", marginBottom: -36, borderRadius:9999, padding:6, backgroundColor:Colours.darkerBlue  quiz={personalQuiz || quiz || triviaNight}
            // questionView={questionView}
            shouldPlaySoundEffects={false}
            ad={overlayAd}
            endTime={endTime}
            imgUrl={imgUrl || defaultPicUrl}
            alt={"Ad"}
            width={size.height < 672 ? 50 : size.width >= 1024 ? 70 : 60}
            lastXSeconds={lastXSeconds}
            togglePauseQuizCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (quiz && (quiz.gameTimingType === 0 || personalQuiz)) {
              } else {
                return;
              }
              // if (!isPaused) {
              setIsPaused(!isPaused);
              // }
              var curTime = new Date();
              var secondsLeftAtPause = 0;
              var timeLeftInSec = 0;
              var timeLeftInMilli = 0;
              if (!(personalQuiz || quiz).isPaused) {
                if ((personalQuiz || quiz).status === 0) {
                  const releaseDate = new Date(
                    (personalQuiz || quiz).releaseDate
                  );
                  timeLeftInMilli = releaseDate.getTime() - curTime.getTime();
                  timeLeftInSec = Math.floor(timeLeftInMilli / 1000);

                  if (timeLeftInSec <= 0) {
                    // throw new UserInputError("Errors", {
                    //   errors: {
                    //     pause: "The show must go on!",
                    //   },
                    // });
                  } else {
                    secondsLeftAtPause = timeLeftInSec > 0 ? timeLeftInSec : 20;
                  }
                } else if ((personalQuiz || quiz).status === 1) {
                  const nextQStartsAt = new Date(
                    (personalQuiz || quiz).nextQStartsAt
                  );
                  timeLeftInMilli = nextQStartsAt - curTime.getTime();
                  timeLeftInSec = Math.floor(timeLeftInMilli / 1000);

                  if (timeLeftInSec <= 0) {
                    // throw new UserInputError("Errors", {
                    //   errors: {
                    //     pause: "The show must go on!",
                    //   },
                    // });
                  } else {
                    secondsLeftAtPause = timeLeftInSec > 0 ? timeLeftInSec : 20;
                  }
                } else if ((personalQuiz || quiz).status === 3) {
                  const curAnsEndsAt = new Date(
                    (personalQuiz || quiz).curAnsEndsAt
                  );
                  timeLeftInMilli = curAnsEndsAt.getTime() - curTime.getTime();
                  timeLeftInSec = Math.floor(timeLeftInMilli / 1000);

                  if (timeLeftInSec <= 0) {
                    // throw new UserInputError("Errors", {
                    //   errors: {
                    //     pause: "The show must go on!",
                    //   },
                    // });
                  } else {
                    secondsLeftAtPause = timeLeftInSec > 0 ? timeLeftInSec : 20;
                  }
                }
                setTempElapsedTime(secondsLeftAtPause);
              }
              if (personalQuiz) {
                togglePausePersonalQuiz({
                  variables: {
                    personalQuizId: personalQuiz ? personalQuiz.id : "",
                    secondsLeftAtPause,
                  },
                });
              } else {
                togglePauseQuiz({
                  variables: {
                    quizId: quiz ? quiz.id : null,
                    secondsLeftAtPause,
                  },
                });
              }
            }}
            loading={loading}
          />
        </div>

        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            toggleBookmarkAd();
            setIsBookmarked(
              targetAdBookmark && targetAdBookmark.status === 0 ? false : true
            );
          }}
          className={`${
            // ((triviaNight || quiz) && (triviaNight || quiz).status === 5) ||
            isBookmarked ? " m-2.5 " : "  m-2.5"
          } ${
            adBookmarkErr
              ? "motion-safe:animate-shake hover:text-red-400 text-red-400"
              : " motion-safe:animate-none"
          } group-hover:opacity-100  hover:text-gray-300 text-gray-700 duration-150 top-4 opacity-0 absolute hover:shadow right-0 p-1.5 group/adBookmark bg-white bg-opacity-50  backdrop-blur rounded-full aspect-square  transition-all group-hover:top-0`}
          disabled={adBookmarkErr || loadingToggleBookmark ? true : false}
        >
          <div className="relative">
            {adBookmarkErr && (
              <p className="absolute right-full whitespace-nowrap  mr-4">
                {adBookmarkErr}
              </p>
            )}

            <svg
              className={`${
                isBookmarked ? "fill-current" : ""
              } w-6 stroke-current transition-all`}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3Z"
                strokeWidth="2"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
}

export default HorAdOverlayBox;
