import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import VideoBox from "./VideoBox";
// import  backVid from "../../videos/background-vid.mp4";

export default function AdMediaInputField({
  value,
  name,
  onChangeMedia,
  disabled,
  previewVideoURL,
  setPreviewVideoURL,
  previewPhotoURL,
  setPreviewPhotoURL,
  shouldEdit,
  editFunc,
  type,
  loading,
  maxSize,
  setErrors,
  errors,
}) {
  const [videoDuration, setVideoDuration] = useState(null);

  const onDrop = async (files) => {
    if (!disabled && files && files.length > 0) {
      var fileType = files[0].type;

      onChangeMedia(name, files[0], fileType);

      if (fileType.includes("image")) {
        const imageTempUrl = URL.createObjectURL(files[0]);

        setPreviewPhotoURL(imageTempUrl);
        setPreviewVideoURL(null);
        setErrors({ ...errors, [name]: null });
      } else {
        const videoTempUrl = URL.createObjectURL(files[0]);
        setPreviewVideoURL(videoTempUrl);
        setPreviewPhotoURL(null);
        // Get the video duration
        const video = document.getElementById("adVideo");

        video.addEventListener("loadedmetadata", () => {
          setVideoDuration(video.duration); // Set the video duration

          console.log(videoDuration);
          if (video.duration > 30) {
            files[0] = null;
            setPreviewVideoURL(null);
            setPreviewPhotoURL(null);

            setErrors({ ...errors, [name]: "- 30s max" });
          } else if (video.duration < 2) {
            files[0] = null;
            setPreviewVideoURL(null);
            setPreviewPhotoURL(null);

            setErrors({ ...errors, [name]: "- 2s min" });
          } else {
            setErrors({ ...errors, [name]: null });
          }
        });
      }

      if (shouldEdit && files[0]) {
        editFunc({ variables: { [name]: files[0], fieldsToEdit: [name] } });
      }
    } else {
      setErrors({ ...errors, [name]: "- Invalid" });
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "video/mp4": [".mp4"],
      "video/mov": [".mov"],
    },
    maxSize, //10 MB
    multiple: false,
  });

  useEffect(() => {
    if (disabled && !loading) {
      if (value && value.length > 0 && (value.includes(".mp4") || type === 0)) {
        setPreviewVideoURL(value);
      } else if (value) {
        setPreviewPhotoURL(value);
      }
    }
  }, [value, setPreviewVideoURL, setPreviewPhotoURL, disabled, type, loading]);

  return (
    <button
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
      className={`${isDragActive ? "bg-blue-700 animate-pulse" : ""} ${
        disabled ? "" : "hover:bg-gray-100"
      } flex-shrink flex-grow-0 overflow-hidden group relative w-full h-full bg-gray-50  transition-all`}
      type="button"
      disabled={disabled}
    >
      <input {...getInputProps()} />

      {!(previewVideoURL || previewPhotoURL || value) && (
        <div className="inset-0 z-0 m-auto items-center flex justify-center absolute opacity-100 transition-opacity">
          <svg
            className="h-7 m-auto fill-current text-gray-400 group-hover:text-gray-500 transition-all"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM2.99878 17.25L14.0588 6.19L17.8088 9.94L6.74878 21H2.99878V17.25Z"
            />
          </svg>
        </div>
      )}
      {(previewVideoURL || previewPhotoURL || value) && (
        <div
          className={`inset-0 m-auto z-10 items-center flex justify-center absolute ${
            disabled ? "" : "opacity-100"
          } transition-opacity opacity-0 `}
        >
          <svg
            className="h-7 m-auto fill-current text-white"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM2.99878 17.25L14.0588 6.19L17.8088 9.94L6.74878 21H2.99878V17.25Z"
            />
          </svg>
        </div>
      )}

      {(previewVideoURL || previewPhotoURL || value) && (
        <div
          className={`inset-0 m-auto z-10 items-center flex justify-center absolute ${
            loading ? "opacity-100" : ""
          } transition-opacity opacity-0 `}
        >
          <svg
            className={`h-7 m-auto stroke-current text-white motion-safe:animate-spin`}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.636 3.636A9 9 0 0119 10"
              strokeWidth={2}
              strokeLinecap="round"
            />
          </svg>
        </div>
      )}

      {previewVideoURL && <VideoBox url={previewVideoURL} />}

      {previewPhotoURL && (
        <img
          className="flex-grow-0 w-full h-full top-0 object-cover z-10"
          src={previewPhotoURL || value}
          alt={name}
        />
      )}
    </button>
  );
}
