import React from "react";

export default function QuickPowerupSelection({
  togglePurchasePowerUpForm,
  targetCustomer,
}) {
  return (
    <div className="grid grid-cols-3 sm:grid-cols-4 w-full z-10 short:mb-3 mb-4 shrink-0 items-start justify-start gap-3 overflow-x-auto">
      <button
        onClick={(e) => {
          e.preventDefault();
          togglePurchasePowerUpForm(0);
        }}
        className="px-2 xshort:px-1 w-full shrink-0 xshort:py-1 short:py-1.5 short:px-1.5 py-2 sm:pb-1 hover:bg-opacity-80 transition-opacity flex-col space-y-1 rounded-2xl short:rounded-xl bg-white flex items-center justify-center"
      >
        <div className="bg-gray-100 relative rounded-xl short:rounded-lg py-3 short:py-2 w-full flex items-center justify-center">
          <svg
            className="h-12 short:h-10 xshort:h-8 fill-current text-red-600"
            viewBox="0 0 62 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M31 57L26.505 52.8997C10.54 38.3935 0 28.8262 0 17.0845C0 7.51717 7.502 0 17.05 0C22.444 0 27.621 2.51608 31 6.4921C34.379 2.51608 39.556 0 44.95 0C54.498 0 62 7.51717 62 17.0845C62 28.8262 51.46 38.3935 35.495 52.9308L31 57Z" />
          </svg>
          <p className="absolute text-white m-auto font-semibold text-lg -mt-0.5 short:text-base">
            1
          </p>
        </div>
        <div className="hidden sm:flex items-center justify-start w-full space-x-1">
          <svg
            className="h-5 fill-current 2xl:h-6"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
              fill="#F4900C"
            />
            <path
              d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
              fill="#FFCC4D"
            />
            <path
              d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
              fill="#FFE8B6"
            />
            <path
              d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
              fill="#FFAC33"
            />
            <path
              d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
              fill="#FFD983"
            />
          </svg>
          <p className="font-semibold text-lg">
            {targetCustomer && targetCustomer.isPaidSubscriber ? "67" : "75"}
          </p>
        </div>
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          togglePurchasePowerUpForm(1);
        }}
        className="px-2 xshort:px-1 w-full shrink-0 xshort:py-1 short:py-1.5 short:px-1.5 py-2 sm:pb-1 hover:bg-opacity-80 transition-opacity flex-col space-y-1 rounded-2xl short:rounded-xl bg-white flex items-center justify-center"
      >
        <div className="bg-gray-100 relative rounded-xl short:rounded-lg py-3 short:py-2 w-full flex items-center justify-center">
          <svg
            className="h-12 short:h-10 xshort:h-8 fill-current text-red-600"
            viewBox="0 0 62 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M31 57L26.505 52.8997C10.54 38.3935 0 28.8262 0 17.0845C0 7.51717 7.502 0 17.05 0C22.444 0 27.621 2.51608 31 6.4921C34.379 2.51608 39.556 0 44.95 0C54.498 0 62 7.51717 62 17.0845C62 28.8262 51.46 38.3935 35.495 52.9308L31 57Z" />
          </svg>

          <p className="absolute text-white m-auto font-semibold text-lg -mt-0.5 short:text-base">
            2
          </p>
        </div>
        <div className="hidden sm:flex items-center justify-start w-full space-x-1">
          <svg
            className="h-5 fill-current 2xl:h-6"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
              fill="#F4900C"
            />
            <path
              d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
              fill="#FFCC4D"
            />
            <path
              d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
              fill="#FFE8B6"
            />
            <path
              d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
              fill="#FFAC33"
            />
            <path
              d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
              fill="#FFD983"
            />
          </svg>
          <p className="font-semibold text-lg">
            {targetCustomer && targetCustomer.isPaidSubscriber ? "130" : "145"}
          </p>
        </div>
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          togglePurchasePowerUpForm(2);
        }}
        className="px-2 xshort:px-1 w-full shrink-0 xshort:py-1 short:py-1.5 short:px-1.5 py-2 sm:pb-1 hover:bg-opacity-80 transition-opacity flex-col space-y-1 rounded-2xl short:rounded-xl bg-white flex items-center justify-center"
      >
        <div className="bg-gray-100 relative rounded-xl short:rounded-lg py-3 short:py-2 w-full flex items-center justify-center">
          <svg
            className="h-12 short:h-10 xshort:h-8 fill-current text-red-600"
            viewBox="0 0 62 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M31 57L26.505 52.8997C10.54 38.3935 0 28.8262 0 17.0845C0 7.51717 7.502 0 17.05 0C22.444 0 27.621 2.51608 31 6.4921C34.379 2.51608 39.556 0 44.95 0C54.498 0 62 7.51717 62 17.0845C62 28.8262 51.46 38.3935 35.495 52.9308L31 57Z" />
          </svg>
          <p className="absolute text-white m-auto font-semibold text-lg -mt-0.5 short:text-base">
            5
          </p>
        </div>
        <div className="hidden sm:flex items-center justify-start w-full space-x-1">
          <svg
            className="h-5 fill-current 2xl:h-6"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
              fill="#F4900C"
            />
            <path
              d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
              fill="#FFCC4D"
            />
            <path
              d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
              fill="#FFE8B6"
            />
            <path
              d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
              fill="#FFAC33"
            />
            <path
              d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
              fill="#FFD983"
            />
          </svg>
          <p className="font-semibold text-lg">
            {targetCustomer && targetCustomer.isPaidSubscriber ? "315" : "350"}
          </p>
        </div>
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          togglePurchasePowerUpForm(3);
        }}
        className="px-2 xshort:px-1 w-full shrink-0 xshort:py-1 short:py-1.5 short:px-1.5 py-2 sm:pb-1 hover:bg-opacity-80 transition-opacity flex-col space-y-1 rounded-2xl short:rounded-xl bg-white items-center justify-center hidden sm:flex"
      >
        <div className="bg-gray-100 relative rounded-xl short:rounded-lg py-3 short:py-2 w-full flex items-center justify-center">
          <svg
            className="h-12 short:h-10 xshort:h-8 fill-current text-red-600"
            viewBox="0 0 62 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M31 57L26.505 52.8997C10.54 38.3935 0 28.8262 0 17.0845C0 7.51717 7.502 0 17.05 0C22.444 0 27.621 2.51608 31 6.4921C34.379 2.51608 39.556 0 44.95 0C54.498 0 62 7.51717 62 17.0845C62 28.8262 51.46 38.3935 35.495 52.9308L31 57Z" />
          </svg>
          <p className="absolute text-white m-auto font-semibold text-lg -mt-0.5 short:text-base">
            10
          </p>
        </div>
        <div className="hidden sm:flex items-center justify-start w-full space-x-1">
          <svg
            className="h-5 fill-current 2xl:h-6"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
              fill="#F4900C"
            />
            <path
              d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
              fill="#FFCC4D"
            />
            <path
              d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
              fill="#FFE8B6"
            />
            <path
              d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
              fill="#FFAC33"
            />
            <path
              d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
              fill="#FFD983"
            />
          </svg>
          <p className="font-semibold text-lg">
            {targetCustomer && targetCustomer.isPaidSubscriber ? "599" : "670"}
          </p>
        </div>
      </button>
    </div>
  );
}
