import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { useInView } from "react-intersection-observer";
import { adminClient } from "../../GraphqlApolloClients";

import {
  FETCH_CUSTOMERS,
  RESET_BRAND_CONTACT_PFP_MUTATION,
  TOGGLE_BLOCK_BRAND_CONTACT_MUTATION,
} from "../../util/gqlFunctions";
import TopicIcon from "./TopicIcon";

function AdminBrandContactBox({
  index,
  navigate,
  tknightBrandContact,
  tknightBrandContactIndexToScrollIntoView,

  setTKnightBrandContactIndexInView,
  disabled,
}) {
  const tknightBrandContactRef = useRef();

  const {
    ref: boxRef,
    inView: isBoxVisible,
    // entry,
  } = useInView({
    /* Optional options */
    threshold: 0.7,
  });

  useEffect(() => {
    if (index === tknightBrandContactIndexToScrollIntoView) {
      tknightBrandContactRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [tknightBrandContactIndexToScrollIntoView, index]);

  useEffect(() => {
    if (isBoxVisible && !disabled) {
      setTKnightBrandContactIndexInView(index);
    }
  }, [isBoxVisible, disabled, index, setTKnightBrandContactIndexInView]);

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const resetInterval = 1000;

  useEffect(() => {
    let timeout;
    if (isLinkCopied && resetInterval) {
      timeout = setTimeout(() => setIsLinkCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isLinkCopied, resetInterval]);
  const [errors, setErrors] = useState({});

  const [isEmailCopied, setIsEmailCopied] = useState(null);

  useEffect(() => {
    let timeout;
    if (isEmailCopied && resetInterval) {
      timeout = setTimeout(() => setIsEmailCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isEmailCopied, resetInterval]);

  const copyEmail = async (e) => {
    e.preventDefault();

    navigator.clipboard.writeText(tknightBrandContact.email);
    setIsEmailCopied(true);
  };

  const [isBlocked, setIsBlocked] = useState(tknightBrandContact.isBlocked);

  const [toggleBlockBrandContact, { loading: loadingBlockCustomer }] =
    useMutation(TOGGLE_BLOCK_BRAND_CONTACT_MUTATION, {
      refetchQueries: [
        {
          query: FETCH_CUSTOMERS,
          // variables: { triviaNightId: triviaNight.id },
        },
      ],
      update(_, { data: { toggleBlockBrandContact: blockedCustomer } }) {
        setErrors({});
        setIsBlocked(!isBlocked);
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
          console.log(errors);
        }
      },
      client: adminClient,
      variables: {
        customerId: tknightBrandContact.id,
        blockedReason:
          "We had to block your account because we detected suspicious activities on your IP address through ipqualityscore.com. You can create a new account with a new device to keep playing.",
      },
    });

  function toggleBlockBrandContactCallback(e) {
    e.preventDefault();
    toggleBlockBrandContact();
  }

  const [isPFPReset, setIsPFPReset] = useState(
    tknightBrandContact.profilePicUrl ===
      "https://tknight-media.s3.amazonaws.com/profile.png"
  );

  const [resetBrandContactPFP, { loading: loadingResetBrandContactPFP }] =
    useMutation(RESET_BRAND_CONTACT_PFP_MUTATION, {
      // refetchQueries: [
      //   {
      //     query: FETCH_CUSTOMERS,
      //     // variables: { triviaNightId: triviaNight.id },
      //   },
      // ],
      update(_, { data: { resetBrandContactPFP: customerEdited } }) {
        setErrors({});
        setIsPFPReset(true);
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: adminClient,
      variables: {
        customerId: tknightBrandContact.id,
      },
    });

  function resetBrandContactPFPCallback(e) {
    e.preventDefault();
    resetBrandContactPFP();
  }

  return (
    <div ref={tknightBrandContactRef} className="w-full shrink-0">
      <div
        ref={boxRef}
        className="w-full sm:px-10 px-5 snap-center shrink-0 pb-5 pt-4 sm:pb-10 sm:pt-9 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl short:px-2 short:py-2"
      >
        <div className="flex whitespace-nowrap items-center space-x-2 justify-start w-full overflow-x-auto">
          <img
            src={
              isPFPReset
                ? "https://tknight-media.s3.amazonaws.com/profile.png"
                : tknightBrandContact.profilePicUrl
            }
            className={`h-16 short:h-12 aspect-square object-cover object-center origin-center rounded-md mr-2 short:mr-1`}
            alt={tknightBrandContact.name}
          />
          <div className="flex-1 w-full flex flex-col items-start justify-start truncate">
            <p className="font-semibold text-gray-700 text-xl short:text-lg leading-tight short:leading-tight w-full truncate ">
              {tknightBrandContact.name}
            </p>
            <p className=" text-gray-500 leading-tight">
              Active {moment(tknightBrandContact.lastActiveAt).fromNow()}
            </p>
          </div>
        </div>

        <div className="w-full mt-2 items-center justify-start space-x-2 flex ">
          <button
            onClick={toggleBlockBrandContactCallback}
            disabled={loadingBlockCustomer}
            className={`${
              loadingBlockCustomer ? "bg-gray-300" : "hover:bg-opacity-50"
            } ${
              isBlocked ? "bg-blue-600" : "bg-red-700"
            } shrink-0 transition-all w-10 short:w-8 aspect-square rounded-full `}
          >
            <svg
              className="p-2 fill-current text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM4 12C4 7.58 7.58 4 12 4C13.85 4 15.55 4.63 16.9 5.69L5.69 16.9C4.63 15.55 4 13.85 4 12ZM7.1 18.31C8.45 19.37 10.15 20 12 20C16.42 20 20 16.42 20 12C20 10.15 19.37 8.45 18.31 7.1L7.1 18.31Z"
              />
            </svg>
          </button>

          {!isPFPReset ? (
            <button
              onClick={resetBrandContactPFPCallback}
              disabled={loadingResetBrandContactPFP}
              className={`${
                loadingResetBrandContactPFP
                  ? "bg-gray-300"
                  : "hover:bg-opacity-50"
              } ${
                isBlocked ? "bg-blue-600" : "bg-red-700"
              } shrink-0 pl-2 pb-0.5 transition-all w-10 short:w-8 aspect-square rounded-full `}
            >
              <svg
                className="w-6 short:w-4 fill-current text-white"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="add_photo_alternate_24px">
                  <path
                    id="icon/image/add_photo_alternate_24px"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.3806 1.96365L18.502 4.08497L20.6233 1.96365L22.0375 3.37787L19.9162 5.49919L22.0304 7.61344C22.0304 7.61344 20.6304 9.02765 20.6162 9.02765L18.502 6.9134L16.3806 9.03472C16.3806 9.03472 14.9806 7.62051 14.9664 7.62051L17.0878 5.49919L14.9664 3.37787L16.3806 1.96365ZM17.5 20.5H3.5V6.50001H12.5V4.50001H3.5C2.4 4.50001 1.5 5.40001 1.5 6.50001V20.5C1.5 21.6 2.4 22.5 3.5 22.5H17.5C18.6 22.5 19.5 21.6 19.5 20.5V11.5H17.5V20.5ZM9.71 17.33L7.75 14.97L5 18.5H16L12.46 13.79L9.71 17.33Z"
                  />
                </g>
              </svg>
            </button>
          ) : (
            <></>
          )}

          <button
            onClick={copyEmail}
            className={`${
              isEmailCopied ? "bg-yellow-600" : "hover:bg-opacity-50"
            } bg-black shrink-0 transition-all w-10 short:w-8 aspect-square rounded-full `}
          >
            <svg
              className="p-2 fill-current text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM4 8L12 13L20 8V18H4V8ZM4 6L12 11L20 6H4Z"
              />
            </svg>
          </button>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-4 w-full rounded-lg border-gray-200 border">
          <p>ID</p>
          <p>{tknightBrandContact.id}</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Username</p>
          <p>@{tknightBrandContact.username}</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Joined on</p>
          <p>{moment(tknightBrandContact.createdAt).format("D MMM, YYYY")}</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Location</p>
          <p>
            {tknightBrandContact.city ? tknightBrandContact.city + ", " : ""}
            {tknightBrandContact.country}
          </p>
        </div>
        {/* 
        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Ad Campaigns</p>
          <p>{tknightBrandContact.numOfBattlesPlayed}</p>
        </div> */}

        {tknightBrandContact.recentTopicIds &&
          tknightBrandContact.recentTopicIds.length > 0 && (
            <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
              <p>Recent topics ({tknightBrandContact.recentTopicIds.length})</p>
              <div className="max-h-8 items-center justify-start flex-col space-y-2 overflow-y-auto flex">
                {tknightBrandContact.recentTopicIds &&
                  tknightBrandContact.recentTopicIds.length > 0 &&
                  tknightBrandContact.recentTopicIds.map((rT) => (
                    <TopicIcon
                      key={rT}
                      topicId={rT}
                      // disabled={true}
                      hideSeek={true}
                      navigate={navigate}
                    />
                  ))}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default AdminBrandContactBox;
