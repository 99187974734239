import React from "react";

export default function EmptyLeaderPodium() {
  const defaultPicUrl = "https://tknight-media.s3.amazonaws.com/profile.png";

  return (
    <div className="flex items-end text-center w-full my-6 max-w-sm justify-center space-x-4 short:space-x-0 sm:space-x-0">
      <div className="flex w-1/3 truncate flex-col items-center justify-end">
        <div
          className={`bg-white/30 shadow-lg relative flex items-center justify-end flex-col transition-all p-1.5 rounded-full aspect-square w-20 short:w-[4.2rem]`}
        >
          <img
            src={defaultPicUrl}
            alt={"2nd place"}
            className="border-white bg-white border-2 box-border rounded-full object-cover aspect-square "
          />

          <p className=" absolute bottom-0 bg-white shadow rounded-full aspect-square w-8 -mb-3 flex items-center justify-center">
            <span className="text-lg short:text-base">2</span>
          </p>
        </div>

        <p className=" font-semibold mt-12 short:mt-10 w-full text-white text-lg short:text-base truncate">
          --
        </p>
      </div>
      <div className="flex flex-col items-center justify-end">
        <div
          className={`bg-white/30 shadow-lg relative flex items-center justify-end flex-col transition-all p-2 rounded-full aspect-square w-32 short:w-24 short:p-2`}
        >
          <img
            src={defaultPicUrl}
            alt={"1st place"}
            className="border-white bg-white border-2 box-border rounded-full object-cover aspect-square"
          />

          <p className=" absolute bottom-0 bg-white rounded-full aspect-square w-10 shadow -mb-3.5 flex items-center justify-center short:w-8">
            <span className="text-lg short:text-base">1</span>
          </p>
        </div>

        <p className="font-semibold mt-8 text-white text-lg short:text-base">
          --
        </p>
      </div>
      <div className="flex w-1/3 truncate flex-col items-center justify-end">
        <div
          className={`bg-white/30 shadow-lg relative flex items-center justify-end flex-col transition-all p-1.5 rounded-full aspect-square w-20 short:w-[4.2rem]`}
        >
          <img
            src={defaultPicUrl}
            alt={"3rd place"}
            className="border-white bg-white border-2 box-border rounded-full object-cover aspect-square"
          />

          <p className=" absolute bottom-0 bg-white shadow rounded-full aspect-square w-8 -mb-3 flex items-center justify-center">
            <span className="text-lg short:text-base">3</span>
          </p>
        </div>

        <p className=" font-semibold mt-12 short:mt-10 w-full text-white text-lg short:text-base truncate">
          --
        </p>
      </div>
    </div>
  );
}
