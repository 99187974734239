import { jwtDecode } from "jwt-decode";
import React, { createContext, useReducer } from "react";
import { guestClient } from "../GraphqlApolloClients";
import { isRunningInRNWebView } from "../util/hooks";

const initialState = {
  guest: null,
};

if (localStorage.getItem("guestJwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("guestJwtToken"));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("guestJwtToken");
  } else {
    initialState.guest = decodedToken;
  }
}

const GuestAuthContext = createContext({
  guest: null,
  loginGuest: (guestData) => {},
  logoutGuest: () => {},
});

function guestAuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN_GUEST":
      return {
        ...state,
        guest: action.payload,
      };
    case "LOGOUT_GUEST":
      return {
        ...state,
        guest: null,
      };
    default:
      return state;
  }
}

function GuestAuthProvider(props) {
  const [state, dispatch] = useReducer(guestAuthReducer, initialState);

  function loginGuest(guestData) {
    localStorage.setItem("guestJwtToken", guestData.token);
    const decodedToken = jwtDecode(guestData.token);

    dispatch({
      type: "LOGIN_GUEST",
      payload: decodedToken,
    });

    if (isRunningInRNWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "guestCustomerLogin",
          ...guestData,
        })
      );
    }
  }

  function logoutGuest() {
    guestClient.cache.reset();
    localStorage.removeItem("guestJwtToken");
    dispatch({ type: "LOGOUT_GUEST" });
    if (isRunningInRNWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "guestCustomerLogout",
        })
      );
    }
  }

  return (
    <GuestAuthContext.Provider
      value={{
        guest: state.guest,
        loginGuest,
        logoutGuest,
      }}
      {...props}
    />
  );
}

export { GuestAuthContext, GuestAuthProvider };
