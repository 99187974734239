import React from "react";

import moment from "moment";
import PieTimer from "./PieTimer";
const defaultPicUrl = "https://tknight-media.s3.amazonaws.com/profile.png";

function NewSellerAdBox({ createAdCallback, disabled, business }) {
  return (
    <button
      onClick={createAdCallback}
      disabled={disabled}
      className="w-full group shrink-0 text-left"
    >
      <div className="w-full shrink-0 relative">
        <div className="w-full relative overflow-hidden sm:px-10 px-5 snap-center shrink-0 pb-5 pt-4 sm:pb-8 sm:pt-7 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl">
          <div className="flex items-center space-x-2 justify-start w-full truncate">
            <PieTimer
              endTime={new Date()}
              imgUrl={business.logoUrl || defaultPicUrl}
              alt={business.name}
              width={50}
              lastXSeconds={0}
            />
            <div className="flex-1 w-full flex flex-col items-start justify-start truncate">
              <p className="font-semibold text-gray-700 text-xl short:text-lg leading-tight short:leading-tight w-full truncate ">
                Ad 1
              </p>
              <p className=" text-gray-500 leading-tight">
                {moment().format("D MMM, YYYY")}
              </p>
            </div>
            <div className="bg-black shrink-0 transition-all w-10 short:w-8 hover:bg-opacity-50 aspect-square rounded-full ">
              <svg
                className="p-2 fill-current text-white"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.375 16.875H7.875C6.01875 16.875 4.5 15.3562 4.5 13.5C4.5 11.6437 6.01875 10.125 7.875 10.125H12.375V7.875H7.875C4.77 7.875 2.25 10.395 2.25 13.5C2.25 16.605 4.77 19.125 7.875 19.125H12.375V16.875ZM19.125 7.875H14.625V10.125H19.125C20.9813 10.125 22.5 11.6437 22.5 13.5C22.5 15.3562 20.9813 16.875 19.125 16.875H14.625V19.125H19.125C22.23 19.125 24.75 16.605 24.75 13.5C24.75 10.395 22.23 7.875 19.125 7.875ZM18 12.375H9V14.625H18V12.375Z"
                />
              </svg>
            </div>
          </div>
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-4 w-full rounded-lg border-gray-200 border">
            <p>ID</p>
            <p>1243241</p>
          </div>
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Objective</p>
            <p>Views</p>
          </div>
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Daily Budget</p>
            <p>US${50}</p>
          </div>

          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Audience interests</p>
            <p>Beauty +3</p>
          </div>
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Audience gender</p>
            <p>Everyone</p>
          </div>
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Audience age</p>
            <p>18-24, 25-34</p>
          </div>

          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Target locations</p>
            <p>Toronto +3</p>
          </div>

          <>
            <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
              <p>Live at</p>
              <p>{moment().format("D MMM, YYYY, h:mm a")}</p>
            </div>
            <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
              <p>Duration</p>
              <p>1 week</p>
            </div>

            <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
              <p>Ends at</p>
              <p> {moment().add("1", "day").format("D MMM, YYYY, h:mm a")}</p>
            </div>
          </>
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Status</p>
            <p>Editing</p>
          </div>
          <div className="mt-4 text-center sm:text-lg flex items-center  text-gray-600 justify-start w-full overflow-hidden rounded-lg border-gray-200 border">
            <button
              disabled={true}
              className={`border-r px-8 short:px-4 flex items-center justify-center relative font-semibold border-gray-200 transition-all`}
            >
              <p className={`py-3`}>Edit</p>
            </button>

            <button
              disabled={true}
              className={`flex-1 shrink-0 py-3 font-semibold transition-all`}
            >
              <p className="">Send for approval</p>
            </button>
          </div>
          <div className="w-full z-50 h-full flex items-center justify-center transition-all group-hover:bg-opacity-50 bg-black bg-opacity-30 absolute inset-0">
            <svg
              className=" text-white z-50 fill-current h-24 group-hover:opacity-100 opacity-0 transition-all"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
            </svg>
          </div>
        </div>
      </div>
    </button>
  );
}

export default NewSellerAdBox;
