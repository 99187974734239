import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

import { brandContactClient } from "../../GraphqlApolloClients";

// import  { useForm } from "../../util/hooks";
import {
  END_AD_CAMPAIGN_MUTATION,
  FETCH_ALL_ADS,
} from "../../util/gqlFunctions";
import PieTimer from "./PieTimer";

function SellerAdBox({
  index,

  ad,
  adIndexToScrollIntoView,
  navigate,
  setAdIndexInView,
  setTargetAd,
  disabled,
}) {
  const adRef = useRef();

  const {
    ref: boxRef,
    inView: isBoxVisible,
    // entry,
  } = useInView({
    /* Optional options */
    threshold: 0.7,
  });

  useEffect(() => {
    if (index === adIndexToScrollIntoView) {
      adRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [adIndexToScrollIntoView, index]);

  useEffect(() => {
    if (isBoxVisible && !disabled) {
      setAdIndexInView(index);
    }
  }, [isBoxVisible, disabled, index, setAdIndexInView]);

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const resetInterval = 1000;

  useEffect(() => {
    let timeout;
    if (isLinkCopied && resetInterval) {
      timeout = setTimeout(() => setIsLinkCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isLinkCopied, resetInterval]);

  const [endAdCampaign] = useMutation(END_AD_CAMPAIGN_MUTATION, {
    refetchQueries: [{ query: FETCH_ALL_ADS }],
    update(_, { data: { endAdCampaign: closedAdSale } }) {},
    onError(err) {
      console.log(err);
    },
    variables: { adId: ad.id },
    client: brandContactClient,
  });

  return (
    <div ref={adRef} className="w-full shrink-0">
      <div
        ref={boxRef}
        className="w-full sm:px-10 px-5 snap-center shrink-0 pb-5 pt-4 sm:pb-10 sm:pt-9 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl short:px-2 short:py-2"
      >
        <div className="flex items-center space-x-2 justify-start w-full truncate">
          <PieTimer
            endTime={ad.status === 3 ? ad.campaignEndsAt : new Date()}
            imgUrl={
              ad.businessLogoUrl ||
              "https://tknight-media.s3.amazonaws.com/profile.png"
            }
            alt={ad ? ad.businessName : "Ad Timer"}
            width={50}
            lastXSeconds={0}
          />
          <div className="flex-1 w-full flex flex-col items-start justify-start truncate">
            <p className="font-semibold text-gray-700 text-xl short:text-lg leading-tight short:leading-tight w-full truncate ">
              {ad.campaignName}
            </p>
            <p className=" text-gray-500 leading-tight">
              {moment(ad.createdAt).format("D MMM, YYYY")}
            </p>
          </div>

          {ad.link && (
            <a
              href={"http://" + ad.link}
              target="_blank"
              rel="noreferrer"
              className="bg-black shrink-0 transition-all w-10 short:w-8 hover:bg-opacity-50 aspect-square rounded-full "
            >
              <svg
                className="p-2 fill-current text-white"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.375 16.875H7.875C6.01875 16.875 4.5 15.3562 4.5 13.5C4.5 11.6437 6.01875 10.125 7.875 10.125H12.375V7.875H7.875C4.77 7.875 2.25 10.395 2.25 13.5C2.25 16.605 4.77 19.125 7.875 19.125H12.375V16.875ZM19.125 7.875H14.625V10.125H19.125C20.9813 10.125 22.5 11.6437 22.5 13.5C22.5 15.3562 20.9813 16.875 19.125 16.875H14.625V19.125H19.125C22.23 19.125 24.75 16.605 24.75 13.5C24.75 10.395 22.23 7.875 19.125 7.875ZM18 12.375H9V14.625H18V12.375Z"
                />
              </svg>
            </a>
          )}
          {/* <a
            href={"/@" + ad.username}
            target="_blank"
            rel="noreferrer"
            className="bg-black shrink-0 transition-all w-12 hover:bg-opacity-50 aspect-square rounded-full "
          >
            <svg
              className="p-2 fill-current text-white"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.375 16.875H7.875C6.01875 16.875 4.5 15.3562 4.5 13.5C4.5 11.6437 6.01875 10.125 7.875 10.125H12.375V7.875H7.875C4.77 7.875 2.25 10.395 2.25 13.5C2.25 16.605 4.77 19.125 7.875 19.125H12.375V16.875ZM19.125 7.875H14.625V10.125H19.125C20.9813 10.125 22.5 11.6437 22.5 13.5C22.5 15.3562 20.9813 16.875 19.125 16.875H14.625V19.125H19.125C22.23 19.125 24.75 16.605 24.75 13.5C24.75 10.395 22.23 7.875 19.125 7.875ZM18 12.375H9V14.625H18V12.375Z"
              />
            </svg>
          </a> */}
          {/* <a
            href={"mailto:" + ad.email}
            target="_blank"
            rel="noreferrer"
            className="bg-black shrink-0 transition-all w-12 hover:bg-opacity-50 aspect-square rounded-full "
          >
            <svg
              className="p-2 fill-current text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM4 8L12 13L20 8V18H4V8ZM4 6L12 11L20 6H4Z"
              />
            </svg>
          </a> */}
        </div>
        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-4 w-full rounded-lg border-gray-200 border">
          <p>ID</p>
          <p>{ad.id}</p>
        </div>

        {ad.objectiveType !== null ? (
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Objective</p>
            <p>
              {ad.objectiveType === 0
                ? "Views"
                : ad.objectiveType === 1
                ? "Site traffic"
                : "Lead generation"}
            </p>
          </div>
        ) : (
          <></>
        )}
        {/* 
        {ad.dailyBudget && (
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Daily Budget</p>
            <p>US${(ad.dailyBudget / 100).toFixed(2)}</p>
          </div>
        )} */}
        {ad.targetAgeRanges && ad.targetAgeRanges.length > 0 && (
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Audience age range</p>
            <p>
              {ad.targetAgeRanges
                .map((ageRange, index) =>
                  ageRange === 0
                    ? "Kids"
                    : ageRange === 1
                    ? "18-24"
                    : ageRange === 2
                    ? "25-34"
                    : ageRange === 3
                    ? "35-44"
                    : ageRange === 4
                    ? "45-54"
                    : ageRange === 5
                    ? "55-64"
                    : "65+"
                )
                .join(", ")}
            </p>
          </div>
        )}
        {ad.targetGender !== null && (
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Gender</p>
            <p>
              {ad.targetGender === 0
                ? "Male"
                : ad.targetGender === 1
                ? "Female"
                : "Everyone"}
            </p>
          </div>
        )}

        {ad.targetInterests && ad.targetInterests.length > 0 && (
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Interests</p>
            <p>
              {ad.targetInterests[0]}
              {ad.targetInterests.length > 1 &&
                " +" + ad.targetInterests.length - 1}
            </p>
          </div>
        )}

        {ad.targetPlaces && ad.targetPlaces.length > 0 && (
          <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
            <p>Target locations</p>
            <p className=" whitespace-nowrap">
              {ad.targetPlaces[0]}
              {ad.targetPlaces.length > 1
                ? ` +${ad.targetPlaces.length - 1}`
                : ""}
            </p>
          </div>
        )}

        {/* {ad.numOfDaysOfCampaign && (
          <>
            <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
              <p>Live at</p>
              <p>{moment(ad.campaignStartsAt).format("D MMM, YYYY, h:mm a")}</p>
            </div>
            <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
              <p>Duration</p>
              <p>{ad.numOfDaysOfCampaign} days</p>
            </div>

            <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
              <p>Ends at</p>
              <p> {moment(ad.campaignEndsAt).format("D MMM, YYYY, h:mm a")}</p>
            </div>
          </>
        )} */}

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Status</p>
          <p>
            {ad.status === 0
              ? "Editing"
              : ad.status === 1
              ? "In review"
              : ad.status === 2
              ? "Approved"
              : ad.status === 3
              ? "LIVE"
              : ad.status === 4
              ? "Campaign complete"
              : ad.status === 5
              ? "Rejected approval request"
              : "Cancelled"}
          </p>
        </div>

        {(ad.status === 0 || ad.status === 5 || ad.status === 4) && (
          <div className="mt-4 text-center sm:text-lg flex items-center  text-gray-600 justify-start w-full overflow-hidden rounded-lg border-gray-200 border">
            <button
              // onClick={copyTrackingLink}
              onClick={(e) => {
                e.preventDefault();

                setTargetAd(index);
              }}
              className={` flex-1 px-8 short:px-4 flex items-center justify-center relative font-semibold border-gray-200 hover:bg-gray-50 transition-all`}
            >
              <p className={`py-3`}>
                {ad.status === 0 || ad.status === 5 || ad.status !== 4
                  ? "Edit"
                  : "View Details"}
              </p>
            </button>
          </div>
        )}

        {ad.status === 3 && (
          <div className="mt-4 text-center sm:text-lg flex items-center  text-gray-600 justify-start w-full overflow-hidden rounded-lg border-gray-200 border">
            {ad.status === 1 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTargetAd(index);

                  // toggleMakeRevisionPaymentRequestForm(index);
                }}
                className={`flex-1 border-r border-gray-200 shrink-0 py-3 font-semibold  hover:bg-gray-50 transition-all`}
              >
                <p className="">View</p>
              </button>
            )}
            {ad.status === 3 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  endAdCampaign();
                  // toggleMakeRevisionPaymentRequestForm(index);
                }}
                className={`${
                  ad.status === 3 ? "border-l border-gray-200" : ""
                } flex-1 shrink-0 py-3 font-semibold  hover:bg-green-50 transition-all`}
              >
                <p className="">End</p>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SellerAdBox;
