import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";

import {
  CREATE_AD_VIEW_MUTATION,
  FETCH_AD_BOOKMARK_BY_CUSTOMER,
  SEND_AD_CLICK_MUTATION,
  TOGGLE_AD_BOOKMARK_MUTATION,
} from "../../util/gqlFunctions";

function HorAdSmallBox({
  triviaNight,
  quiz,
  horAdUrl,
  horAdLink,
  targetCustomer,
  targetGuestCustomer,
  adId,
  targetAdBookmark,
  isOutOfLives,
  areCommentsInFocus,
  isInComms,
  removeBottomPadding,
}) {
  const [targetAdView, setTargetAdView] = useState(null);
  const [viewSentForAd, setViewSentForAd] = useState(null);
  const [sentAdClick, setSentAdClick] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(null);

  useEffect(() => {
    if (isBookmarked === null && targetAdBookmark) {
      setIsBookmarked(
        targetAdBookmark && targetAdBookmark.status === 0 ? true : false
      );
    }
  }, [isBookmarked, targetAdBookmark]);

  const [valuesForAd, setValuesForAd] = useState({
    adBookmarkId: targetAdBookmark ? targetAdBookmark.id : null,
    adId,
    adViewId: targetAdView ? targetAdView : null,
    quizId: quiz ? quiz.id : null,
    viewedAfterQuestion: quiz ? quiz.lastCompletedQ : null,
  });

  const [createAdView] = useMutation(CREATE_AD_VIEW_MUTATION, {
    update(_, { data: { createAdView: adView } }) {
      setTargetAdView(adView.id);

      setViewSentForAd(adId);
    },
    onError(err) {
      console.log(err);
    },
    client: targetCustomer ? customerClient : guestClient,
    variables: valuesForAd,
  });

  const [sendAdClick] = useMutation(SEND_AD_CLICK_MUTATION, {
    update(_, { data: { sendAdClick: adClick } }) {
      setSentAdClick(true);
      // setErrors({});
    },
    onError(err) {
      console.log(err);
    },
    client: targetCustomer ? customerClient : guestClient,
    variables: { adViewId: targetAdView || "" },
  });

  useEffect(() => {
    if (
      targetAdBookmark &&
      (!valuesForAd.adBookmarkId ||
        valuesForAd.adBookmarkId !== targetAdBookmark.id)
    ) {
      setValuesForAd({ ...valuesForAd, adBookmarkId: targetAdBookmark.id });
    } else if (
      targetAdView &&
      (!valuesForAd.adViewId || valuesForAd.adViewId !== targetAdView)
    ) {
      setValuesForAd({ ...valuesForAd, adViewId: targetAdView });
    } else if (adId && (!valuesForAd.adId || valuesForAd.adId !== adId)) {
      setValuesForAd({ ...valuesForAd, adId });
    }
  }, [targetAdView, targetAdBookmark, valuesForAd, adId]);

  const [adBookmarkErr, setAdBookmarkErr] = useState("");

  useEffect(() => {
    let timeout;
    if (adBookmarkErr) {
      timeout = setTimeout(() => setAdBookmarkErr(""), 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [adBookmarkErr]);

  const [toggleBookmarkAd, { loading: loadingToggleBookmark }] = useMutation(
    TOGGLE_AD_BOOKMARK_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_AD_BOOKMARK_BY_CUSTOMER,
          variables: { adId },
        },
      ],
      update(_, { data: { toggleBookmarkAd: adBookmark } }) {},
      onError(err) {
        if (err.graphQLErrors[0]) {
          console.log(err);

          console.log(err.graphQLErrors[0].extensions.errors.bookmarkErr);
          setAdBookmarkErr(err.graphQLErrors[0].extensions.errors.bookmarkErr);
          setIsBookmarked(!isBookmarked);
        }
      },
      client: targetCustomer ? customerClient : guestClient,
      variables: valuesForAd,
    }
  );

  // State to track whether the component has been displayed for 5 seconds
  const [hasDisplayedFor5Seconds, setHasDisplayedFor5Seconds] = useState(false);

  // Set a timer to mark that the component has been displayed for 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setHasDisplayedFor5Seconds(true);
    }, 5000); // 5 seconds

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // Call createAdView if the timer has expired
  useEffect(() => {
    if (
      hasDisplayedFor5Seconds &&
      viewSentForAd !== adId &&
      (targetCustomer || targetGuestCustomer)
    ) {
      createAdView();
    }
  }, [
    hasDisplayedFor5Seconds,
    viewSentForAd,
    adId,
    targetGuestCustomer,
    createAdView,
    targetCustomer,
  ]);

  return (
    <div
      className={`${
        areCommentsInFocus ? "aspect-video" : "aspect-[58/7] h-auto"
      } ${
        (((triviaNight || quiz) && (triviaNight || quiz).status === 5) ||
          isOutOfLives ||
          (!triviaNight && !quiz)) &&
        horAdLink
          ? "cursor-pointer"
          : ""
      } w-full ${
        removeBottomPadding ? "rounded-lg" : "mb-1 sm:mb-2 rounded"
      } hide-button-flash overflow-hidden group transition-all  shadow-lg shrink-0 bg-gray-200 bg-opacity-30 backdrop-blur z-10`}
      onClick={(e) => {
        if (
          (((triviaNight || quiz) && (triviaNight || quiz).status === 5) ||
            isOutOfLives ||
            (!triviaNight && !quiz)) &&
          horAdLink
        ) {
          if (!sentAdClick && targetAdView) {
            sendAdClick();
          }

          window.open("https://" + horAdLink, "_blank");
        }
      }}
    >
      {horAdUrl && horAdUrl.includes(".mp4") && (
        <video
          className="flex-grow-0 motion-safe:animate-fadeIn w-full h-full top-0 object-cover z-10"
          controls={false}
          autoPlay={true}
          muted={true}
          loop={true}
        >
          <source src={horAdUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {horAdUrl && !horAdUrl.includes(".mp4") && (
        <img
          className="flex-grow-0 origin-left motion-safe:animate-fadeIn w-full h-full top-0 object-cover z-10"
          src={horAdUrl}
          alt={"Ad"}
        />
      )}

      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleBookmarkAd();
          setIsBookmarked(
            targetAdBookmark && targetAdBookmark.status === 0 ? false : true
          );
        }}
        className={` ${
          adBookmarkErr
            ? "motion-safe:animate-shake hover:text-red-400 text-red-400"
            : " motion-safe:animate-none"
        } ${
          isInComms ? "m-1 p-1 sm:p-1.5 sm:m-1.5" : "m-2.5 p-1.5"
        } group-hover:opacity-100  hover:text-gray-300 text-gray-700 duration-150 top-4 opacity-0 absolute hover:shadow right-0 group/adBookmark bg-white bg-opacity-50  backdrop-blur rounded-full aspect-square  transition-all group-hover:top-0`}
        disabled={adBookmarkErr || loadingToggleBookmark ? true : false}
      >
        <div className="relative">
          {adBookmarkErr && (
            <p className="absolute right-full whitespace-nowrap  mr-4">
              {adBookmarkErr}
            </p>
          )}

          <svg
            className={`${isBookmarked ? "fill-current" : ""} ${
              isInComms ? "w-5" : "w-6"
            } stroke-current transition-all`}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3Z"
              strokeWidth="2"
            />
          </svg>
        </div>
      </button>
    </div>
  );
}

export default HorAdSmallBox;
