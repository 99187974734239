import React, { useContext, useEffect, useState } from "react";

import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate, useParams } from "react-router";
import TextInputField from "../../components/creator/TextInputField";

import { useMutation } from "@apollo/client";
import BlueFlatGradBack from "../../components/creator/BlueFlatGradBack";
import SubmitButton from "../../components/creator/SubmitButton";
import TopBar from "../../components/creator/TopBar";
import { CustomerAuthContext } from "../../context/customerAuth";
import {
  RESET_CUSTOMER_PASSWORD_MUTATION,
  SEND_PASSWORD_RESET_CODE_CUSTOMER,
} from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";

function ResetCustomerPassword() {
  usePageTracking();

  let navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const { resetCode } = useParams();

  const { customer, loginCustomer: loginCustomerContext } =
    useContext(CustomerAuthContext);

  useEffect(() => {
    if (customer) {
      navigate("/");
    }
  }, [customer, navigate]);

  useEffect(() => {
    document.title = "Reset Password | TKnight";
  }, []);

  const { onChange, values, setValues } = useForm(
    requestCustomerPasswordResetCallback,
    {
      email: "",
      phone: "",
      phoneCountryCode: "+1",
      phoneCountryShortForm: "CA",
      passwordResetCode: resetCode ? resetCode : "",
      newPassword: "",
      usingPhone: true,
    }
  );

  const [isResetCodeEmailSent, setIsResetCodeEmailSent] = useState(
    resetCode ? true : false
  );

  const [sendCustomerResetPasswordCode, { loading }] = useMutation(
    SEND_PASSWORD_RESET_CODE_CUSTOMER,
    {
      update(_, { data: { sendCustomerResetPasswordCode } }) {
        setErrors({});
        setIsResetCodeEmailSent(true);
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
    }
  );

  const [resetCustomerPassword, { loading: loadingResetPassword }] =
    useMutation(RESET_CUSTOMER_PASSWORD_MUTATION, {
      update(_, { data: { resetCustomerPassword: customerData } }) {
        loginCustomerContext(customerData);
        navigate("/");
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0]);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
    });

  function requestCustomerPasswordResetCallback(e) {
    e.preventDefault();
    sendCustomerResetPasswordCode({
      variables: { ...values, phone: values.phoneCountryCode + values.phone },
    });
  }

  function resetCustomerPasswordCallback(e) {
    e.preventDefault();
    resetCustomerPassword({
      variables: { ...values, phone: values.phoneCountryCode + values.phone },
    });
  }

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-gray-50">
      <BlueFlatGradBack gradIndex={0} />
      <div className="relative z-10 short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-10 lg:pb-0">
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          page="Forgot"
        />

        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-10 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
            Reset your password
          </p>

          <p className="text-gray-500 w-full mt-2 flex-shrink-0 leading-tight">
            {isResetCodeEmailSent
              ? "Password reset code sent. Please enter the code below."
              : "Enter the phone number or email address associated with your account and we'll send you a code to reset your password."}
          </p>

          {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
          <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6 mb-8">
            {values.usingPhone ? (
              <TextInputField
                title="Phone"
                name="phone"
                type="tel"
                placeholder=""
                error={errors.phone}
                disabled={loading}
                values={values}
                onChange={onChange}
                setValues={setValues}
                value={values.phone}
                phoneCodePrefix={
                  values.phoneCountryShortForm + " " + values.phoneCountryCode
                }
                phoneCountryCode={values.phoneCountryCode}
                actionBtnTitle={"Use email instead"}
                onActionBtnPress={(e) => {
                  e.preventDefault();
                  setValues({ ...values, usingPhone: !values.usingPhone });
                }}
              />
            ) : (
              <TextInputField
                title="Email"
                name="email"
                type="email"
                error={errors.email || errors.name}
                disabled={loading}
                onChange={onChange}
                value={values.email}
                actionBtnTitle={"Use phone instead"}
                onActionBtnPress={(e) => {
                  e.preventDefault();
                  setValues({ ...values, usingPhone: !values.usingPhone });
                }}
              />
            )}

            {isResetCodeEmailSent && (
              <>
                <TextInputField
                  title="Reset code"
                  name="passwordResetCode"
                  placeholder={"______"}
                  disabled={loading || loadingResetPassword}
                  error={errors.passwordResetCode}
                  onChange={onChange}
                  value={values.passwordResetCode}
                />
                <TextInputField
                  title="New password"
                  name="newPassword"
                  type="password"
                  disabled={loading || loadingResetPassword}
                  error={errors.newPassword}
                  onChange={onChange}
                  value={values.newPassword}
                />
              </>
            )}
          </div>

          <SubmitButton
            title={!isResetCodeEmailSent ? "Generate Code" : "Confirm"}
            disabled={loading || loadingResetPassword}
            onClickFunc={(e) => {
              if (isResetCodeEmailSent) {
                resetCustomerPasswordCallback(e);
              } else {
                requestCustomerPasswordResetCallback(e);
              }
            }}
            errors={errors}
          />

          <p className="text-center text-gray-600 mt-4 sm:mb-0 mb-2 text-sm sm:text-base">
            <button
              onClick={(e) => {
                navigate("/login");
              }}
              className="text-blue-600 hover:text-black transition-all"
            >
              Return to sign in
            </button>
          </p>
        </div>

        <p className="text-gray-500 transition-all short:px-3 px-6 font-semibold mb-10 short:mb-4 text-sm text-center sm:text-base">
          <button
            className="hover:text-gray-700 font-semibold transition-all"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            © TKnight
          </button>{" "}
          <span className="text-gray-200 mx-1 sm:mx-2"> - </span>
          <a
            className="hover:text-gray-700 font-semibold transition-all"
            href={"mailto:info@tknight.live"}
            target="_blank"
            rel="noreferrer"
          >
            Contact
          </a>
          <span className="text-gray-200 mx-1 sm:mx-2"> - </span>
          <button
            className="hover:text-gray-700 font-semibold transition-all"
            onClick={(e) => {
              e.preventDefault();
              navigate("/terms");
            }}
          >
            Privacy &amp; terms
          </button>
        </p>
      </div>
    </div>
  );
}

export default ResetCustomerPassword;
