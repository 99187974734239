import React from "react";

export default function PlayerStatsBar({ targetCustomer }) {
  return (
    <div className="flex bg-gray-50 rounded-full py-1 px-3 font-semibold text-sm leading-tight items-center justify-start text-gray-700">
      <svg
        className="h-5 fill-current"
        viewBox="0 0 61 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3 74L27.45 46.25H0L42.7 0L33.55 27.75H61L18.3 74Z"
          fill="#D0D500"
        />
      </svg>

      <p className="ml-2">{targetCustomer.maxFocusStreak}</p>
      <svg
        className="h-5 ml-3"
        viewBox="0 0 74 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M65.7778 8.22222H57.5556V0H16.4444V8.22222H8.22222C3.7 8.22222 0 11.9222 0 16.4444V20.5556C0 31.0389 7.89333 39.59 18.0478 40.8644C20.6378 47.0311 26.1878 51.6767 32.8889 53.0333V65.7778H16.4444V74H57.5556V65.7778H41.1111V53.0333C47.8122 51.6767 53.3622 47.0311 55.9522 40.8644C66.1067 39.59 74 31.0389 74 20.5556V16.4444C74 11.9222 70.3 8.22222 65.7778 8.22222ZM8.22222 20.5556V16.4444H16.4444V32.1489C11.6756 30.4222 8.22222 25.9 8.22222 20.5556ZM65.7778 20.5556C65.7778 25.9 62.3244 30.4222 57.5556 32.1489V16.4444H65.7778V20.5556Z"
          fill="#FFA439"
        />
        <path
          d="M45.2224 12.3333L41.1113 8.22217H49.3336L45.2224 20.5555V12.3333Z"
          fill="white"
        />
        <path
          d="M57.5554 71.9443H16.4443V73.9999H57.5554V71.9443Z"
          fill="black"
        />
      </svg>

      <p className="ml-2">{targetCustomer?.numOfBattlesWon || 0}</p>
      <svg
        className="h-5 ml-3 fill-current text-red-600"
        viewBox="0 0 82 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M41 11.5544C34.2043 -6.6058 0 -3.90817 0 23.5558C0 37.239 10.455 55.4463 41 74C71.545 55.4463 82 37.239 82 23.5558C82 -3.75008 47.8333 -6.71343 41 11.5544Z"
          fill="#D21212"
        />
      </svg>

      <p className="ml-2">{targetCustomer.availableExtraLivesCount}</p>
      <svg
        className="h-5 ml-3 fill-current text-black"
        viewBox="0 0 74 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37 74C57.4345 74 74 57.4345 74 37C74 16.5655 57.4345 0 37 0C16.5655 0 0 16.5655 0 37C0 57.4345 16.5655 74 37 74Z"
          fill="#DD2E44"
        />
        <path
          d="M37 64.75C52.3259 64.75 64.75 52.3259 64.75 37C64.75 21.6741 52.3259 9.25 37 9.25C21.6741 9.25 9.25 21.6741 9.25 37C9.25 52.3259 21.6741 64.75 37 64.75Z"
          fill="white"
        />
        <path
          d="M36.9999 57.5554C48.3524 57.5554 57.5554 48.3524 57.5554 36.9999C57.5554 25.6474 48.3524 16.4443 36.9999 16.4443C25.6474 16.4443 16.4443 25.6474 16.4443 36.9999C16.4443 48.3524 25.6474 57.5554 36.9999 57.5554Z"
          fill="#DD2E44"
        />
        <path
          d="M37.0003 49.3332C43.8118 49.3332 49.3337 43.8113 49.3337 36.9998C49.3337 30.1883 43.8118 24.6665 37.0003 24.6665C30.1888 24.6665 24.667 30.1883 24.667 36.9998C24.667 43.8113 30.1888 49.3332 37.0003 49.3332Z"
          fill="white"
        />
        <path
          d="M36.9997 43.1668C40.4054 43.1668 43.1663 40.4059 43.1663 37.0002C43.1663 33.5944 40.4054 30.8335 36.9997 30.8335C33.5939 30.8335 30.833 33.5944 30.833 37.0002C30.833 40.4059 33.5939 43.1668 36.9997 43.1668Z"
          fill="#DD2E44"
        />
        <path
          opacity="0.2"
          d="M37.4935 37.5796L64.5117 61.7406C64.5117 61.7406 59.0707 68.6801 48.2934 72.2424L36.1348 37.8633L37.4935 37.5796Z"
          fill="black"
        />
        <path
          d="M37.6047 39.0555C37.1994 39.0569 36.803 38.9377 36.4657 38.7131C36.1284 38.4886 35.8654 38.1688 35.7103 37.7945C35.5552 37.4201 35.5149 37.0081 35.5945 36.6108C35.6742 36.2135 35.8701 35.8488 36.1575 35.5631L37.3148 34.4059C37.6984 34.0221 38.2186 33.8063 38.7612 33.8062C39.3038 33.806 39.8242 34.0213 40.208 34.4048C40.5918 34.7884 40.8075 35.3086 40.8077 35.8512C40.8079 36.3938 40.5926 36.9142 40.209 37.298L39.0497 38.4553C38.8606 38.646 38.6354 38.7973 38.3874 38.9003C38.1393 39.0034 37.8733 39.0561 37.6047 39.0555Z"
          fill="#FFAC33"
        />
        <path
          d="M49.3662 14.3499C48.5378 18.6234 49.3662 23.9926 49.3662 23.9926L63.8661 8.80611C64.4643 5.81733 62.7808 0.723662 60.3347 0.0946624C57.8885 -0.534338 51.9501 1.74527 49.3662 14.3499ZM59.6502 24.6339C55.3767 25.4623 50.0075 24.6339 50.0075 24.6339L65.194 10.134C68.1828 9.53583 73.2764 11.2193 73.9054 13.6654C74.5344 16.1116 72.2548 22.0501 59.6502 24.6339Z"
          fill="#55ACEE"
        />
        <path
          d="M67.4182 9.21899L43.5285 36.1509C42.7844 36.895 40.0896 37.9639 38.3691 36.2454C36.6506 34.527 37.5078 31.9699 38.2519 31.2237L65.3976 7.19632C65.3976 7.19632 66.7871 5.89104 67.7799 6.88387C68.7728 7.87671 67.4182 9.21899 67.4182 9.21899Z"
          fill="#3A87C2"
        />
      </svg>

      <p className="ml-2">{targetCustomer.accuracy}%</p>
    </div>
  );
}
