import React, { useEffect } from "react";

import { Gradient } from "../../util/Gradient";

export default function BlueFullGradBack({ gradIndex }) {
  useEffect(() => {
    const gradient = new Gradient();

    gradient.initGradient("#gradient-canvas-" + gradIndex || 0);
  }, [gradIndex]);

  return (
    <div className="z-10 w-screen h-full bg-blue-900 origin-top-left -skew-y-12 object-cover absolute inset-0 ">
      <canvas
        id={`gradient-canvas-${gradIndex || 0}`}
        className="motion-safe:animate-fadeInFast"
        data-js-darken-top
        data-transition-in
      />
    </div>
  );
}
