import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";
import { FETCH_QUESTION_VIEW_BY_CUSTOMER } from "../../util/gqlFunctions";
import { defaultPicUrl } from "../../util/hooks";

export default function QuestionPlayIcon({
  question,
  index,
  setQuestionIdToScrollIntoView,
  questionIdToScrollIntoView,
  questionIdInView,
  setQuestionFetchErr,
  setLastIncorrectQIndex,
  targetCustomer,
  indexVisible,
  offset,
  topicShortForm,
  setIsQFetchErrShown,
  seenIds,
  refetchRecommendedQuestions,
  setLoadingRecommendedQs,
  wantToScrollToQId,
  disabled,
  pageSize,
  setWantToScrollToQId,
  targetGuestCustomer,
  setQFetchErrShownAtQId,
}) {
  const {
    data: { getQuestionViewByCustomer: questionView } = {},
    loading: loadingQuestionView,
  } = useQuery(FETCH_QUESTION_VIEW_BY_CUSTOMER, {
    client: targetCustomer ? customerClient : guestClient,

    variables: { questionId: question.id },
  });

  const [isClicked, setIsClicked] = useState(false);
  const [createdTimeout, setCreatedTimeout] = useState(false);

  useEffect(() => {
    let timeout;
    if (isClicked && !createdTimeout) {
      setCreatedTimeout(true);
      timeout = setTimeout(() => {
        setIsClicked(false);
        setCreatedTimeout(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isClicked, createdTimeout]);

  return (
    <button
      onClick={async (e) => {
        e.preventDefault();

        setIsClicked(true);

        if (question.id === questionIdInView) {
        } else if (questionView && questionView.status === 1) {
          setQuestionIdToScrollIntoView(question.id);
        } else if (
          (index - offset) % 7 === 6 &&
          (!questionView || questionView.answerResult === -1)
        ) {
          setQuestionFetchErr("Can't skip to milestone question");
          setQFetchErrShownAtQId(questionIdInView);
          setIsQFetchErrShown(true);
        } else {
          setLoadingRecommendedQs(true);
          setWantToScrollToQId(question.id);

          const result = await refetchRecommendedQuestions({
            topicShortForm,
            pageSize,
            seenIds,
            clickedQuestionId: question.id,
          });
          if (result) {
            setLoadingRecommendedQs(false);
            setQuestionIdToScrollIntoView(question.id);
            setWantToScrollToQId(null);
            setLastIncorrectQIndex(null);
          }
        }
      }}
      className={`${
        isClicked ? " motion-safe:animate-popUp " : ""
      } px-1 md:px-2 group ${
        index === 0 ? "pb-1.5 2xl:pb-2" : "py-1.5 2xl:py-2"
      }`}
      disabled={disabled}
    >
      <div className="rounded-md group bg-black sm:rounded-lg flex items-center justify-center relative border-white box-border overflow-hidden border 2xl:border-2 short:w-[1.25rem] w-[1.35rem] sm:w-7 2xl:w-10 aspect-square">
        <img
          src={question.topicLogoUrl || defaultPicUrl}
          alt={question.topicName}
          className={`w-full h-full ${
            isClicked ? "opacity-80" : ""
          } object-cover group-hover:opacity-80 transition-all`}
        ></img>
        {indexVisible === index ? (
          <svg
            className="w-1 sm:w-1.5 2xl:w-3 m-auto absolute aspect-square inset-0 motion-safe:animate-scaleIn"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
          </svg>
        ) : (questionView &&
            (questionView.wasMainFocusStreakMilestone ||
              questionView.wasTopicFocusStreakMilestone)) ||
          ((index - offset) % 7 === 6 &&
            (!questionView ||
              (questionView.status === 1 &&
                (questionView.answerResult === 3 ||
                  questionView.answerResult === 2)))) ? (
          <div
            className={`${
              questionView &&
              (questionView.answerResult === 3 ||
                questionView.answerResult === 2)
                ? "bg-white"
                : topicShortForm || questionView?.wasTopicFocusStreakMilestone
                ? "bg-blue-300"
                : "bg-[#D0D500]"
            } w-full h-full flex items-center justify-center  absolute`}
          >
            {topicShortForm || questionView?.wasTopicFocusStreakMilestone ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 sm:w-4 2xl:w-5 fill-current text-black aspect-square inset-0 motion-safe:animate-scaleIn"
                viewBox="0 0 24 24"
              >
                <path d="M13.664 5.789c3.015-.714 7.986.497 10.336 2.568-1.74-4.282-6.446-7.357-11.993-7.357-4.904 0-9.084 3.078-9.863 7.265-.794 4.258 2.152 8.84 8.208 9.923-3.019.714-8.005-.5-10.352-2.581 1.729 4.3 6.445 7.393 12.007 7.393l.308-.003c4.828-.099 8.882-3.169 9.633-7.294.778-4.275-2.194-8.827-8.284-9.914zm1 7.643c-.759.771-2.571.755-4.042-.036-1.475-.792-2.046-2.058-1.286-2.829.759-.771 2.568-.755 4.043.037 1.469.792 2.046 2.057 1.285 2.828z" />
              </svg>
            ) : (
              <svg
                className="w-3 sm:w-4 2xl:w-5 fill-current text-black aspect-square inset-0 motion-safe:animate-scaleIn"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.5 18L6.75 11.25H0L10.5 0L8.25 6.75H15L4.5 18Z" />
              </svg>
            )}
          </div>
        ) : questionView && questionView.answerResult === 3 ? (
          <div className="w-full h-full flex items-center justify-center bg-white absolute">
            <svg
              className="w-3 sm:w-4 2xl:w-5 text-black fill-current aspect-square inset-0 motion-safe:animate-scaleIn"
              viewBox="0 0 15 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M14.5 1.71583L12.7963 0L4.83333 7.96292L1.71583 4.8575L0 6.56125L4.83333 11.3825L14.5 1.71583Z" />
            </svg>
          </div>
        ) : questionView && questionView.answerResult === 2 ? (
          <div className="w-full h-full flex items-center justify-center bg-white absolute">
            <svg
              className="w-3 sm:w-4 2xl:w-5 text-black fill-current aspect-square inset-0 motion-safe:animate-scaleIn"
              viewBox="0 0 20 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 18.35L8.55 17.03C3.4 12.36 0 9.28 0 5.5C0 2.42 2.42 0 5.5 0C7.24 0 8.91 0.81 10 2.09C11.09 0.81 12.76 0 14.5 0C17.58 0 20 2.42 20 5.5C20 9.28 16.6 12.36 11.45 17.04L10 18.35Z" />
            </svg>
          </div>
        ) : questionView &&
          questionView.answerResult <= 0 &&
          questionView.status === 1 &&
          !loadingQuestionView ? (
          <div className="w-full h-full flex items-center justify-center  absolute">
            <svg
              className="w-3 sm:w-4 2xl:w-5 text-white fill-current aspect-square inset-0 motion-safe:animate-scaleIn"
              viewBox="0 0 17 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.05475 0H0L13.593 12.4736C13.962 12.8122 14.4445 13 14.9452 13H17L3.40699 0.526412C3.03804 0.187845 2.5555 0 2.05475 0Z" />
            </svg>
          </div>
        ) : (
          <></>
        )}
      </div>
    </button>
  );
}
