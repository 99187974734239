import moment from "moment";
import React, { useEffect, useState } from "react";
import { getRandomInt, positiveEnforcements } from "../../util/hooks";

export default function SecondsCountdown({
  personalQuiz,
  quiz,
  triviaNight,
  targetCustomer,
  triviaWin,
  curQuestion,
  selectedAnswer,
  isPaused,
}) {
  const [reenforcementInd] = useState(
    getRandomInt(positiveEnforcements.length)
  );

  const [secondsUntilNextQ, setSecondsUntilNextQ] = useState(null);

  useEffect(() => {
    if (
      !(personalQuiz || quiz || triviaNight) ||
      ![1, 2].includes((personalQuiz || quiz || triviaNight).status)
    ) {
      setSecondsUntilNextQ(null);
      return;
    }

    if (personalQuiz || quiz || triviaNight) {
      let targetDate = null;

      if ((personalQuiz || quiz || triviaNight).status === 1) {
        targetDate = moment(
          (personalQuiz || quiz || triviaNight).nextQStartsAt
        );
      } else {
        targetDate = moment((personalQuiz || quiz || triviaNight).curQEndsAt);
      }

      const updateSeconds = setInterval(() => {
        const currentTime = Date.now();
        const msUntilNextQTemp = targetDate - currentTime;
        if (Math.floor(msUntilNextQTemp / 1000) > -1) {
          setSecondsUntilNextQ(Math.floor(msUntilNextQTemp / 1000));
        } else {
          setSecondsUntilNextQ(0);
        }
      }, 1000);

      return () => clearInterval(updateSeconds);
    }
  }, [personalQuiz, quiz, triviaNight]);

  return (
    <p
      className={`font-semibold uppercase tracking-wide whitespace-nowrap text-center truncate`}
    >
      {triviaNight && (!targetCustomer || !targetCustomer.isPaidSubscriber)
        ? "TKnight LIVE"
        : isPaused
        ? (personalQuiz || quiz)?.status === 0
          ? (quiz.gameTimingType === 0 ? "Live" : "Async") + " Battle"
          : "Paused"
        : (personalQuiz || quiz || triviaNight).status >= 5
        ? triviaWin
          ? "Congratulations!"
          : "Nice try!"
        : (personalQuiz || quiz || triviaNight).status === 2
        ? secondsUntilNextQ === 0
          ? "Time's up!"
          : secondsUntilNextQ && secondsUntilNextQ <= 5
          ? secondsUntilNextQ
          : `Question ${(personalQuiz || quiz || triviaNight).curQIndex + 1}`
        : (personalQuiz || quiz || triviaNight).status === 3 && curQuestion
        ? `${
            selectedAnswer && selectedAnswer.isCorrect
              ? positiveEnforcements[reenforcementInd]
              : "Time's up!"
          }`
        : (personalQuiz || quiz || triviaNight).status === 1
        ? secondsUntilNextQ
          ? `${
              (personalQuiz || quiz || triviaNight).lastCompletedQIndex !==
                null &&
              (personalQuiz || quiz || triviaNight).lastCompletedQIndex >= 0
                ? `Question ${
                    (personalQuiz || quiz || triviaNight).lastCompletedQIndex +
                    2
                  }`
                : "Starting"
            } in ${secondsUntilNextQ}`
          : secondsUntilNextQ === 0
          ? "Here we go..."
          : "Get ready!"
        : triviaNight
        ? "TKnight LIVE"
        : (quiz.gameTimingType === 0 ? "Live" : "Async") + " Battle"}
    </p>
  );
}
