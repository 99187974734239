import { useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { AdminAuthContext } from "../context/adminAuth";
import { BrandContactAuthContext } from "../context/brandContactAuth";
import { CustomerAuthContext } from "../context/customerAuth";
import { GuestAuthContext } from "../context/guestAuth";

const usePageTracking = () => {
  const location = useLocation();
  const { customer } = useContext(CustomerAuthContext);

  const { brandContact } = useContext(BrandContactAuthContext);
  const { admin } = useContext(AdminAuthContext);
  const { guest } = useContext(GuestAuthContext);

  useEffect(() => {
    if (
      (!admin &&
        ((customer && !["1146747"].includes(customer.id)) ||
          (guest &&
            !["9618013", "0664812", "1359831", "7202409", "1304627"].includes(
              guest.id
            )))) ||
      (!admin && !guest && !brandContact)
    ) {
      ReactGA.initialize("G-G6C35DED4D");

      window.scrollTo(0, 0);

      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [location, customer, admin, guest, brandContact]);
};

export default usePageTracking;
