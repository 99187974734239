import React from "react";

export default function StreakIcon({ topicId, width }) {
  return (
    <svg
      className={`aspect-square ${width || "w-4"}`}
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 18L6.75 11.25H0L10.5 0L8.25 6.75H15L4.5 18Z"
        fill={topicId ? "#c084fc" : "#D0D500"}
      />
    </svg>
  );
}
