import { useQuery } from "@apollo/client";
import React from "react";
import { FETCH_TOPIC } from "../../util/gqlFunctions";

function TopicIcon({
  topicId,
  navigate,
  height,
  width,
  disabled,
  isMystery,
  hideSeek,
  isCurTopic,
  isForDash,
}) {
  const { data: { getTopicById: targetTopic } = {} } = useQuery(FETCH_TOPIC, {
    variables: { topicId: topicId || "" },
    fetchPolicy: "cache-first",
  });

  return (
    <button
      onClick={(e) => {
        if (targetTopic) {
          navigate("/topic/" + targetTopic.shortForm);
        }
      }}
      className={`group relative overflow-hidden ${
        isForDash ? "flex-shrink-0" : ""
      } bg-opacity-70 hover:shadow-lg shadow-sm transition-all bg-white ${
        height === "h-6" ? "rounded" : "rounded-lg"
      } aspect-square ${height ? height : width ? width : "h-8"} ${
        isCurTopic ? "ring-4 ring-blue-300" : ""
      }`}
      disabled={disabled}
    >
      {targetTopic && targetTopic.logoUrl ? (
        <img
          src={targetTopic.logoUrl}
          className={`origin-center mx-auto aspect-square object-cover ${
            height ? height : width ? width : "h-8"
          } ${width ? "bg-gray-200" : height ? "" : ""}`}
          alt={targetTopic.name}
        />
      ) : isMystery ? (
        <div
          className={`origin-center mx-auto aspect-square object-cover ${
            height ? height : width ? width : "h-8"
          } flex items-center justify-center relative bg-gray-200`}
        >
          <svg
            className="h-1/2 fill-current text-gray-400 z-10"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 0C1.79 0 0 1.79 0 4H2C2 2.9 2.9 2 4 2C5.1 2 6 2.9 6 4C6 6 3 5.75 3 9H5C5 6.75 8 6.5 8 4C8 1.79 6.21 0 4 0Z" />
            <rect x="3" y="10" width="2" height="2" />
          </svg>
        </div>
      ) : (
        <div
          className={`origin-center mx-auto aspect-square object-cover ${
            height ? height : width ? width : "h-8"
          } bg-gray-200`}
        ></div>
      )}
      {!hideSeek && (
        <div className="flex flex-col items-center justify-center z-50 absolute top-full left-1/2 transform -translate-x-1/2 -mb-1 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <svg
            className="h-1.5 rotate-180 fill-current text-gray-800"
            viewBox="0 0 20 10"
          >
            <path d="M0 0L20 0L10 10Z" />
          </svg>
          <span className="bg-gray-800 px-2 py-1 leading-tight  rounded-md shadow">
            {targetTopic ? targetTopic.name : "Mystery"}
          </span>
        </div>
      )}
    </button>
  );
}

export default TopicIcon;
