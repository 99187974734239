import React from "react";
import { decryptQCipher, defaultPicUrl } from "../../util/hooks";

function QuestionSummaryBox({
  index,
  question,
  loadingDeleteQ,
  deleteQuestion,
  triviaNight,
  setEditQIndex,
  moveQuestionUp,
  moveQuestionDown,
  loadingMoveQuestion,
}) {
  return (
    <div
      className={`${
        loadingDeleteQ ? " motion-safe:animate-pulse " : ""
      } w-full shadow-sm flex items-center justify-between hover:bg-gray-50 transition-all shrink-0 border border-gray-200 rounded-md`}
    >
      <img
        src={question.topicLogoUrl || defaultPicUrl}
        className="h-10 aspect-square rounded-md object-cover"
        alt={question.topicName}
      />
      <button
        onClick={(e) => {
          e.preventDefault();
          setEditQIndex(index);
        }}
        className="truncate py-2 pl-3 whitespace-nowrap w-full flex-1 text-left"
      >
        <span className="font-semibold text-sm">{question.index + 1}.</span>{" "}
        {decryptQCipher(question.questionDesc)}
      </button>

      <button
        onClick={(e) => {
          e.preventDefault();
          moveQuestionUp();
        }}
        disabled={
          loadingMoveQuestion || !(triviaNight.numOfQs > 0 && index !== 0)
        }
        className="px-2 py-2 disabled:text-gray-300 hover:text-blue-300 transition-all "
      >
        <svg
          className="h-6 shrink-0 fill-current m-auto"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.295L6 14.295L7.41 15.705Z" />
        </svg>
      </button>

      <button
        onClick={(e) => {
          e.preventDefault();
          moveQuestionDown();
        }}
        disabled={
          loadingMoveQuestion ||
          !(triviaNight.numOfQs > 0 && index !== triviaNight.numOfQs - 1)
        }
        className="px-2 py-2 disabled:text-gray-300 hover:text-blue-300 transition-all "
      >
        <svg
          className="h-6 shrink-0 fill-current m-auto"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.41 8.295L12 12.875L16.59 8.295L18 9.705L12 15.705L6 9.705L7.41 8.295Z" />
        </svg>
      </button>

      <button
        onClick={(e) => {
          e.preventDefault();
          deleteQuestion();
        }}
        disabled={loadingDeleteQ}
        className="px-2 py-2 disabled:text-gray-300 hover:text-red-600 transition-all "
      >
        <svg
          className="h-5 fill-current m-auto"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM16 9V19H8V9H16ZM6 7H18V19C18 20.1 17.1 21 16 21H8C6.9 21 6 20.1 6 19V7Z"
          />
        </svg>
      </button>
    </div>
  );
}

export default QuestionSummaryBox;
