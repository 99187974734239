import React, { useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";

import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
// import  BlueFullGradBack from "../../components/creator/BlueFullGradBack";
import BlueFlatGradBack from "../../components/creator/BlueFlatGradBack";
import CodeForm from "../../components/creator/CodeForm";
import LoginFooter from "../../components/creator/LoginFooter";
import SubmitButton from "../../components/creator/SubmitButton";
import TextInputField from "../../components/creator/TextInputField";
import TopBar from "../../components/creator/TopBar";
import { CustomerAuthContext } from "../../context/customerAuth";
import { customerClient, guestClient } from "../../GraphqlApolloClients";
import {
  FETCH_CUSTOMER,
  REGISTER_CUSTOMER,
  RESEND_VERIFICATION_CODE,
} from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";

function RegisterCustomer(props) {
  usePageTracking();

  const { customer } = useContext(CustomerAuthContext);

  let navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const { joinedFor, prevPath } = useParams();

  useEffect(() => {
    document.title = "Sign Up | TKnight";
  }, []);

  const { onChange, values, setValues } = useForm(signupCustomerFunc, {
    email: "",
    password: "",
    referralCode: "",
    joinedFor: joinedFor ? parseInt(joinedFor) : 1,

    country: "",
    verificationCode: "",
    usingPhone: true,
    phone: "",
    phoneCountryCode: "+1",
    phoneCountryShortForm: "CA",
  });

  const [shouldOpenVerificationPopup, setShouldOpenVerificationPopup] =
    useState(false);

  //creating function to load ip address from the API

  const [registerCustomer, { loading }] = useMutation(REGISTER_CUSTOMER, {
    update(_, { data: { registerCustomer: customerData } }) {
      setErrors({});
      setShouldOpenVerificationPopup(true);
    },
    onError(err) {
      console.log(err);
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    // variables: values,
    client: guestClient,
  });

  const [resendVerificationCode, { loading: loadingResendVerificationCode }] =
    useMutation(RESEND_VERIFICATION_CODE, {
      refetchQueries: [{ query: FETCH_CUSTOMER }],
      update(proxy, { data }) {
        setErrors({});
      },
      onError(err) {
        setValues({ ...values, verificationCode: "" });

        console.log(err);
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: customerClient,
    });

  function signupCustomerFunc(e) {
    e.preventDefault();
    registerCustomer({
      variables: {
        ...values,
      },
    });
  }

  useEffect(() => {
    if (customer && !shouldOpenVerificationPopup) {
      navigate("/");
    }
  }, [customer, navigate, prevPath, shouldOpenVerificationPopup]);

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-gray-50">
      <BlueFlatGradBack gradIndex={0} />
      <div
        className={`short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl grow-0 relative h-full z-10 flex flex-col items-center justify-start mx-auto xl:px-0 px-4 pb-4 lg:pb-0 min-h-screen`}
      >
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          page="Signup"
          prevPath={prevPath}
        />
        <CodeForm
          title={"Verification code"}
          name={"verificationCode"}
          isCodeBoxOpen={shouldOpenVerificationPopup}
          setIsCodeBoxOpen={setShouldOpenVerificationPopup}
          errors={errors}
          values={values}
          setValues={setValues}
          onChange={onChange}
          typeOfCode={1}
          setErrors={setErrors}
          navigate={navigate}
          caption={
            loadingResendVerificationCode
              ? "Sending..."
              : values.usingPhone
              ? "Sent to your phone"
              : "Sent to your email"
          }
          linkCaption={"Resend"}
          linkFunction={() => {
            resendVerificationCode();
          }}
          prevPath={prevPath}
        />
        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-6 sm:mb-10 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
            Create your TKnight account
          </p>
          {/* {targetGuestCustomer && targetGuestCustomer.balance ? (
            <p className="text-gray-500 w-full short:text-sm short:leading-tight leading-tight">
              All your stats from guest mode, including your{" "}
              <span className=" font-semibold">
                {targetGuestCustomer.balance} tokens
              </span>
              , will be transferred to your official account.
              {/* Enjoy 100 extra
              tokens as well! 
            </p>
          ) : (
            <></>
          )} */}

          {/* <p className="text-gray-500 w-full short:text-sm short:leading-tight leading-tight">
            Already have one? Sign in
          </p> */}

          <div className="w-full flex flex-col items-start justify-start space-y-6 my-4 short:mb-10 xshort:mb-6 sm:my-6">
            {values.usingPhone ? (
              <TextInputField
                title="Phone"
                name="phone"
                type="tel"
                placeholder=""
                error={errors.phone}
                disabled={loading}
                values={values}
                onChange={onChange}
                setValues={setValues}
                value={values.phone}
                phoneCodePrefix={
                  values.phoneCountryShortForm + " " + values.phoneCountryCode
                }
                phoneCountryCode={values.phoneCountryCode}
                actionBtnTitle={"Sign up with email"}
                onActionBtnPress={(e) => {
                  e.preventDefault();
                  setValues({ ...values, usingPhone: !values.usingPhone });
                }}
              />
            ) : (
              <TextInputField
                title="Email"
                name="email"
                type="email"
                error={errors.email || errors.name}
                disabled={loading}
                onChange={onChange}
                value={values.email}
                actionBtnTitle={"Sign up with phone"}
                onActionBtnPress={(e) => {
                  e.preventDefault();
                  setValues({ ...values, usingPhone: !values.usingPhone });
                }}
              />
            )}
            <TextInputField
              title="Password"
              name="password"
              type="password"
              error={errors.password}
              disabled={loading}
              onChange={onChange}
              value={values.password}
            />

            <TextInputField
              title="Extra ❤️ Code (optional)"
              name="referralCode"
              error={errors.referralCode}
              disabled={loading}
              onChange={onChange}
              value={values.referralCode}
            />
          </div>

          <SubmitButton
            onClickFunc={async (e) => {
              e.preventDefault();
              registerCustomer({
                variables: {
                  ...values,
                },
              });
            }}
            disabled={loading}
            // disabled={loading || !values.verificationCode}
            isNotLoading={!loading}
            title={"Create account"}
            errors={errors}
          />

          <p className="text-center text-gray-600 mt-4 sm:mb-0 mb-2 text-sm sm:text-base">
            Have an account?{" "}
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/login" + (prevPath ? "/redirect/" + prevPath : ""));
              }}
              className="text-blue-600 hover:text-black transition-all"
            >
              Sign in
            </button>
          </p>
        </div>

        <LoginFooter navigate={navigate} />
      </div>
    </div>
  );
}

export default RegisterCustomer;
