import { useMutation } from "@apollo/client";

import React, { useState } from "react";
import { adminClient } from "../../GraphqlApolloClients";

import {
  EDIT_QUESTION_MUTATION,
  FETCH_QUESTIONS,
} from "../../util/gqlFunctions";
import { decryptQCipher, useForm } from "../../util/hooks";
import SubmitButton from "./SubmitButton";
import TextInputField from "./TextInputField";
import TopicIcon from "./TopicIcon";

function EditQuestionForm({ targetQuestion, setTargetQuestion, navigate }) {
  const [errors, setErrors] = useState({});

  const { onChange, values, setValues } = useForm(editQuestionCallback, {
    ...targetQuestion,
    topicNames: [targetQuestion.topicName],
    questionDesc: decryptQCipher(targetQuestion.questionDesc),
    questionId: targetQuestion.id,
    fieldsToEdit: [],
  });

  const [editQuestion, { loading: loadingEditQuestion }] = useMutation(
    EDIT_QUESTION_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_QUESTIONS,
          variables: {
            seenIds: [],
            pageSize: 10,
          },
        },
      ],
      update(_, { data: { editQuestion: editedQuestion } }) {
        setValues({
          ...values,
          ...editedQuestion,
          questionDesc: decryptQCipher(editedQuestion.questionDesc),

          fieldsToEdit: [],
        });

        // setTargetQuestion(null);
        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: adminClient,
    }
  );

  function setFieldsToEdit(name) {
    if (
      values.fieldsToEdit &&
      values.fieldsToEdit.includes(name) &&
      targetQuestion
    ) {
      if (
        name.includes("Amount") ||
        name.includes("Price") ||
        name === "discount"
      ) {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetQuestion[name]
            ? Math.floor(targetQuestion[name] / 100)
            : 10,
        });
      } else if (name === "questionDesc") {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: decryptQCipher(targetQuestion[name]) || "",
        });
      } else {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetQuestion[name],
        });
      }
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }

  function editQuestionCallback(e) {
    e.preventDefault();
    editQuestion();
  }

  // Initialize the Autocomplete objects
  var cityAutocomplete = new window.google.maps.places.Autocomplete(
    document.getElementById("city")
  );

  // Set the types to retrieve only cities and provinces/states
  cityAutocomplete.setTypes(["(cities)"]);

  // Listen for the "place_changed" event of the Autocomplete objects
  cityAutocomplete.addListener("place_changed", function () {
    var place = cityAutocomplete.getPlace();
    if (place && place.address_components) {
      // Update the city field value

      var province = getProvinceFromAddressComponents(place.address_components);
      var country = getCountryFromAddressComponents(place.address_components);

      setValues({
        ...values,
        fieldsToEdit: ["province", "city", "country"],
        province: province || values.province,
        city: place.address_components[0].long_name,
        country: country || values.country,
      });
    }
  });

  function getProvinceFromAddressComponents(components) {
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf("administrative_area_level_1") !== -1) {
        return component.long_name;
      }
    }
    return null;
  }
  function getCountryFromAddressComponents(components) {
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf("country") !== -1) {
        return component.long_name;
      }
    }
    return null;
  }

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setTargetQuestion(null);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl short:h-full short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setTargetQuestion(null);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          Edit question
        </p>
        <p className="text-gray-500 w-full ">These edits reflect live</p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-4">
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor={"topicId"}
                className="font-semibold text-gray-700 "
              >
                Topic
                {errors && errors.topicId && (
                  <span className="text-red-500">{errors.topicId}</span>
                )}
              </label>
            </div>

            <div>
              <TopicIcon topicId={targetQuestion.topicId} navigate={navigate} />
            </div>
          </div>

          <TextInputField
            title="Question"
            name="questionDesc"
            value={values.questionDesc}
            onChange={onChange}
            type="multiline"
            error={errors.questionDesc}
            disabled={loadingEditQuestion}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
          />

          <TextInputField
            title="Option 0"
            name="option0"
            value={values.option0}
            onChange={onChange}
            error={errors.option0}
            disabled={loadingEditQuestion}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
            isCorrectAnswer={
              values.questionType === 0 && values.correctAnswer === 0
                ? true
                : null
            }
          />

          <TextInputField
            title="Option 1"
            name="option1"
            value={values.option1}
            onChange={onChange}
            error={errors.option1}
            disabled={loadingEditQuestion}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
            isCorrectAnswer={
              values.questionType === 0 && values.correctAnswer === 1
                ? true
                : null
            }
          />

          <TextInputField
            title="Option 2"
            name="option2"
            value={values.option2}
            onChange={onChange}
            error={errors.option2}
            disabled={loadingEditQuestion}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
            isCorrectAnswer={
              values.questionType === 0 && values.correctAnswer === 2
                ? true
                : null
            }
          />

          <TextInputField
            title="Option 3"
            name="option3"
            value={values.option3}
            onChange={onChange}
            error={errors.option3}
            disabled={loadingEditQuestion}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
            isCorrectAnswer={
              values.questionType === 0 && values.correctAnswer === 3
                ? true
                : null
            }
          />

          <TextInputField
            title="Interesting fact (optional)"
            name="interestingFact"
            type="multiline"
            value={values.interestingFact}
            onChange={onChange}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
            error={errors.interestingFact}
            disabled={loadingEditQuestion}
            maxLen={200}
            rows={"3"}
          />

          {targetQuestion.status <= 0 && (
            <SubmitButton
              onClickFunc={(e) => {
                e.preventDefault();
                editQuestion({
                  variables: {
                    questionId: targetQuestion.id,
                    fieldsToEdit: ["status"],
                    status: 1,
                  },
                });
              }}
              disabled={loadingEditQuestion}
              title={"Approve"}
              errors={errors}
            />
          )}
        </div>
      </form>
    </div>
  );
}

export default EditQuestionForm;
