import React, { useContext, useEffect, useState } from "react";

import usePageTracking from "../../util/usePageTracking";

import profilePic from "../../images/profile.png";

import { useNavigate, useParams } from "react-router";

import Confetti from "react-confetti";

import { useElements, useStripe } from "@stripe/react-stripe-js";

import { useMutation, useQuery } from "@apollo/client";
import { customerClient, guestClient } from "../../GraphqlApolloClients";
import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import BuySubscriptionForm from "../../components/creator/BuySubscriptionForm";
import CircularProgressBar from "../../components/creator/CircularProgressBar";
import CodeForm from "../../components/creator/CodeForm";
import CreateBattleModal from "../../components/creator/CreateBattleModal";
import CustomerFooter from "../../components/creator/CustomerFooter";
import EmptyCircularProgressBar from "../../components/creator/EmptyCircularProgressBar";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import MakeContentForm from "../../components/creator/MakeContentForm";
import MakeQuestion from "../../components/creator/MakeQuestion";
import MiniNavBar from "../../components/creator/MiniNavBar";
import MobileFooter from "../../components/creator/MobileFooter";
import PortfolioGradBack from "../../components/creator/PortfolioGradBack";
import TNReviewForm from "../../components/creator/TNReviewForm";
import TopBar from "../../components/creator/TopBar";

import TopicSummaryBox from "../../components/creator/TopicSummaryBox";
import { CustomerAuthContext } from "../../context/customerAuth";
import { GuestAuthContext } from "../../context/guestAuth";
import {
  FETCH_ARE_FRIEND_REQUESTS_AVAILABLE,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_PLAYER_TOPIC_STATS,
  FETCH_RELATED_TOPICS,
  FETCH_TOPIC_BY_SHORT_FORM,
  LOG_TRIVIA_SHARE_MUTATION,
} from "../../util/gqlFunctions";
import { useWindowSize } from "../../util/hooks";

function Topic(props) {
  usePageTracking();
  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  const { loginGuest: loginGuestContext } = useContext(GuestAuthContext);
  let navigate = useNavigate();

  const { shortForm } = useParams();

  // const pageTopRef = useRef();
  const stripe = useStripe();
  const elements = useElements();

  // const [ugcFormToOpen, setUGCFormToOpen] = useState(null);
  // const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
  //   useState(false);
  // const [
  //   shouldOpenRevisionPaymentSuccessfulForm,
  //   setShouldOpenRevisionPaymentSuccessfulForm,
  // ] = useState(false);

  // const [shouldOpenRevisionPaymentForm, setShouldOpenRevisionPaymentForm] =
  //   useState(false);

  // const [shouldOpenReviewSuccessfulForm, setShouldOpenReviewSuccessfulForm] =
  //   useState(false);

  // const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  // const [shouldOpenEditUGCPlanForm, setShouldOpenEditUGCPlanForm] =
  //   useState(false);

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
    // refetch: refetchCustomer,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });
  const resetInterval = 2000;
  const [triedToMakeAQuestion, setTriedToMakeAQuestion] = useState(false);
  useEffect(() => {
    let timeout;
    if (triedToMakeAQuestion && resetInterval) {
      timeout = setTimeout(() => setTriedToMakeAQuestion(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [triedToMakeAQuestion, resetInterval]);
  const [values, setValues] = useState({});

  const {
    data: { getGuestCustomer: targetGuestCustomer } = {},
    // loading: loadingGuestCustomer,
  } = useQuery(FETCH_GUEST_CUSTOMER, { client: guestClient });

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  const {
    data: { areFriendRequestsAvailable } = {},
    // loading: loadingAreFriendRequestsAvailable,
  } = useQuery(FETCH_ARE_FRIEND_REQUESTS_AVAILABLE, {
    client: customer ? customerClient : guestClient,
    // fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (
      (customer &&
        !loadingCustomerInfo &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);
  const { data: { getTopicByShortForm: mainTopic } = {} } = useQuery(
    FETCH_TOPIC_BY_SHORT_FORM,
    {
      variables: { shortForm },
    }
  );

  useEffect(() => {
    if (mainTopic) {
      document.title = mainTopic.name + " | TKnight";
    }
  }, [mainTopic]);

  const { data: { getRelatedTopics: relatedTopics } = {} } = useQuery(
    FETCH_RELATED_TOPICS,
    {
      variables: { topicId: mainTopic ? mainTopic.id : "", seenIds: [] },
    }
  );

  const [shouldOpenCreateQ, setShouldOpenCreateQ] = useState(false);

  const [subscriptionType, setSubscriptionType] = useState(null);

  const { width, height } = useWindowSize();

  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);
  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);
  const [shouldOpenMakeContentForm, setShouldOpenMakeContentForm] =
    useState(false);
  const [
    shouldOpenQuestionAddedSuccessfullyForm,
    setShouldOpenQuestionAddedSuccessfullyForm,
  ] = useState(false);
  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);
  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);
  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);
  const [
    shouldOpenContentAddedSuccessfullyForm,
    setShouldOpenContentAddedSuccessfullyForm,
  ] = useState(false);
  const toggleBuySubscriptionForm = (subscriptionType) => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setTargetTopic(null);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setSubscriptionType(subscriptionType);
  };
  const toggleReviewForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setTargetTopic(null);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setShouldShowMiniNavBar(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  };

  const toggleJoinedProPlanPopup = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setTargetTopic(null);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setShouldShowMiniNavBar(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };

  const toggleProInviteCodeForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setTargetTopic(null);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setShouldShowMiniNavBar(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };

  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setTargetTopic(null);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setShouldShowMiniNavBar(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  const toggleSubPaymentSuccessfulForm = () => {
    setSubscriptionType(null);
    // setShouldOpenCashoutForm(false);
    // setShouldOpenCashoutSuccessfulForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setTargetTopic(null);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };

  const toggleBuyCoinsForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setTargetTopic(null);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenBuyCoinsForm(false);
    setTargetTopic(null);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };

  const toggleMakeQuestionForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenBuyCoinsForm(false);
    setTargetTopic(null);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenMakeContentForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    if (!shouldOpenCreateQ && !customer) {
      setTriedToMakeAQuestion(true);
    } else {
      setShouldOpenCreateQ(!shouldOpenCreateQ);
    }
  };

  // const toggleMakeContentForm = () => {
  //   setShouldOpenSubPaymentSuccessfulForm(false);
  //   setSubscriptionType(null);
  //   setShouldOpenBuyCoinsForm(false);
  //   setTargetTopic(null);
  //   setShouldOpenCoinPurchaseSuccessfulForm(false);
  //   setShouldOpenCreateQ(false);
  //   setShouldOpenMakeContentForm(false);
  //   setShouldShowMiniNavBar(false);
  //   setShouldOpenReviewForm(false);
  //   setShouldOpenReviewSentForm(false);
  //   setShouldOpenJoinedProPlanPopup(false);
  //   setShouldOpenProInviteCodeForm(false);

  //   setShouldOpenQuestionAddedSuccessfullyForm(false);

  //   setShouldOpenMakeContentForm(!shouldOpenMakeContentForm);
  // };

  const toggleQuestionAddedSuccessfullyForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenBuyCoinsForm(false);
    setTargetTopic(null);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setShouldOpenQuestionAddedSuccessfullyForm(
      !shouldOpenQuestionAddedSuccessfullyForm
    );
  };

  const toggleContentAddedSuccessfullyForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenBuyCoinsForm(false);
    setTargetTopic(null);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setTargetTopic(null);

    setShouldOpenContentAddedSuccessfullyForm(
      !shouldOpenContentAddedSuccessfullyForm
    );
  };

  const toggleOneTopicBattleForm = (tempTargetTopic) => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenCreateQ(false);
    setShouldOpenMakeContentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenQuestionAddedSuccessfullyForm(false);
    setShouldOpenContentAddedSuccessfullyForm(false);

    setTargetTopic(targetTopic ? null : tempTargetTopic);
  };

  const [targetTopic, setTargetTopic] = useState(null);
  const [isCaptionCopied, setIsCaptionCopied] = useState(false);
  const [isSharingToApps, setIsSharingToApps] = useState(false);

  const [subPopupReason, setSubPopupReason] = useState(null);
  const [errors, setErrors] = useState({});

  const [logTriviaShare] = useMutation(LOG_TRIVIA_SHARE_MUTATION, {
    // refetchQueries: [
    //   {
    //     query: FETCH_TRIVIA_SHARES_BY_CUSTOMER,
    //     variables: { quizId: quiz.id, type: 3 },
    //   },
    // ],
    update(_, { data: { logTriviaShare: triviaShare } }) {
      setErrors({});
    },
    onError(err) {
      if (err.graphQLErrors[0]) {
        console.log(errors);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    client: targetCustomer ? customerClient : guestClient,
  });

  const logTriviaShareEvent = async (type) => {
    logTriviaShare({
      variables: {
        topicId: targetTopic ? targetTopic.id : "",

        type,
      },
    });
  };

  const shareTNTopic = async () => {
    if (!mainTopic) {
      return;
    }

    var textToShare = "TKnight | " + mainTopic.name + "\n";
    // textToShare +=
    //   (targetCustomer && targetCustomer.gender === 0 ? "🧔🏽‍♂️ " : "👱🏽‍♀️ ") +
    //   mainTopic.numOfPlayers +
    //   "  🪙" +
    //   mainTopic.minimumBet +
    //   "\n\n";

    // textToShare += "I love this topic.\n";
    textToShare += "https://tknight.live/topic/" + mainTopic.shortForm;

    if (navigator.share) {
      navigator
        .share({
          title: mainTopic.name + " on TKnight!",
          text: textToShare,
          url: "http://tknight.live/topic/" + mainTopic.shortForm,
          // url:
          //   playersPhraze && playersPhraze.isEligibleForPrize
          //     ? "W/" + playersPhraze.id
          //     : "phraze",
        })
        .then(() => {
          // setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);
          // navigator.clipboard.writeText(textToShare + " worldphraze.com");
          setIsCaptionCopied(true);
          // setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      // setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }

    // if (quiz.status >= 5) {
    await logTriviaShareEvent(5);
    // } else if (quiz.status === 0) {
    //   await logTriviaShareEvent(0);
    // } else {
    //   await logTriviaShareEvent(1);
    // }
  };
  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => {
        setIsCaptionCopied(false);
        setIsSharingToApps(false);
      }, resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);
  const { data: { getPlayerTopicStatsByTopic: playerTopicStats } = {} } =
    useQuery(FETCH_PLAYER_TOPIC_STATS, {
      client: customer ? customerClient : guestClient,
      variables: {
        topicId: mainTopic ? mainTopic.id : "",
      },
    });

  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);
  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      setIsSharingInviteToApps(false);
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);
  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);
  return (
    <div
      // ref={pageTopRef}
      className={`w-screen bg-gray-50 overflow-x-hidden h-full min-h-screen`}
    >
      <PortfolioGradBack gradIndex={mainTopic ? mainTopic.accentColor : 1} />

      {mainTopic && targetTopic ? (
        <CreateBattleModal
          topic={targetTopic}
          navigate={navigate}
          toggleOneTopicBattleForm={toggleOneTopicBattleForm}
          targetCustomer={targetCustomer}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          targetGuest={targetGuestCustomer}
          loginGuestContext={loginGuestContext}
          subPopupReason={subPopupReason}
          setSubPopupReason={setSubPopupReason}
        />
      ) : (
        <></>
      )}
      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          questionId={null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
        />
      ) : (
        <></>
      )}
      {shouldOpenReviewForm && (targetCustomer || targetGuestCustomer) ? (
        <TNReviewForm
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          targetCustomer={targetCustomer}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenCoinPurchaseSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetCustomer={targetCustomer}
            message={
              "Tokens added to stash. Good luck, " + targetCustomer.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"Payment Successful!"}
          />
        </>
      )}

      {shouldOpenQuestionAddedSuccessfullyForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleQuestionAddedSuccessfullyForm}
            targetCustomer={targetCustomer}
            message={"Your question has been sent for review."}
            buttonMsg={"Awesome!"}
            popUpTitle={"Thank you!"}
          />
        </>
      )}

      {shouldOpenContentAddedSuccessfullyForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleContentAddedSuccessfullyForm}
            targetCustomer={targetCustomer}
            message={"Your content has been sent for review."}
            buttonMsg={"Awesome!"}
            popUpTitle={"Thank you!"}
          />
        </>
      )}

      {subscriptionType !== null && targetCustomer && stripe && elements ? (
        <BuySubscriptionForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          subscriptionType={subscriptionType}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
          subPopupReason={subPopupReason}
        />
      ) : (
        <></>
      )}
      {shouldOpenProInviteCodeForm && targetCustomer && (
        <CodeForm
          title="TKnight Pro Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}

      {shouldOpenJoinedProPlanPopup && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight Pro, " + targetCustomer.name + "!"}
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetCustomer.subscriptionType === 0
                ? "Individual"
                : targetCustomer.subscriptionType === 1
                ? "Duo"
                : targetCustomer.subscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenSubPaymentSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight Pro, " + targetCustomer.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenReviewSentForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetCustomer={targetCustomer}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}

      {shouldOpenMakeContentForm && mainTopic && (
        <MakeContentForm
          setShouldOpenMakeContentForm={setShouldOpenMakeContentForm}
          targetTopic={mainTopic}
          navigate={navigate}
          toggleContentAddedSuccessfullyForm={
            toggleContentAddedSuccessfullyForm
          }
        />
      )}

      {shouldOpenCreateQ && mainTopic && (
        <MakeQuestion
          setShouldOpenCreateQ={setShouldOpenCreateQ}
          targetTopic={mainTopic}
          toggleQuestionAddedSuccessfullyForm={
            toggleQuestionAddedSuccessfullyForm
          }
        />
      )}

      {shouldShowMiniNavBar && targetCustomer && (
        <MiniNavBar
          targetCustomer={targetCustomer}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          topic={mainTopic}
          isInviteCopied={isInviteCopied}
          inviteFriends={shareTNTopic}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
        />
      )}
      <div className="relative min-h-screen z-10 max-w-5xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto grow-0 h-full flex flex-col items-center justify-start  xl:px-0 px-4 py-4 pb-0 sm:pb-0 sm:py-6">
        <TopBar
          navigate={navigate}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          targetGuestCustomer={targetGuestCustomer}
          targetCustomer={targetCustomer}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          setIsFirstTime={setIsFirstTime}
          customer={customer}
          areFriendRequestsAvailable={areFriendRequestsAvailable}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          page="Topic"
          topicId={mainTopic?.id}
          topicShortForm={shortForm}
        />

        <div
          className={`flex text-center xshort:mt-10 short:mt-10 mt-20 sm:mt-44 w-full max-w-lg 2xl:max-w-2xl flex-col items-center justify-center space-y-6`}
        >
          <p
            className={`${
              mainTopic ? "opacity-90" : "opacity-0"
            } text-white text-2xl sm:text-3xl font-bold w-full short:text-xl transition-all`}
          >
            {mainTopic ? mainTopic.name : "Hi, there!"}
          </p>

          <div
            className={`bg-opacity-70 shadow-lg bg-white rounded-xl p-2 short:p-1.5 aspect-square w-1/2 sm:w-40`}
          >
            <img
              src={mainTopic ? mainTopic.logoUrl : profilePic}
              className="rounded-lg w-full h-full aspect-square object-cover"
              alt={mainTopic ? mainTopic.name : "Topic"}
            />
          </div>

          <div
            className={`flex items-center space-x-3 w-full px-4 short:px-0 lg:px-10 justify-start`}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleOneTopicBattleForm(mainTopic);
              }}
              className="font-semibold hide-button-flash hover:bg-gray-600 transition-all shadow-xl rounded-full text-white bg-black w-full p-2 short:p-1.5"
            >
              Battle
            </button>
            {playerTopicStats ? (
              <CircularProgressBar
                percentage={Math.floor(
                  playerTopicStats
                    ? (Math.ceil(playerTopicStats.levelNum) -
                        playerTopicStats.levelNum) *
                        100
                    : 0
                )}
                text={
                  playerTopicStats ? Math.floor(playerTopicStats.levelNum) : 0
                }
                radius={width < 640 ? 18 : 20}
                shouldShowCaption={true}
              />
            ) : (
              <EmptyCircularProgressBar
                radius={width < 640 ? 18 : 20}
                shouldShowCaption={true}
              />

              // <CircularProgressBar
              //   percentage={Math.floor(
              //     playerTopicStats
              //       ? (playerTopicStats.levelNum /
              //           (Math.ceil(playerTopicStats.levelNum) || 1)) *
              //           100
              //       : 0
              //   )}
              //   text={
              //     playerTopicStats ? Math.floor(playerTopicStats.levelNum) : 0
              //   }
              //   radius={width < 640 ? 18 : 20}
              //   shouldShowCaption={true}
              // />
            )}
            <button
              onClick={(e) => {
                if (mainTopic) {
                  navigate("/focus/" + mainTopic.shortForm);
                }
              }}
              className={`font-semibold shadow-xl rounded-full text-white bg-blue-600 hover:bg-opacity-70 transition-all w-full p-2 short:p-1.5`}
            >
              Focus
            </button>
          </div>

          <div className="flex items-center space-x-6 justify-start">
            <div className="relative group">
              <button
                onClick={(e) => {
                  if (mainTopic) {
                    navigate("/leaderboard/" + mainTopic.shortForm);
                  } else {
                    navigate("/leaderboard");
                  }
                }}
                className="h-10 shadow-lg flex-grow-0 aspect-square rounded-full shrink-0 hover:opacity-30 bg-white transition-all"
              >
                <svg
                  className="w-full my-auto h-full p-2 shrink-0 fill-current text-black"
                  viewBox="0 0 26 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 10C15.7614 10 18 7.76142 18 5C18 2.23858 15.7614 0 13 0C10.2386 0 8 2.23858 8 5C8 7.76142 10.2386 10 13 10Z"
                    fill="black"
                  />
                  <path
                    d="M3.5 9C5.433 9 7 7.433 7 5.5C7 3.567 5.433 2 3.5 2C1.567 2 0 3.567 0 5.5C0 7.433 1.567 9 3.5 9Z"
                    fill="black"
                  />
                  <path
                    d="M22.5 9C24.433 9 26 7.433 26 5.5C26 3.567 24.433 2 22.5 2C20.567 2 19 3.567 19 5.5C19 7.433 20.567 9 22.5 9Z"
                    fill="black"
                  />
                </svg>

                {/* <p className="text-white font-semibold">@tknight.live</p> */}
              </button>
              <div
                className={`sm:flex hidden font-semibold flex-col absolute items-center justify-center  top-full -mt-1 left-1/2 transform -translate-x-1/2 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
              >
                <svg
                  className={`h-1.5 rotate-180 fill-current text-gray-800 `}
                  viewBox="0 0 20 10"
                >
                  <path d="M0 0L20 0L10 10Z" />
                </svg>
                <span className={`bg-gray-800 px-2 py-1  rounded-md shadow`}>
                  Leaderboard
                </span>
              </div>
            </div>
            {/* <a className="h-10 shadow-lg flex-grow-0 aspect-square rounded-full shrink-0 hover:opacity-30 bg-white transition-all">
              <svg
                className="w-full my-auto h-full p-2 shrink-0 fill-current text-black"
                viewBox="0 0 10 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 8.43V0H0V8.43C0 8.78 0.18 9.11 0.49 9.29L4.67 11.8L3.68 14.14L0.27 14.43L2.86 16.67L2.07 20L5 18.23L7.93 20L7.15 16.67L9.74 14.43L6.33 14.14L5.34 11.8L9.52 9.29C9.82 9.11 10 8.79 10 8.43ZM6 10.23L5 10.83L4 10.23V1H6V10.23Z"
                  fill="black"
                />
              </svg>

              {/* <p className="text-white font-semibold">@tknight.live</p> 
              
            </a> */}
            <div className="relative group">
              <button
                onClick={toggleMakeQuestionForm}
                className="h-10 relative hide-button-flash shadow-lg flex-grow-0 aspect-square rounded-full shrink-0 hover:opacity-30 bg-white transition-all"
              >
                <svg
                  className={`w-full my-auto h-full  p-2.5 shrink-0 fill-current text-blue-600`}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" />
                </svg>

                {/* <p className="text-white font-semibold">@tknight.live</p> */}
              </button>
              <div
                className={`${
                  triedToMakeAQuestion
                    ? "sm:opacity-0 opacity-100"
                    : "opacity-0"
                } flex font-semibold flex-col absolute items-center justify-center group-hover:opacity-100 top-full -mt-1 left-1/2 transform -translate-x-1/2 whitespace-nowrap text-center text-xs text-white transition-opacity duration-300`}
              >
                <svg
                  className={`${
                    triedToMakeAQuestion ? "text-red-600" : "text-gray-800"
                  }  h-1.5 rotate-180 fill-current `}
                  viewBox="0 0 20 10"
                >
                  <path d="M0 0L20 0L10 10Z" />
                </svg>
                <span
                  className={`${
                    triedToMakeAQuestion
                      ? "motion-safe:animate-shake bg-red-600"
                      : ""
                  } bg-gray-800 px-2 py-1  rounded-md shadow`}
                >
                  {triedToMakeAQuestion ? "Sign in to post" : "Add a question"}
                </span>
              </div>
            </div>
            {/* <a className="h-10 shadow-lg flex-grow-0 aspect-square rounded-full shrink-0 hover:opacity-30 bg-white transition-all">
              <svg
                className="w-full my-auto h-full p-2 shrink-0 fill-current text-black"
                viewBox="0 0 214 173"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M57.1701 2.23996C56.3301 2.25996 55.4801 2.48998 54.7101 2.93998L4.83006 31.74C2.34006 33.18 1.49007 36.33 2.93007 38.83L19.9201 68.26C21.3601 70.75 24.5301 71.6 27.0201 70.16L53.0801 55.11V165.09C53.0801 167.97 55.4101 170.29 58.2801 170.29H154.57C157.45 170.29 159.76 167.97 159.76 165.09V54.96L186.1 70.16C188.59 71.6 191.75 70.75 193.19 68.26L210.18 38.83C211.62 36.33 210.77 33.18 208.28 31.74L158.41 2.93998C157.4 2.35998 156.27 2.14997 155.2 2.27997C154.99 2.24997 154.79 2.23996 154.57 2.23996H126.99C126.58 5.50996 117.58 8.12998 106.55 8.12998C95.5301 8.12998 86.5301 5.50996 86.1201 2.23996H58.2801C58.1201 2.23996 57.9501 2.24996 57.7901 2.26996C57.5801 2.24996 57.3801 2.22996 57.1701 2.23996Z"
                  fill="black"
                />
              </svg>

              {/* <p className="text-white font-semibold">@tknight.live</p>
            </a> */}
            <div className="relative group">
              <button
                onClick={shareTNTopic}
                className="h-10 shadow-lg flex-grow-0 aspect-square rounded-full shrink-0 hover:opacity-30 bg-white transition-all"
              >
                <svg
                  className="w-full my-auto h-full p-2 shrink-0 fill-current text-black"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_8661_576)">
                    <path
                      d="M15 14.1365C14.24 14.1365 13.56 14.4378 13.04 14.9096L5.91 10.743C5.96 10.512 6 10.2811 6 10.0402C6 9.7992 5.96 9.56827 5.91 9.33735L12.96 5.21084C13.5 5.71285 14.21 6.0241 15 6.0241C16.66 6.0241 18 4.67871 18 3.01205C18 1.34538 16.66 0 15 0C13.34 0 12 1.34538 12 3.01205C12 3.25301 12.04 3.48394 12.09 3.71486L5.04 7.84137C4.5 7.33936 3.79 7.02811 3 7.02811C1.34 7.02811 0 8.37349 0 10.0402C0 11.7068 1.34 13.0522 3 13.0522C3.79 13.0522 4.5 12.741 5.04 12.239L12.16 16.4157C12.11 16.6265 12.08 16.8474 12.08 17.0683C12.08 18.6847 13.39 20 15 20C16.61 20 17.92 18.6847 17.92 17.0683C17.92 15.4518 16.61 14.1365 15 14.1365Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8661_576">
                      <rect width="21" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                {/* <p className="text-white font-semibold">@tknight.live</p> */}
              </button>
              <div
                className={`flex font-semibold flex-col absolute items-center justify-center  top-full -mt-1 left-1/2 transform -translate-x-1/2 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
              >
                <svg
                  className={`h-1.5 rotate-180 fill-current ${
                    isCaptionCopied ? "text-lime-600" : "text-gray-800"
                  } `}
                  viewBox="0 0 20 10"
                >
                  <path d="M0 0L20 0L10 10Z" />
                </svg>
                <span
                  className={`${
                    isCaptionCopied ? "bg-lime-600" : "bg-gray-800"
                  } px-2 py-1  rounded-md shadow`}
                >
                  {isSharingToApps
                    ? "Shared!"
                    : isCaptionCopied
                    ? "Copied!"
                    : "Share"}
                </span>
              </div>
            </div>
          </div>

          <p
            className={`text-gray-600 sm:text-lg relative z-10  mt-8 whitespace-normal text-center w-full`}
          >
            {(mainTopic && mainTopic.description) || "..."}
          </p>
        </div>

        <div className={`relative w-full mt-16`}>
          <div className="w-full  flex flex-col items-center justify-center relative z-10">
            <div className="overflow-x-auto flex items-center justify-center max-w-lg 2xl:max-w-xl w-full">
              {/* <button className="py-1 px-2 group relative transition-all hover:text-gray-700 text-gray-400 font-semibold flex items-center justify-start">
                <p>Mix</p>
                <div className="h-0.5 w-full group-hover:opacity-100 opacity-0 absolute inset-x-0 bottom-0 bg-current z-20"></div>
              </button> */}
              {/* <button className="py-1 px-2 group relative transition-all hover:text-gray-700 text-gray-400 font-semibold flex items-center justify-start">
                <p>Gallery</p>
                <div className="h-0.5 w-full group-hover:opacity-100 opacity-0 absolute inset-x-0 bottom-0 bg-current z-20"></div>
              </button> */}
              {/* <button className="py-1 px-2 group relative transition-all hover:text-gray-700 text-gray-400 font-semibold flex items-center justify-start">
                <p>Tableaux</p>
                <div className="h-0.5 w-full group-hover:opacity-100 opacity-0 absolute inset-x-0 bottom-0 bg-current z-20"></div>
              </button> */}
              {/* <button className="py-1 px-2 group relative transition-all hover:text-gray-700 text-gray-400 font-semibold flex items-center justify-start">
                <p>Questions</p>
                <div className="h-0.5 w-full group-hover:opacity-100 opacity-0 absolute inset-x-0 bottom-0 bg-current z-20"></div>
              </button> */}

              {/* <button className="py-1 px-2 group relative transition-all hover:text-gray-700 text-gray-400 font-semibold flex items-center justify-start">
                Stash
                <div className="h-0.5 w-full group-hover:opacity-100 opacity-0 absolute inset-x-0 bottom-0 bg-current z-20"></div>
              </button> */}
              {/* <button className="py-1 px-2 group relative transition-all hover:text-gray-700 text-gray-400 font-semibold flex items-center justify-start">
                Related
                <div className="h-0.5 w-full group-hover:opacity-100 opacity-0 absolute inset-x-0 bottom-0 bg-current z-20"></div>
              </button> */}
              <button className="py-1 hide-button-flash px-2 group relative transition-all text-gray-400 font-semibold flex items-center justify-start">
                You might also like
                <div className="h-0.5 w-full opacity-0 absolute inset-x-0 bottom-0 bg-current z-20"></div>
              </button>

              {/* <div className="flex-1"></div> */}
              {/* <button className="py-1 px-2 group relative transition-all hover:text-gray-700 text-gray-400 font-semibold flex items-center justify-start">
                <svg
                  className="h-6 py-1.5 fill-current"
                  viewBox="0 0 24 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 2C0 1.17157 0.671573 0.5 1.5 0.5H22.5C23.3284 0.5 24 1.17157 24 2C24 2.82843 23.3284 3.5 22.5 3.5H1.5C0.671573 3.5 0 2.82843 0 2ZM3 8C3 7.1716 3.67157 6.5 4.5 6.5H19.5C20.3284 6.5 21 7.1716 21 8C21 8.8284 20.3284 9.5 19.5 9.5H4.5C3.67157 9.5 3 8.8284 3 8ZM7.5 12.5C6.67157 12.5 6 13.1716 6 14C6 14.8284 6.67157 15.5 7.5 15.5H16.5C17.3284 15.5 18 14.8284 18 14C18 13.1716 17.3284 12.5 16.5 12.5H7.5Z"
                  />
                </svg>

                <div className="h-0.5 w-full group-hover:opacity-100 opacity-0 absolute inset-x-0 bottom-0 bg-current z-20"></div>
              </button> */}
            </div>
            <div className="h-0.5 w-full absolute bottom-0 bg-gray-200 inset-x-0 p-0 m-0"></div>
          </div>
        </div>
        <div
          className={`grid grid-cols-1 mb-20 w-full gap-4 py-2 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4`}
        >
          {relatedTopics &&
            relatedTopics.map((topic, index) => (
              <TopicSummaryBox
                key={topic.id}
                topic={topic}
                navigate={navigate}
                setTargetTopic={setTargetTopic}
                targetCustomer={targetCustomer}
                targetGuestCustomer={targetGuestCustomer}
                isOnTopicPage={true}
              />
            ))}
        </div>
      </div>

      <CustomerFooter
        customer={customer}
        navigate={navigate}
        logout={logout}
        targetCustomer={targetCustomer}
        accentColor={mainTopic ? mainTopic.accentColor : 0}
      />

      <MobileFooter navigate={navigate} page="topic" />
    </div>
  );
}

export default Topic;
