import React, { useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";

import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
// import  BlueFullGradBack from "../../components/creator/BlueFullGradBack";
import SubmitButton from "../../components/creator/SubmitButton";
import TextInputField from "../../components/creator/TextInputField";
import { useForm } from "../../util/hooks";

import BlueAngledGradBack from "../../components/creator/BlueAngledGradBack";
import { BrandContactAuthContext } from "../../context/brandContactAuth";
import { REGISTER_BRAND_CONTACT } from "../../util/gqlFunctions";

function RegisterSeller(props) {
  usePageTracking();

  const { brandContact, loginBrandContact } = useContext(
    BrandContactAuthContext
  );

  let navigate = useNavigate();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.title = "Seller Sign Up | TKnight";
  }, []);

  const { joinedFor } = useParams();

  useEffect(() => {
    if (brandContact) {
      navigate("/sellerDashboard");
    }
  }, [brandContact, navigate, joinedFor]);

  const { onChange, values, setValues } = useForm(signupBrandContactFunc, {
    email: "",
    name: "",

    password: "",

    businessName: "",
    businessWeb: "",

    country: "",

    gender: null,
  });

  //creating function to load ip address from the API

  const [registerBrandContact, { loading }] = useMutation(
    REGISTER_BRAND_CONTACT,
    {
      update(_, { data: { registerBrandContact: brandContactData } }) {
        setErrors({});

        if (brandContactData.token) {
          console.log("hiiii");

          loginBrandContact(brandContactData);
        }
      },
      onError(err) {
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
    }
  );

  function signupBrandContactFunc(e) {
    e.preventDefault();
    registerBrandContact();
  }

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-gray-50">
      <BlueAngledGradBack gradIndex={7} />
      <div className="relative z-10 max-w-5xl 2xl:max-w-7xl mx-auto xl:px-0 px-4 pb-10 lg:pb-0">
        <div className="space-x-10 relative z-20 px-2 sm:px-5 pt-4 pb-6 short:py-2 text-white flex items-center justify-between w-full">
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
            className="transition-opacity hover:opacity-50 py-1 focus:outline-none"
          >
            <svg
              className="h-5 fill-current "
              viewBox="0 0 140 140"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M69.7018 0C51.1365 0 33.3636 7.37493 20.2921 20.5025C7.22057 33.63 -0.0786027 51.4347 0.000638512 69.9999C0.0798797 88.5647 7.531 106.37 20.7136 119.497C33.8971 132.625 51.7337 140 70.2982 140C61.7401 140 53.3626 137.462 46.226 132.707C39.0902 127.952 33.5148 121.194 30.2062 113.287C26.8969 105.38 26.0032 96.679 27.637 88.2849C29.2709 79.8908 33.3595 72.1805 39.3851 66.1287C45.4116 60.077 53.1045 55.9556 61.491 54.286C69.8783 52.6163 78.5826 53.4732 86.5034 56.7484C94.4243 60.0236 101.206 65.57 105.991 72.6861C110.777 79.8019 113.35 88.1688 113.386 96.7275C113.435 108.204 108.923 119.21 100.842 127.325C92.7618 135.44 81.7751 140 70.2982 140C88.8635 140 106.636 132.625 119.708 119.497C132.779 106.37 140.079 88.5647 139.999 69.9999C139.92 51.4347 132.469 33.63 119.286 20.5025C106.103 7.37493 88.2671 0 69.7018 0Z" />
            </svg>
          </button>

          <button
            onClick={(e) => {
              navigate("/loginSeller");
            }}
            className="text-sm  h-[1.9rem] sm:text-base group flex-shrink-0 flex hover:bg-opacity-30 transition-all items-center justify-center space-x-2 bg-white bg-opacity-20 rounded-full backdrop-blur-sm pl-4 pr-3 py-1 sm:h-full"
          >
            <p className="font-bold">Sign in instead</p>
            <svg
              className="h-2.5 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
        </div>

        <div className="flex items-start justify-start space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="w-full sm:w-1/2 pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-6 sm:mb-10 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl shadow-2xl">
            <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
              Create a Business account
            </p>
            {/* <p className="text-gray-500 w-full "></p> */}

            {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
            <div className="w-full flex flex-col items-start justify-start space-y-6 my-4 sm:my-6">
              <TextInputField
                title="Your name"
                name="name"
                error={errors.name}
                disabled={loading}
                onChange={onChange}
                value={values.name}
              />

              <TextInputField
                title="Business name"
                name="businessName"
                error={errors.businessName}
                disabled={loading}
                onChange={onChange}
                value={values.businessName}
              />

              <TextInputField
                title="Business website (optional)"
                name="businessWeb"
                type={"url"}
                error={errors.businessWeb}
                disabled={loading}
                onChange={onChange}
                value={values.businessWeb}
                prefix="https://"
              />
              <TextInputField
                title="Email"
                name="email"
                type="email"
                error={errors.email}
                disabled={loading}
                onChange={onChange}
                value={values.email}
              />

              <TextInputField
                title="Password"
                name="password"
                type="password"
                error={errors.password}
                disabled={loading}
                onChange={onChange}
                value={values.password}
              />
            </div>

            <SubmitButton
              onClickFunc={async (e) => {
                e.preventDefault();
                registerBrandContact({
                  variables: {
                    ...values,
                  },
                });
                setValues({
                  ...values,
                });
              }}
              disabled={loading}
              // disabled={loading || !values.verificationCode}
              isNotLoading={!loading}
              title={"Create account"}
              errors={errors}
            />

            <p className="text-center text-gray-600 mt-4 sm:mb-0 mb-2 text-sm sm:text-base">
              Have an account?{" "}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/loginSeller");
                }}
                className="text-blue-600 hover:text-black transition-all"
              >
                Sign in
              </button>
            </p>
          </div>
          <div className="flex flex-col items-start justify-start space-y-4"></div>
        </div>
        <p className="text-gray-500 transition-all px-6 font-semibold mb-6 sm:mb-10">
          <button
            className="hover:text-gray-700 font-semibold transition-all"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            © TKnight
          </button>
          <span className="text-gray-200 mx-1 sm:mx-2"> - </span>
          <a
            className="hover:text-gray-700 font-semibold transition-all"
            href={"mailto:info@tknight.live"}
            target="_blank"
            rel="noreferrer"
          >
            Contact
          </a>
          <span className="text-gray-200 mx-1 sm:mx-2"> - </span>
          <button
            className="hover:text-gray-700 font-semibold transition-all"
            onClick={(e) => {
              e.preventDefault();
              navigate("/terms");
            }}
          >
            Privacy &amp; terms
          </button>
        </p>
      </div>
    </div>
  );
}

export default RegisterSeller;
