import React from "react";

export default function TokenIcon({ width }) {
  return (
    <svg
      className={`aspect-square ${width || "w-4"}`}
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M400 800C608.641 800 777.778 630.863 777.778 422.222C777.778 213.581 608.641 44.4443 400 44.4443C191.36 44.4443 22.2227 213.581 22.2227 422.222C22.2227 630.863 191.36 800 400 800Z"
        fill="#F4900C"
      />
      <path
        d="M400 755.556C608.641 755.556 777.778 586.419 777.778 377.778C777.778 169.137 608.641 0 400 0C191.36 0 22.2227 169.137 22.2227 377.778C22.2227 586.419 191.36 755.556 400 755.556Z"
        fill="#FFCC4D"
      />
      <path
        d="M400 711.111C571.822 711.111 711.111 571.822 711.111 400C711.111 228.178 571.822 88.8887 400 88.8887C228.178 88.8887 88.8887 228.178 88.8887 400C88.8887 571.822 228.178 711.111 400 711.111Z"
        fill="#FFE8B6"
      />
      <path
        d="M400 688.889C571.822 688.889 711.111 549.6 711.111 377.778C711.111 205.956 571.822 66.667 400 66.667C228.178 66.667 88.8887 205.956 88.8887 377.778C88.8887 549.6 228.178 688.889 400 688.889Z"
        fill="#FFAC33"
      />
      <path
        d="M399.02 148C338.02 148 279.623 172.232 236.674 215.365C193.725 258.499 169.742 317 170.002 378C170.262 438.998 194.745 497.502 238.059 540.635C281.376 583.767 339.982 608 400.98 608C372.86 608 345.334 599.66 321.885 584.038C298.439 568.415 280.12 546.208 269.249 520.228C258.375 494.249 255.439 465.659 260.807 438.079C266.176 410.498 279.61 385.165 299.408 365.28C319.209 345.396 344.486 331.854 372.042 326.368C399.6 320.882 428.2 323.698 454.225 334.459C480.251 345.221 502.532 363.444 518.256 386.826C533.98 410.206 542.435 437.698 542.553 465.819C542.714 503.527 527.89 539.691 501.338 566.355C474.789 593.019 438.69 608 400.98 608C461.98 608 520.377 583.767 563.326 540.635C606.275 497.502 630.258 438.998 629.998 378C629.738 317 605.255 258.499 561.941 215.365C518.624 172.232 460.021 148 399.02 148Z"
        fill="#FFD983"
      />
    </svg>
  );
}
