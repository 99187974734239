import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { customerClient } from "../../GraphqlApolloClients";
import {
  ACTIVE_NOTIFICATION_QUERY,
  FETCH_NUM_UNCLICKED_NOTIFICATIONS,
  SEND_NOTIF_CLICK_MUTATION,
} from "../../util/gqlFunctions";

export default function BattleNotifButton({ battleNotif, index, navigate }) {
  const [isNotifClicked, setIsNotifClicked] = useState(
    battleNotif.status === 1
  );

  const [sendNotificationClick] = useMutation(SEND_NOTIF_CLICK_MUTATION, {
    refetchQueries: [
      { query: FETCH_NUM_UNCLICKED_NOTIFICATIONS },
      { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 0 } },
      { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 1 } },
      { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 2 } },
      { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 3 } },
      { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 5 } },
    ],
    update(_, { data: { sendNotificationClick: notif } }) {
      if (notif.quizId) {
        navigate("/battle/" + notif.quizId);
      }
    },
    onError(err) {
      console.log(err);
    },

    client: customerClient,
  });

  return (
    <button
      key={index}
      className="w-full space-x-2 rounded sm:rounded-lg truncate transition-all group hover:bg-gray-100 flex items-center justify-start p-2 sm:p-3"
      onClick={(e) => {
        setIsNotifClicked(true);

        sendNotificationClick({
          variables: { notificationId: battleNotif.id },
        });
      }}
    >
      <svg
        className="w-3 sm:w-3.5 aspect-square text-gray-400 fill-current"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.4037 6.89891C2.16243 6.1402 3.39258 6.1402 4.15131 6.89891L6.21202 8.95958C6.97076 9.71838 6.97076 10.9485 6.21202 11.7072L4.49502 9.99018L2.74767 11.7376L1.37386 10.3638L3.12121 8.61638L1.4037 6.89891ZM0.686904 9.67688L3.43452 12.4246L2.74761 13.1115L0 10.3639L0.686904 9.67688Z"
        />
        <path d="M13.5 0L10.9373 0.50168L4.46425 6.58601L6.52496 8.64668L12.9982 2.56257L13.5 0Z" />
      </svg>
      <p className="text-sm font-semibold text-black text-left">
        {battleNotif.topicName || ""}
      </p>
      {!isNotifClicked ? (
        <div className="w-2 aspect-square bg-red-600 rounded-full"></div>
      ) : (
        <></>
      )}
      <div className="flex-1"></div>
      <svg
        className="w-2.5 h-2.5 text-gray-400 fill-current"
        viewBox="0 0 8 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" />
      </svg>
    </button>
  );
}
