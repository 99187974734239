import React, { useContext, useEffect, useState } from "react";

// import  usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate, useParams } from "react-router";
// import  Confetti from "react-confetti";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { adminClient } from "../../GraphqlApolloClients";
import AdminCustomerBox from "../../components/creator/AdminCustomerBox";
import AdminTNBox from "../../components/creator/AdminTNBox";
import BlueAngledGradBack from "../../components/creator/BlueAngledGradBack";
import EditTriviaNight from "../../components/creator/EditTriviaNight";
import Footer from "../../components/creator/Footer";
import { AdminAuthContext } from "../../context/adminAuth";
// import  LivestreamAnswerBox from "../../components/creator/LivestreamAnswerBox";
import AdminProductBox from "../../components/creator/AdminProductBox";
import EditForumForm from "../../components/creator/EditForumForm";
import EditProduct from "../../components/creator/EditProduct";

import AdminAdBox from "../../components/creator/AdminAdBox";
import AdminBrandContactBox from "../../components/creator/AdminBrandContactBox";
import AdminCityBox from "../../components/creator/AdminCityBox";
import AdminCustCashoutBox from "../../components/creator/AdminCustCashoutBox";
import AdminQuestionBox from "../../components/creator/AdminQuestionBox";
import AdminTNReviewBox from "../../components/creator/AdminTNReviewBox";
import AdminTopicBox from "../../components/creator/AdminTopicBox";
import CreateCityForm from "../../components/creator/CreateCityForm";
import EditCityForm from "../../components/creator/EditCityForm";
import EditQuestionForm from "../../components/creator/EditQuestionForm";
import EditSellerAd from "../../components/creator/EditSellerAd";
import EditTopicForm from "../../components/creator/EditTopicForm";
import MakeContentForm from "../../components/creator/MakeContentForm";
import MakeForumForm from "../../components/creator/MakeForumForm";
import MakeOrgForm from "../../components/creator/MakeOrgForm";
import MakeQuestion from "../../components/creator/MakeQuestion";
import MakeTopicForm from "../../components/creator/MakeTopicForm";
import TopBar from "../../components/creator/TopBar";
import {
  CREATE_AD_MUTATION,
  CREATE_PRODUCT,
  CREATE_TRIVIA_NIGHT,
  FETCH_ADMIN,
  FETCH_ALL_ADS,
  FETCH_ALL_SEARCHES,
  FETCH_BRAND_CONTACTS,
  FETCH_CUSTOMER_STRIPE_SUBS,
  FETCH_CUSTOMERS,
  FETCH_PENDING_CASHOUTS,
  FETCH_PRODUCTS,
  FETCH_QUESTIONS,
  FETCH_RECOMMENDED_CITIES,
  FETCH_RECOMMENDED_FORUMS,
  FETCH_REVIEWS,
  FETCH_TOPICS,
  FETCH_TRIVIA_NIGHTS,
  TOGGLE_SEARCH_TERM_CHECKED_BY_ADMIN_MUTATION,
} from "../../util/gqlFunctions";

function AdminDashboard(props) {
  // usePageTracking();
  const { typeOfData } = useParams();

  let navigate = useNavigate();
  const { admin, logoutAdmin: logout } = useContext(AdminAuthContext);

  useEffect(() => {
    document.title = "Admin Dash | TKnight";
  }, []);

  const { data: { getAdmin: adminInfo } = {}, loading: loadingAdminInfo } =
    useQuery(FETCH_ADMIN, {
      client: adminClient,
    });

  const [checkedIsAdminLoggedIn, setCheckedIsAdminLoggedIn] = useState(false);

  useEffect(() => {
    if (admin && !loadingAdminInfo && !adminInfo && !checkedIsAdminLoggedIn) {
      logout();
      setCheckedIsAdminLoggedIn(true);
    }
  }, [admin, loadingAdminInfo, adminInfo, logout, checkedIsAdminLoggedIn]);

  useEffect(() => {
    if (!admin) {
      navigate("/adminLogin");
    }
  }, [admin, navigate]);

  const [targetTN, setTargetTN] = useState(null);

  const [
    tknightCustomerIndexToScrollIntoView,
    setTKnightCustomerIndexToScrollIntoView,
  ] = useState(null);
  const [tknightCustomerIndexInView, setTKnightCustomerIndexInView] =
    useState(0);

  const [
    tknightBrandContactIndexToScrollIntoView,
    setTKnightBrandContactIndexToScrollIntoView,
  ] = useState(null);
  const [tknightBrandContactIndexInView, setTKnightBrandContactIndexInView] =
    useState(0);

  const { data: { getCustomers: customers } = {}, loading: loadingCustomers } =
    useQuery(FETCH_CUSTOMERS, {
      client: adminClient,
    });

  const {
    data: { getBrandContacts: brandContacts } = {},
    loading: loadingBrandContacts,
  } = useQuery(FETCH_BRAND_CONTACTS, {
    client: adminClient,
  });

  const [
    triviaNightIndexToScrollIntoView,
    setTriviaNightIndexToScrollIntoView,
  ] = useState(null);
  const [triviaNightIndexInView, setTriviaNightIndexInView] = useState(0);

  const {
    data: { getTriviaNights: triviaNights } = {},
    loading: loadingTriviaNights,
  } = useQuery(FETCH_TRIVIA_NIGHTS, {
    client: adminClient,
  });

  const {
    data: { getAllSearches: allSearches } = {},
    // loading: loadingAllSearches,
  } = useQuery(FETCH_ALL_SEARCHES, {
    client: adminClient,
    variables: { pageSize: 10, seenIds: [], isCheckedByAdmin: false },
  });

  const [errors, setErrors] = useState({});
  const [createTriviaNight, { loading: loadingCreateTN }] = useMutation(
    CREATE_TRIVIA_NIGHT,
    {
      refetchQueries: [{ query: FETCH_TRIVIA_NIGHTS }],
      update(_, { data: { createTriviaNight: triviaNight } }) {},
      onError(err) {
        console.log(err);
        // setValues({ ...values, shouldDelete: false });
        if (err.graphQLErrors[0]) {
          console.log(errors);
          console.log(err.graphQLErrors[0].extensions.errors);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: adminClient,
    }
  );

  // const {
  //   data: { getPendingTriviaWins: pendingTNWins } = {},
  //   loading: loadingTNWins,
  // } = useQuery(FETCH_PENDING_TN_WINS, {
  //   client: adminClient,
  // });

  // const [triviaWinIndexToScrollIntoView, setTriviaWinIndexToScrollIntoView] =
  //   useState(null);
  // const [triviaWinIndexInView, setTriviaWinIndexInView] = useState(0);

  // const {
  //   data: { getTNSharesPendingApproval: pendingTNShares } = {},
  //   loading: loadingTNShares,
  // } = useQuery(FETCH_PENDING_TN_SHARES, {
  //   client: adminClient,
  // });

  // const [
  //   triviaShareIndexToScrollIntoView,
  //   setTriviaShareIndexToScrollIntoView,
  // ] = useState(null);
  // const [triviaShareIndexInView, setTriviaShareIndexInView] = useState(0);

  // const [
  //   forumAdminRequestIndexToScrollIntoView,
  //   setForumAdminRequestIndexToScrollIntoView,
  // ] = useState(null);
  // const [forumAdminRequestIndexInView, setForumAdminRequestIndexInView] =
  //   useState(0);

  const {
    data: { getReviews: pendingTNReviews } = {},
    loading: loadingTNReviews,
  } = useQuery(FETCH_REVIEWS, {
    client: adminClient,
  });

  // const {
  //   data: { getForumAdminRequests: forumAdminRequests } = {},
  //   loading: loadingForumAdminRequests,
  // } = useQuery(FETCH_FORUM_ADMIN_REQUESTS, {
  //   client: adminClient,
  //   variables: { seenIds: [], pageSize: 20 },
  // });

  const [tnReviewIndexToScrollIntoView, setTriviaReviewIndexToScrollIntoView] =
    useState(null);
  const [triviaReviewIndexInView, setTriviaReviewIndexInView] = useState(0);

  const {
    data: { getPendingCashouts: pendingCashouts } = {},
    loading: loadingPendingCashouts,
  } = useQuery(FETCH_PENDING_CASHOUTS, {
    client: adminClient,
  });

  const [cashoutIndexToScrollIntoView, setCashoutIndexToScrollIntoView] =
    useState(null);
  const [cashoutIndexInView, setCashoutIndexInView] = useState(0);

  const [targetProduct, setTargetProduct] = useState(null);

  const { data: { getProducts: products } = {}, loading: loadingProducts } =
    useQuery(FETCH_PRODUCTS, {
      client: adminClient,
    });

  const [productIndexToScrollIntoView, setProductIndexToScrollIntoView] =
    useState(null);
  const [productIndexInView, setProductIndexInView] = useState(0);

  const [shouldOpenCreateCityForm, setShouldOpenCreateCityForm] =
    useState(false);

  const [createProduct, { loading: loadingCreateProduct }] = useMutation(
    CREATE_PRODUCT,
    {
      refetchQueries: [{ query: FETCH_PRODUCTS }],
      update(_, { data: { createProduct: product } }) {},
      onError(err) {
        console.log(err);
        // setValues({ ...values, shouldDelete: false });
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: adminClient,
    }
  );

  // const [targetCustomerProduct, setTargetCustomerProduct] = useState(null);

  const [
    toggleSearchTermCheckedByAdmin,
    { loading: loadingToggleSearchTermCheckedByAdmin },
  ] = useMutation(TOGGLE_SEARCH_TERM_CHECKED_BY_ADMIN_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_ALL_SEARCHES,
        variables: { pageSize: 10, seenIds: [], isCheckedByAdmin: false },
      },
    ],
    update(_, { data }) {},
    onError(err) {
      console.log(err);
      // setValues({ ...values, shouldDelete: false });
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    client: adminClient,
  });

  const [targetAd, setTargetAd] = useState(null);

  const { data: { getAllAds: ads } = {}, loading: loadingAds } = useQuery(
    FETCH_ALL_ADS,
    {
      client: adminClient,
    }
  );

  const [adIndexToScrollIntoView, setAdIndexToScrollIntoView] = useState(null);
  const [adIndexInView, setAdIndexInView] = useState(0);

  const [createAd, { loading: loadingCreateAd }] = useMutation(
    CREATE_AD_MUTATION,
    {
      refetchQueries: [{ query: FETCH_ALL_ADS }],
      update(_, { data: { createAd: ad } }) {},
      onError(err) {
        console.log(err);
        // setValues({ ...values, shouldDelete: false });
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: adminClient,
    }
  );

  // const {
  //   data: { getRecommendedOrgsBySearch: orgs } = {},
  //   loading: loadingOrgs,
  // } = useQuery(FETCH_RECOMMENDED_ORGS, {
  //   client: adminClient,
  // });

  // const [targetOrg, setTargetOrg] = useState(null);

  // const [orgIndexToScrollIntoView, setOrgIndexToScrollIntoView] =
  //   useState(null);
  // const [orgIndexInView, setOrgIndexInView] = useState(0);
  const [shouldOpenMakeOrgForm, setShouldOpenMakeOrgForm] = useState(false);

  const { data: { getTopics: topics } = {}, loading: loadingTopics } = useQuery(
    FETCH_TOPICS,
    {
      client: adminClient,
    }
  );
  // const [gameClipIndexInView, setGameClipIndexInView] = useState(0);
  // const [gameClipIndexToScrollIntoView, setGameClipIndexToScrollIntoView] =
  //   useState(null);
  // const { data: { getContent: gameClips } = {}, loading: loadingGameClips } =
  //   useQuery(FETCH_CONTENT, {
  //     client: adminClient,
  //     variables: {
  //       pageSize: 10,
  //     },
  //   });

  const [cityIndexInView, setCityIndexInView] = useState(0);
  const [cityIndexToScrollIntoView, setCityIndexToScrollIntoView] =
    useState(null);
  const {
    data: { getRecommendedCitiesBySearch: cities } = {},
    loading: loadingCities,
  } = useQuery(FETCH_RECOMMENDED_CITIES, {
    client: adminClient,
    variables: {
      seenIds: [],
      pageSize: 20,
    },
  });

  const {
    data: { getAllQuestions: questions } = {},
    loading: loadingQuestions,
  } = useQuery(FETCH_QUESTIONS, {
    client: adminClient,
    variables: {
      seenIds: [],
      pageSize: 10,
    },
    // pollInterval: 5000,
    // fetchPolicy: "cache-and-network",
  });

  const [targetTopic, setTargetTopic] = useState(null);
  const [targetQuestion, setTargetQuestion] = useState(null);

  const [topicIndexToScrollIntoView, setTopicIndexToScrollIntoView] =
    useState(null);
  const [topicIndexInView, setTopicIndexInView] = useState(0);
  const [shouldOpenMakeTopicForm, setShouldOpenMakeTopicForm] = useState(false);
  const [shouldOpenMakeGameClipForm, setShouldOpenMakeGameClipForm] =
    useState(false);

  const [questionIndexToScrollIntoView, setQuestionIndexToScrollIntoView] =
    useState(null);
  const [questionIndexInView, setQuestionIndexInView] = useState(0);
  const [shouldOpenMakeQuestionForm, setShouldOpenMakeQuestionForm] =
    useState(false);

  // const {
  //   data: { getRecommendedForumsBySearch: forums } = {},
  //   loading: loadingForums,
  // } = useQuery(FETCH_RECOMMENDED_ORG_SECTIONS, {
  //   client: adminClient,
  // });

  const {
    data: { getRecommendedForumsBySearch: forums } = {},
    // loading: loadingForums,
  } = useQuery(FETCH_RECOMMENDED_FORUMS, {
    client: adminClient,
    variables: { pageSize: 10, seenIds: [] },
  });

  const {
    data: { getCustomerStripeSubscriptions: custStripeSubscriptions } = {},
    loading: loadingCustStripeSubscriptions,
  } = useQuery(FETCH_CUSTOMER_STRIPE_SUBS, {
    client: adminClient,
    variables: { status: "past_due" },
  });

  if (custStripeSubscriptions && !loadingCustStripeSubscriptions) {
    console.log(custStripeSubscriptions);
  }

  const [targetForum, setTargetForum] = useState(null);

  // const [forumIndexToScrollIntoView, setForumIndexToScrollIntoView] =
  //   useState(null);
  // const [forumIndexInView, setForumIndexInView] = useState(0);
  const [shouldOpenMakeForumForm, setShouldOpenMakeForumForm] = useState(false);

  // const [targetContent, setTargetContent] = useState(null);
  const [typeOfView, setTypeOfView] = useState(typeOfData || "players");
  const [targetCity, setTargetCity] = useState(null);

  return (
    <div
      // ref={pageTopRef}
      className="w-screen relative overflow-x-hidden h-full min-h-screen bg-gray-50"
    >
      <BlueAngledGradBack gradIndex={1} />
      {targetTN !== null &&
        triviaNights &&
        triviaNights.length > 0 &&
        triviaNights[targetTN] && (
          <EditTriviaNight
            targetTN={triviaNights[targetTN]}
            setTargetTN={setTargetTN}
            navigate={navigate}
          />
        )}

      {targetProduct !== null &&
        products &&
        products.length > 0 &&
        products[targetProduct] && (
          <EditProduct
            targetProduct={products[targetProduct]}
            setTargetProduct={setTargetProduct}
          />
        )}

      {targetAd !== null && ads && ads.length > 0 && ads[targetAd] && (
        <EditSellerAd
          isAdmin={true}
          targetAd={ads[targetAd]}
          setTargetAd={setTargetAd}
        />
      )}

      {shouldOpenMakeTopicForm ? (
        <MakeTopicForm
          setShouldOpenMakeTopicForm={setShouldOpenMakeTopicForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenMakeGameClipForm ? (
        <MakeContentForm
          setShouldOpenMakeContentForm={setShouldOpenMakeGameClipForm}
          targetTopic={targetTopic}
          navigate={navigate}
        />
      ) : (
        <></>
      )}

      {shouldOpenMakeOrgForm ? (
        <MakeOrgForm setShouldOpenMakeOrgForm={setShouldOpenMakeOrgForm} />
      ) : (
        <></>
      )}

      {shouldOpenCreateCityForm ? (
        <CreateCityForm
          setShouldOpenCreateCityForm={setShouldOpenCreateCityForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenMakeForumForm ? (
        <MakeForumForm
          setShouldOpenMakeForumForm={setShouldOpenMakeForumForm}
        />
      ) : (
        <></>
      )}

      {/* {targetOrg !== null && orgs && orgs.length > 0 && orgs[targetOrg] && (
        <EditOrgForm targetOrg={orgs[targetOrg]} setTargetOrg={setTargetOrg} />
      )} */}

      {targetTopic !== null &&
        topics &&
        topics.length > 0 &&
        topics[targetTopic] && (
          <EditTopicForm
            targetTopic={topics[targetTopic]}
            setTargetTopic={setTargetTopic}
          />
        )}
      {targetQuestion !== null &&
        questions &&
        questions.length > 0 &&
        questions[targetQuestion] && (
          <EditQuestionForm
            targetQuestion={questions[targetQuestion]}
            setTargetQuestion={setTargetQuestion}
            navigate={navigate}
          />
        )}

      {shouldOpenMakeQuestionForm && (
        <MakeQuestion
          setShouldOpenCreateQ={setShouldOpenMakeQuestionForm}
          toggleQuestionAddedSuccessfullyForm={() => {}}
          isAdmin={true}
        />
      )}

      {targetForum !== null &&
        forums &&
        forums.length > 0 &&
        forums[targetForum] && (
          <EditForumForm
            targetForum={forums[targetForum]}
            setTargetForum={setTargetForum}
          />
        )}
      {/* 
      {targetContent !== null && gameClips && gameClips.length > 0 && (
        <EditContentForm
          targetContent={targetContent}
          setTargetContent={setTargetContent}
          navigate={navigate}
        />
      )} */}

      {targetCity !== null && cities && cities.length > 0 && (
        <EditCityForm
          targetCity={targetCity}
          setTargetCity={setTargetCity}
          navigate={navigate}
        />
      )}

      <div className="relative min-h-screen z-10 short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-10 lg:pb-0 ">
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          page="Admin Dash"
        />

        {/* <div className="relative mb-16">
          <button
            onClick={(e) => {
              e.preventDefault();
              setTKnightMonthlySubscriptionRevenueSummaryIndexToScrollIntoView(
                tknightMonthlySusbcriptionRevenueSummaryIndexInView - 1
              );
            }}
            disabled={
              loadingAdminInfo ||
              loadingTKnightMonthlySubscriptionRevenueSummary ||
              tknightMonthlySusbcriptionRevenueSummaryIndexInView <= 0
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pr-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
            </svg>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setTKnightMonthlySubscriptionRevenueSummaryIndexToScrollIntoView(
                tknightMonthlySusbcriptionRevenueSummaryIndexInView + 1
              );
            }}
            disabled={
              tknightMonthlySubscriptionRevenueSummary &&
              tknightMonthlySusbcriptionRevenueSummaryIndexInView >=
                tknightMonthlySubscriptionRevenueSummary.length - 1
                ? true
                : loadingAdminInfo ||
                  loadingTKnightMonthlySubscriptionRevenueSummary
                ? true
                : false
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pl-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
            </svg>
          </button>
          <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
            {adminInfo &&
              tknightMonthlySubscriptionRevenueSummary &&
              tknightMonthlySubscriptionRevenueSummary.length > 0 &&
              tknightMonthlySubscriptionRevenueSummary.map(
                (tknightMonthlySubscriptionEarning, index) => (
                  <AdminMonthlySubscriptionEarningBox
                    key={index}
                    index={index}
                    tknightMonthlySubscriptionEarning={
                      tknightMonthlySubscriptionEarning
                    }
                    adminInfo={adminInfo}
                    tknightMonthlySubscriptionRevenueSummaryIndexToScrollIntoView={
                      tknightMonthlySubscriptionRevenueSummaryIndexToScrollIntoView
                    }
                    setTKnightMonthlySubscriptionRevenueSummaryIndexInView={
                      setTKnightMonthlySubscriptionRevenueSummaryIndexInView
                    }
                    disabled={
                      loadingAdminInfo ||
                      loadingTKnightMonthlySubscriptionRevenueSummary
                    }
                  />
                )
              )}
          </div>

          <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
            <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
              {tknightMonthlySubscriptionRevenueSummary &&
                tknightMonthlySubscriptionRevenueSummary.length > 1 &&
                tknightMonthlySubscriptionRevenueSummary.map(
                  (tknightMonthlySubscriptionEarning, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setTKnightMonthlySubscriptionRevenueSummaryIndexToScrollIntoView(
                          index
                        );
                      }}
                      className={`${
                        index ===
                        tknightMonthlySusbcriptionRevenueSummaryIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  )
                )}
            </div>
          </div>
        </div> */}

        {/* <div className="relative mb-16">
          <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
            Job revenue summary{" "}
            {tknightMonthlyJobRevenueSummary &&
            tknightMonthlyJobRevenueSummary.length > 0
              ? "(" + tknightMonthlyJobRevenueSummary.length + ")"
              : ""}
          </p>
          <button
            onClick={(e) => {
              e.preventDefault();
              setTKnightMonthlyJobRevenueSummaryIndexToScrollIntoView(
                tknightMonthlyJobRevenueSummaryIndexInView - 1
              );
            }}
            disabled={
              loadingAdminInfo ||
              loadingTKnightMonthlyJobRevenueSummary ||
              tknightMonthlyJobRevenueSummaryIndexInView <= 0
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pr-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
            </svg>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setTKnightMonthlyJobRevenueSummaryIndexToScrollIntoView(
                tknightMonthlyJobRevenueSummaryIndexInView + 1
              );
            }}
            disabled={
              tknightMonthlyJobRevenueSummary &&
              tknightMonthlyJobRevenueSummaryIndexInView >=
                tknightMonthlyJobRevenueSummary.length - 1
                ? true
                : loadingAdminInfo || loadingTKnightMonthlyJobRevenueSummary
                ? true
                : false
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pl-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
            </svg>
          </button>
          <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
            {adminInfo &&
              tknightMonthlyJobRevenueSummary &&
              tknightMonthlyJobRevenueSummary.length > 0 &&
              tknightMonthlyJobRevenueSummary.map(
                (tknightMonthlyJobEarning, index) => (
                  <AdminMonthlyJobEarningBox
                    key={index}
                    index={index}
                    tknightMonthlyJobEarning={tknightMonthlyJobEarning}
                    adminInfo={adminInfo}
                    tknightMonthlyJobRevenueSummaryIndexToScrollIntoView={
                      tknightMonthlyJobRevenueSummaryIndexToScrollIntoView
                    }
                    setTKnightMonthlyJobRevenueSummaryIndexToScrollIntoView={
                      setTKnightMonthlyJobRevenueSummaryIndexToScrollIntoView
                    }
                    setTKnightMonthlyJobRevenueSummaryIndexInView={
                      setTKnightMonthlyJobRevenueSummaryIndexInView
                    }
                    disabled={
                      loadingAdminInfo || loadingTKnightMonthlyJobRevenueSummary
                    }
                  />
                )
              )}
          </div>

          <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
            <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
              {tknightMonthlyJobRevenueSummary &&
                tknightMonthlyJobRevenueSummary.length > 1 &&
                tknightMonthlyJobRevenueSummary.map(
                  (tknightMonthlySubscriptionEarning, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setTKnightMonthlyJobRevenueSummaryIndexToScrollIntoView(
                          index
                        );
                      }}
                      className={`${
                        index === tknightMonthlyJobRevenueSummaryIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  )
                )}
            </div>
          </div>
        </div> */}

        <div className="flex short:space-x-1 mb-6 space-x-2 overflow-x-auto items-center short:text-sm text-lg bg-white/20 w-full rounded-full short:px-1 short:py-1 px-2 py-2 shrink-0 overflow-y-hidden">
          {["Players", "Sellers", "Games"].map((typeOView, index) => (
            <button
              key={typeOView}
              onClick={(e) => {
                e.preventDefault();
                setTypeOfView(typeOView.toLowerCase());
                navigate("/adminDashboard/" + typeOView.toLowerCase());
              }}
              className={`${
                typeOfView === typeOView.toLowerCase()
                  ? "bg-white text-black"
                  : "text-white "
              } text-black font-medium short:px-2 px-4 w-full py-1 hover:bg-white hover:text-black transition-all rounded-full`}
            >
              {typeOView}
            </button>
          ))}
        </div>

        {typeOfView === "players" &&
          pendingCashouts &&
          pendingCashouts.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Cashouts{" "}
                {pendingCashouts && pendingCashouts.length > 0
                  ? "(" + pendingCashouts.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setCashoutIndexToScrollIntoView(cashoutIndexInView - 1);
                }}
                disabled={
                  loadingAdminInfo ||
                  loadingPendingCashouts ||
                  cashoutIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setCashoutIndexToScrollIntoView(cashoutIndexInView + 1);
                }}
                disabled={
                  pendingCashouts &&
                  cashoutIndexInView >= pendingCashouts.length - 1
                    ? true
                    : loadingAdminInfo || loadingPendingCashouts
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  pendingCashouts &&
                  pendingCashouts.length > 0 &&
                  pendingCashouts.map((cashout, index) => (
                    <AdminCustCashoutBox
                      key={index}
                      index={index}
                      cashout={cashout}
                      adminInfo={adminInfo}
                      cashoutIndexToScrollIntoView={
                        cashoutIndexToScrollIntoView
                      }
                      setCashoutIndexInView={setCashoutIndexInView}
                      setCashoutIndexToScrollIntoView={
                        setCashoutIndexToScrollIntoView
                      }
                      cashoutIndexInView={cashoutIndexInView}
                      disabled={loadingAdminInfo || loadingPendingCashouts}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {pendingCashouts &&
                    pendingCashouts.length > 1 &&
                    pendingCashouts.map((cashout, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setCashoutIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === cashoutIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )}

        {typeOfView === "players" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Searches{" "}
              {allSearches && allSearches.length > 0
                ? "(" + allSearches.length + ")"
                : ""}
            </p>

            <div className="w-full bg-white p-2 rounded-xl">
              {allSearches && allSearches.length > 0 ? (
                allSearches.map((savedSearch, index) => (
                  <div
                    key={index}
                    className="flex items-center w-full space-x-2 hover:bg-blue-100 transition-all p-2"
                  >
                    <p className="flex-1">
                      {savedSearch.searchTerm} -{" "}
                      {moment(savedSearch.lastSearchedAt).fromNow()}
                    </p>
                    <p>{savedSearch.count}</p>
                    <button
                      className=" text-black hover:text-red-600 transition-all"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleSearchTermCheckedByAdmin({
                          variables: {
                            searchTerm: savedSearch.searchTerm,
                          },
                        });
                      }}
                      disabled={loadingToggleSearchTermCheckedByAdmin}
                    >
                      <svg
                        className="w-2.5  fill-current"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                      </svg>
                    </button>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        )}

        {typeOfView === "players" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Players{" "}
              {customers && customers.length > 0
                ? "(" + customers.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setTKnightCustomerIndexToScrollIntoView(
                  tknightCustomerIndexInView - 1
                );
              }}
              disabled={
                loadingAdminInfo ||
                loadingCustomers ||
                tknightCustomerIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setTKnightCustomerIndexToScrollIntoView(
                  tknightCustomerIndexInView + 1
                );
              }}
              disabled={
                customers && tknightCustomerIndexInView >= customers.length - 1
                  ? true
                  : loadingAdminInfo || loadingCustomers
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                customers &&
                customers.length > 0 &&
                customers.map((tknightCustomer, index) => (
                  <AdminCustomerBox
                    key={index}
                    index={index}
                    navigate={navigate}
                    tknightCustomer={tknightCustomer}
                    adminInfo={adminInfo}
                    tknightCustomerIndexToScrollIntoView={
                      tknightCustomerIndexToScrollIntoView
                    }
                    setTKnightCustomerIndexInView={
                      setTKnightCustomerIndexInView
                    }
                    setTKnightCustomerIndexToScrollIntoView={
                      setTKnightCustomerIndexToScrollIntoView
                    }
                    tknightCustomerIndexInView={tknightCustomerIndexInView}
                    disabled={loadingAdminInfo || loadingCustomers}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                {customers &&
                  customers.length > 1 &&
                  customers.map((tknightCustomer, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setTKnightCustomerIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === tknightCustomerIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>
            </div>
          </div>
        )}

        {typeOfView === "sellers" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Sellers{" "}
              {brandContacts && brandContacts.length > 0
                ? "(" + brandContacts.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setTKnightBrandContactIndexToScrollIntoView(
                  tknightBrandContactIndexInView - 1
                );
              }}
              disabled={
                loadingAdminInfo ||
                loadingBrandContacts ||
                tknightBrandContactIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setTKnightBrandContactIndexToScrollIntoView(
                  tknightBrandContactIndexInView + 1
                );
              }}
              disabled={
                brandContacts &&
                tknightBrandContactIndexInView >= brandContacts.length - 1
                  ? true
                  : loadingAdminInfo || loadingBrandContacts
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                brandContacts &&
                brandContacts.length > 0 &&
                brandContacts.map((tknightBrandContact, index) => (
                  <AdminBrandContactBox
                    key={index}
                    index={index}
                    navigate={navigate}
                    tknightBrandContact={tknightBrandContact}
                    adminInfo={adminInfo}
                    tknightBrandContactIndexToScrollIntoView={
                      tknightBrandContactIndexToScrollIntoView
                    }
                    setTKnightBrandContactIndexInView={
                      setTKnightBrandContactIndexInView
                    }
                    setTKnightBrandContactIndexToScrollIntoView={
                      setTKnightBrandContactIndexToScrollIntoView
                    }
                    tknightBrandContactIndexInView={
                      tknightBrandContactIndexInView
                    }
                    disabled={loadingAdminInfo || loadingBrandContacts}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                {brandContacts &&
                  brandContacts.length > 1 &&
                  brandContacts.map((tknightBrandContact, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setTKnightBrandContactIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === tknightBrandContactIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>
            </div>
          </div>
        )}

        {typeOfView === "games" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Topics{" "}
              {topics && topics.length > 0 ? "(" + topics.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setTopicIndexToScrollIntoView(topicIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingTopics || topicIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setTopicIndexToScrollIntoView(topicIndexInView + 1);
              }}
              disabled={
                topics && topicIndexInView >= topics.length - 1
                  ? true
                  : loadingAdminInfo || loadingTopics
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                topics &&
                topics.length > 0 &&
                topics.map((topic, index) => (
                  <AdminTopicBox
                    key={index}
                    index={index}
                    topic={topic}
                    adminInfo={adminInfo}
                    topicIndexToScrollIntoView={topicIndexToScrollIntoView}
                    setTopicIndexInView={setTopicIndexInView}
                    setTopicIndexToScrollIntoView={
                      setTopicIndexToScrollIntoView
                    }
                    setTargetTopic={setTargetTopic}
                    topicIndexInView={topicIndexInView}
                    disabled={loadingAdminInfo || loadingTopics}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {topics &&
                  topics.length > 1 &&
                  topics.map((topic, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setTopicIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === topicIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenMakeTopicForm(!shouldOpenMakeTopicForm);
                }}
                disabled={loadingTopics}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add topic
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )}

        {typeOfView === "games" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Questions{" "}
              {questions && questions.length > 0
                ? "(" + questions.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setQuestionIndexToScrollIntoView(questionIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingQuestions || questionIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setQuestionIndexToScrollIntoView(questionIndexInView + 1);
              }}
              disabled={
                questions && questionIndexInView >= questions.length - 1
                  ? true
                  : loadingAdminInfo || loadingQuestions
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                questions &&
                questions.length > 0 &&
                questions.map((question, index) => (
                  <AdminQuestionBox
                    key={index}
                    index={index}
                    question={question}
                    adminInfo={adminInfo}
                    questionIndexToScrollIntoView={
                      questionIndexToScrollIntoView
                    }
                    setQuestionIndexInView={setQuestionIndexInView}
                    setQuestionIndexToScrollIntoView={
                      setQuestionIndexToScrollIntoView
                    }
                    setTargetQuestion={setTargetQuestion}
                    questionIndexInView={questionIndexInView}
                    disabled={loadingAdminInfo || loadingQuestions}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {questions &&
                  questions.length > 1 &&
                  questions.map((question, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setQuestionIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === questionIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenMakeQuestionForm(!shouldOpenMakeQuestionForm);
                }}
                disabled={loadingQuestions}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add question
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )}
        {/* 
        {typeOfView === "games" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Game clips{" "}
              {gameClips && gameClips.length > 0
                ? "(" + gameClips.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setGameClipIndexToScrollIntoView(gameClipIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingGameClips || gameClipIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setGameClipIndexToScrollIntoView(gameClipIndexInView + 1);
              }}
              disabled={
                gameClips && gameClipIndexInView >= gameClips.length - 1
                  ? true
                  : loadingAdminInfo || loadingGameClips
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                gameClips &&
                gameClips.length > 0 &&
                gameClips.map((gameClip, index) => (
                  <AdminGameClipBox
                    key={index}
                    index={index}
                    gameClip={gameClip}
                    adminInfo={adminInfo}
                    gameClipIndexToScrollIntoView={
                      gameClipIndexToScrollIntoView
                    }
                    setGameClipIndexInView={setGameClipIndexInView}
                    setGameClipIndexToScrollIntoView={
                      setGameClipIndexToScrollIntoView
                    }
                    setTargetTopic={setTargetTopic}
                    gameClipIndexInView={gameClipIndexInView}
                    disabled={loadingAdminInfo || loadingGameClips}
                    setTargetContent={setTargetContent}
                    targetContent={targetContent}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {gameClips &&
                  gameClips.length > 1 &&
                  gameClips.map((gameClip, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setGameClipIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === gameClipIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenMakeGameClipForm(!shouldOpenMakeGameClipForm);
                }}
                disabled={loadingGameClips}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add game clip
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )} */}

        {/* {typeOfView === "players" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Organizations{" "}
              {orgs && orgs.length > 0 ? "(" + orgs.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setOrgIndexToScrollIntoView(orgIndexInView - 1);
              }}
              disabled={loadingAdminInfo || loadingOrgs || orgIndexInView <= 0}
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setOrgIndexToScrollIntoView(orgIndexInView + 1);
              }}
              disabled={
                orgs && orgIndexInView >= orgs.length - 1
                  ? true
                  : loadingAdminInfo || loadingOrgs
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                orgs &&
                orgs.length > 0 &&
                orgs.map((org, index) => (
                  <AdminOrgBox
                    key={index}
                    index={index}
                    org={org}
                    adminInfo={adminInfo}
                    orgIndexToScrollIntoView={orgIndexToScrollIntoView}
                    setOrgIndexInView={setOrgIndexInView}
                    setOrgIndexToScrollIntoView={setOrgIndexToScrollIntoView}
                    setTargetOrg={setTargetOrg}
                    orgIndexInView={orgIndexInView}
                    disabled={loadingAdminInfo || loadingOrgs}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {orgs &&
                  orgs.length > 1 &&
                  orgs.map((org, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setOrgIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === orgIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenMakeOrgForm(!shouldOpenMakeOrgForm);
                }}
                disabled={loadingOrgs}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add organization
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )} */}
        {/* {typeOfView === "players" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Forums{" "}
              {forums && forums.length > 0 ? "(" + forums.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setForumIndexToScrollIntoView(forumIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingForums || forumIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setForumIndexToScrollIntoView(forumIndexInView + 1);
              }}
              disabled={
                forums && forumIndexInView >= forums.length - 1
                  ? true
                  : loadingAdminInfo || loadingForums
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                forums &&
                forums.length > 0 &&
                forums.map((forum, index) => (
                  <AdminForumBox
                    key={index}
                    index={index}
                    forum={forum}
                    adminInfo={adminInfo}
                    forumIndexToScrollIntoView={forumIndexToScrollIntoView}
                    setForumIndexInView={setForumIndexInView}
                    setForumIndexToScrollIntoView={
                      setForumIndexToScrollIntoView
                    }
                    setTargetForum={setTargetForum}
                    forumIndexInView={forumIndexInView}
                    disabled={loadingAdminInfo || loadingForums}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {forums &&
                  forums.length > 1 &&
                  forums.map((org, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setForumIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === forumIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenMakeForumForm(!shouldOpenMakeForumForm);
                }}
                disabled={loadingForums}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add forum
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )} */}

        {typeOfView === "sellers" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Ads {ads && ads.length > 0 ? "(" + ads.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setAdIndexToScrollIntoView(adIndexInView - 1);
              }}
              disabled={loadingAdminInfo || loadingAds || adIndexInView <= 0}
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setAdIndexToScrollIntoView(adIndexInView + 1);
              }}
              disabled={
                ads && adIndexInView >= ads.length - 1
                  ? true
                  : loadingAdminInfo || loadingAds
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                ads &&
                ads.length > 0 &&
                ads.map((ad, index) => (
                  <AdminAdBox
                    key={index}
                    index={index}
                    // downloadScreenshots={downloadScreenshots}
                    ad={ad}
                    adminInfo={adminInfo}
                    adIndexToScrollIntoView={adIndexToScrollIntoView}
                    setAdIndexInView={setAdIndexInView}
                    setAdIndexToScrollIntoView={setAdIndexToScrollIntoView}
                    setTargetAd={setTargetAd}
                    adIndexInView={adIndexInView}
                    disabled={loadingAdminInfo || loadingAds}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {ads &&
                  ads.length > 1 &&
                  ads.map((ad, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setAdIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === adIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  createAd();
                }}
                disabled={loadingCreateAd}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Create ad
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  {loadingCreateAd ? (
                    <svg
                      className={`p-4 text-white stroke-current m-auto motion-safe:animate-spin`}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.636 3.636A9 9 0 0119 10"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      className=" text-white fill-current p-3"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg>
                  )}
                </div>
              </button>
            </div>
          </div>
        )}

        {typeOfView === "sellers" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Products{" "}
              {products && products.length > 0
                ? "(" + products.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setProductIndexToScrollIntoView(productIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingProducts || productIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setProductIndexToScrollIntoView(productIndexInView + 1);
              }}
              disabled={
                products && productIndexInView >= products.length - 1
                  ? true
                  : loadingAdminInfo || loadingProducts
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                products &&
                products.length > 0 &&
                products.map((product, index) => (
                  <AdminProductBox
                    key={index}
                    index={index}
                    // downloadScreenshots={downloadScreenshots}
                    product={product}
                    adminInfo={adminInfo}
                    productIndexToScrollIntoView={productIndexToScrollIntoView}
                    setProductIndexInView={setProductIndexInView}
                    setProductIndexToScrollIntoView={
                      setProductIndexToScrollIntoView
                    }
                    setTargetProduct={setTargetProduct}
                    productIndexInView={productIndexInView}
                    disabled={loadingAdminInfo || loadingProducts}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {products &&
                  products.length > 1 &&
                  products.map((product, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setProductIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === productIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  createProduct();
                }}
                disabled={loadingCreateProduct}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add product
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  {loadingCreateProduct ? (
                    <svg
                      className={`p-4 text-white stroke-current m-auto motion-safe:animate-spin`}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.636 3.636A9 9 0 0119 10"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      className=" text-white fill-current p-3"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg>
                  )}
                </div>
              </button>
            </div>
          </div>
        )}
        {/* 
        {typeOfView === "Players" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Customer products{" "}
              {allCustomerProducts && allCustomerProducts.length > 0
                ? "(" + allCustomerProducts.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setCustomerProductIndexToScrollIntoView(
                  customerProductIndexInView - 1
                );
              }}
              disabled={
                loadingAdminInfo ||
                loadingAllCustomerProducts ||
                customerProductIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setCustomerProductIndexToScrollIntoView(
                  customerProductIndexInView + 1
                );
              }}
              disabled={
                allCustomerProducts &&
                customerProductIndexInView >= allCustomerProducts.length - 1
                  ? true
                  : loadingAdminInfo || loadingAllCustomerProducts
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                allCustomerProducts &&
                allCustomerProducts.length > 0 &&
                allCustomerProducts.map((customerProduct, index) => (
                  <AdminCustomerProductBox
                    key={index}
                    index={index}
                    customerProduct={customerProduct}
                    adminInfo={adminInfo}
                    customerProductIndexToScrollIntoView={
                      customerProductIndexToScrollIntoView
                    }
                    setCustomerProductIndexInView={
                      setCustomerProductIndexInView
                    }
                    setCustomerProductIndexToScrollIntoView={
                      setCustomerProductIndexToScrollIntoView
                    }
                    // setTargetCustomerProduct={setTargetCustomerProduct}
                    customerProductIndexInView={customerProductIndexInView}
                    disabled={loadingAdminInfo || loadingAllCustomerProducts}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {allCustomerProducts &&
                  allCustomerProducts.length > 1 &&
                  allCustomerProducts.map((customerProduct, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setCustomerProductIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === customerProductIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>
            </div>
          </div>
        )} */}

        {/* {typeOfView === "players" &&
          pendingTNWins &&
          pendingTNWins.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Trivia Wins{" "}
                {pendingTNWins && pendingTNWins.length > 0
                  ? "(" + pendingTNWins.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaWinIndexToScrollIntoView(triviaWinIndexInView - 1);
                }}
                disabled={
                  loadingAdminInfo || loadingTNWins || triviaWinIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaWinIndexToScrollIntoView(triviaWinIndexInView + 1);
                }}
                disabled={
                  pendingTNWins &&
                  triviaWinIndexInView >= pendingTNWins.length - 1
                    ? true
                    : loadingAdminInfo || loadingTNWins
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  pendingTNWins &&
                  pendingTNWins.length > 0 &&
                  pendingTNWins.map((triviaWin, index) => (
                    <AdminTNWinBox
                      key={index}
                      index={index}
                      triviaWin={triviaWin}
                      adminInfo={adminInfo}
                      triviaWinIndexToScrollIntoView={
                        triviaWinIndexToScrollIntoView
                      }
                      setTriviaWinIndexInView={setTriviaWinIndexInView}
                      setTriviaWinIndexToScrollIntoView={
                        setTriviaWinIndexToScrollIntoView
                      }
                      triviaWinIndexInView={triviaWinIndexInView}
                      disabled={loadingAdminInfo || loadingTNWins}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {pendingTNWins &&
                    pendingTNWins.length > 1 &&
                    pendingTNWins.map((triviaWin, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setTriviaWinIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === triviaWinIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )} */}

        {/* {typeOfView === "Players" &&
          pendingTNBailOuts &&
          pendingTNBailOuts.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Trivia Surrenders{" "}
                {pendingTNBailOuts && pendingTNBailOuts.length > 0
                  ? "(" + pendingTNBailOuts.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setBailOutIndexToScrollIntoView(bailOutIndexInView - 1);
                }}
                disabled={
                  loadingAdminInfo ||
                  loadingTNBailOuts ||
                  bailOutIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setBailOutIndexToScrollIntoView(bailOutIndexInView + 1);
                }}
                disabled={
                  pendingTNBailOuts &&
                  bailOutIndexInView >= pendingTNBailOuts.length - 1
                    ? true
                    : loadingAdminInfo || loadingTNBailOuts
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  pendingTNBailOuts &&
                  pendingTNBailOuts.length > 0 &&
                  pendingTNBailOuts.map((bailOut, index) => (
                    <AdminTNBailOutBox
                      key={index}
                      index={index}
                      bailOut={bailOut}
                      adminInfo={adminInfo}
                      bailOutIndexToScrollIntoView={
                        bailOutIndexToScrollIntoView
                      }
                      setBailOutIndexInView={setBailOutIndexInView}
                      setBailOutIndexToScrollIntoView={
                        setBailOutIndexToScrollIntoView
                      }
                      bailOutIndexInView={bailOutIndexInView}
                      disabled={loadingAdminInfo || loadingTNBailOuts}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {pendingTNBailOuts &&
                    pendingTNBailOuts.length > 1 &&
                    pendingTNBailOuts.map((bailOut, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setBailOutIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === bailOutIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )} */}

        {/* {typeOfView === "players" &&
          pendingTNShares &&
          pendingTNShares.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Trivia Shares{" "}
                {pendingTNShares && pendingTNShares.length > 0
                  ? "(" + pendingTNShares.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaShareIndexToScrollIntoView(
                    triviaShareIndexInView - 1
                  );
                }}
                disabled={
                  loadingAdminInfo ||
                  loadingTNShares ||
                  triviaShareIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaShareIndexToScrollIntoView(
                    triviaShareIndexInView + 1
                  );
                }}
                disabled={
                  pendingTNShares &&
                  triviaShareIndexInView >= pendingTNShares.length - 1
                    ? true
                    : loadingAdminInfo || loadingTNShares
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  pendingTNShares &&
                  pendingTNShares.length > 0 &&
                  pendingTNShares.map((triviaShare, index) => (
                    <AdminTNShareBox
                      key={index}
                      index={index}
                      triviaShare={triviaShare}
                      adminInfo={adminInfo}
                      triviaShareIndexToScrollIntoView={
                        triviaShareIndexToScrollIntoView
                      }
                      setTriviaShareIndexInView={setTriviaShareIndexInView}
                      setTriviaShareIndexToScrollIntoView={
                        setTriviaShareIndexToScrollIntoView
                      }
                      triviaShareIndexInView={triviaShareIndexInView}
                      disabled={loadingAdminInfo || loadingTNShares}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {pendingTNShares &&
                    pendingTNShares.length > 1 &&
                    pendingTNShares.map((triviaShare, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setTriviaShareIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === triviaShareIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )} */}

        {typeOfView === "players" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Cities{" "}
              {cities && cities.length > 0 ? "(" + cities.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setCityIndexToScrollIntoView(cityIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingCities || cityIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setCityIndexToScrollIntoView(cityIndexInView + 1);
              }}
              disabled={
                cities && cityIndexInView >= cities.length - 1
                  ? true
                  : loadingAdminInfo || loadingCities
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                cities &&
                cities.length > 0 &&
                cities.map((city, index) => (
                  <AdminCityBox
                    key={index}
                    index={index}
                    city={city}
                    adminInfo={adminInfo}
                    cityIndexToScrollIntoView={cityIndexToScrollIntoView}
                    setCityIndexInView={setCityIndexInView}
                    setCityIndexToScrollIntoView={setCityIndexToScrollIntoView}
                    setTargetTopic={setTargetTopic}
                    cityIndexInView={cityIndexInView}
                    disabled={loadingAdminInfo || loadingCities}
                    setTargetCity={setTargetCity}
                    targetCity={targetCity}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {cities &&
                  cities.length > 1 &&
                  cities.map((city, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setCityIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === cityIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenCreateCityForm(!shouldOpenCreateCityForm);
                }}
                disabled={loadingCities}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add city
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )}
        {typeOfView === "players" &&
          pendingTNReviews &&
          pendingTNReviews.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Trivia Reviews{" "}
                {pendingTNReviews && pendingTNReviews.length > 0
                  ? "(" + pendingTNReviews.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaReviewIndexToScrollIntoView(
                    triviaReviewIndexInView - 1
                  );
                }}
                disabled={
                  loadingAdminInfo ||
                  loadingTNReviews ||
                  triviaReviewIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaReviewIndexToScrollIntoView(
                    triviaReviewIndexInView + 1
                  );
                }}
                disabled={
                  pendingTNReviews &&
                  triviaReviewIndexInView >= pendingTNReviews.length - 1
                    ? true
                    : loadingAdminInfo || loadingTNReviews
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  pendingTNReviews &&
                  pendingTNReviews.length > 0 &&
                  pendingTNReviews.map((tnReview, index) => (
                    <AdminTNReviewBox
                      key={index}
                      index={index}
                      tnReview={tnReview}
                      adminInfo={adminInfo}
                      tnReviewIndexToScrollIntoView={
                        tnReviewIndexToScrollIntoView
                      }
                      setTriviaReviewIndexInView={setTriviaReviewIndexInView}
                      setTriviaReviewIndexToScrollIntoView={
                        setTriviaReviewIndexToScrollIntoView
                      }
                      triviaReviewIndexInView={triviaReviewIndexInView}
                      disabled={loadingAdminInfo || loadingTNReviews}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {pendingTNReviews &&
                    pendingTNReviews.length > 1 &&
                    pendingTNReviews.map((tnReview, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setTriviaReviewIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === triviaReviewIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )}

        {/* {typeOfView === "players" &&
          forumAdminRequests &&
          forumAdminRequests.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Forum Admin Requests{" "}
                {forumAdminRequests && forumAdminRequests.length > 0
                  ? "(" + forumAdminRequests.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setForumAdminRequestIndexToScrollIntoView(
                    forumAdminRequestIndexInView - 1
                  );
                }}
                disabled={
                  loadingAdminInfo ||
                  loadingForumAdminRequests ||
                  forumAdminRequestIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setForumAdminRequestIndexToScrollIntoView(
                    forumAdminRequestIndexInView + 1
                  );
                }}
                disabled={
                  forumAdminRequests &&
                  forumAdminRequestIndexInView >= forumAdminRequests.length - 1
                    ? true
                    : loadingAdminInfo || loadingForumAdminRequests
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  forumAdminRequests &&
                  forumAdminRequests.length > 0 &&
                  forumAdminRequests.map((forumAdminRequest, index) => (
                    <AdminFAdminRequestBox
                      key={index}
                      index={index}
                      forumAdminRequest={forumAdminRequest}
                      adminInfo={adminInfo}
                      forumAdminRequestIndexToScrollIntoView={
                        forumAdminRequestIndexToScrollIntoView
                      }
                      setForumAdminRequestIndexInView={
                        setForumAdminRequestIndexInView
                      }
                      setForumAdminRequestIndexToScrollIntoView={
                        setForumAdminRequestIndexToScrollIntoView
                      }
                      forumAdminRequestIndexInView={
                        forumAdminRequestIndexInView
                      }
                      disabled={loadingAdminInfo || loadingForumAdminRequests}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {forumAdminRequests &&
                    forumAdminRequests.length > 1 &&
                    forumAdminRequests.map((tnReview, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setForumAdminRequestIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === forumAdminRequestIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )} */}

        {typeOfView === "games" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Trivia Nights{" "}
              {triviaNights && triviaNights.length > 0
                ? "(" + triviaNights.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setTriviaNightIndexToScrollIntoView(triviaNightIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo ||
                loadingTriviaNights ||
                triviaNightIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setTriviaNightIndexToScrollIntoView(triviaNightIndexInView + 1);
              }}
              disabled={
                triviaNights &&
                triviaNightIndexInView >= triviaNights.length - 1
                  ? true
                  : loadingAdminInfo || loadingTriviaNights
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                triviaNights &&
                triviaNights.length > 0 &&
                triviaNights.map((triviaNight, index) => (
                  <AdminTNBox
                    key={index}
                    index={index}
                    // downloadScreenshots={downloadScreenshots}
                    triviaNight={triviaNight}
                    adminInfo={adminInfo}
                    triviaNightIndexToScrollIntoView={
                      triviaNightIndexToScrollIntoView
                    }
                    setTriviaNightIndexInView={setTriviaNightIndexInView}
                    setTriviaNightIndexToScrollIntoView={
                      setTriviaNightIndexToScrollIntoView
                    }
                    setTargetTN={setTargetTN}
                    triviaNightIndexInView={triviaNightIndexInView}
                    disabled={loadingAdminInfo || loadingTriviaNights}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {triviaNights &&
                  triviaNights.length > 1 &&
                  triviaNights.map((triviaNight, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setTriviaNightIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === triviaNightIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  createTriviaNight();
                }}
                disabled={loadingCreateTN}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Make TKL
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  {loadingCreateTN ? (
                    <svg
                      className={`p-4 text-white stroke-current m-auto motion-safe:animate-spin`}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.636 3.636A9 9 0 0119 10"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      className=" text-white fill-current p-3"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg>
                  )}
                </div>
              </button>
            </div>
          </div>
        )}
      </div>

      <Footer navigate={navigate} logout={logout} customer={admin} />
    </div>
  );
}

export default AdminDashboard;
