import { jwtDecode } from "jwt-decode";
import React, { createContext, useReducer } from "react";

const initialState = {
  brandContact: null,
};

if (localStorage.getItem("brandContactJwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("brandContactJwtToken"));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("brandContactJwtToken");
  } else {
    initialState.brandContact = decodedToken;
  }
}

const BrandContactAuthContext = createContext({
  brandContact: null,
  loginBrandContact: (brandContactData) => {},
  logoutBrandContact: () => {},
});

function brandContactAuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN_BRAND_CONTACT":
      return {
        ...state,
        brandContact: action.payload,
      };
    case "LOGOUT_BRAND_CONTACT":
      return {
        ...state,
        brandContact: null,
      };
    default:
      return state;
  }
}

function BrandContactAuthProvider(props) {
  const [state, dispatch] = useReducer(brandContactAuthReducer, initialState);

  function loginBrandContact(brandContactData) {
    console.log("ergwee");

    localStorage.setItem("brandContactJwtToken", brandContactData.token);
    const decodedToken = jwtDecode(brandContactData.token);
    console.log("asdasdasd");
    dispatch({
      type: "LOGIN_BRAND_CONTACT",
      payload: decodedToken,
    });
  }

  function logoutBrandContact() {
    localStorage.removeItem("brandContactJwtToken");
    dispatch({ type: "LOGOUT_BRAND_CONTACT" });
  }

  return (
    <BrandContactAuthContext.Provider
      value={{
        brandContact: state.brandContact,
        loginBrandContact,
        logoutBrandContact,
      }}
      {...props}
    />
  );
}

export { BrandContactAuthContext, BrandContactAuthProvider };
