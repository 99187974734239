import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import { adminClient, customerClient } from "../../GraphqlApolloClients";
import { AdminAuthContext } from "../../context/adminAuth";

import { useForm } from "../../util/hooks";

import MultiSelectTopicWSearchInputField from "./MultiSelectTopicWSearchInputField";

import {
  CREATE_CONTENT_MUTATION,
  FETCH_CONTENT,
  FETCH_RECOMMENDED_TOPIC_TITLES,
} from "../../util/gqlFunctions";
import SubmitButton from "./SubmitButton";
import TextInputField from "./TextInputField";
import VideoInputField from "./VideoInputField";

function MakeContentForm({
  setShouldOpenMakeContentForm,
  triviaNightId,
  targetTopic,
  toggleContentAddedSuccessfullyForm,
}) {
  const [errors, setErrors] = useState({});
  const { admin } = useContext(AdminAuthContext);

  const { values, setValues, onChangeMedia, onChange } = useForm(
    createContentCallback,
    {
      topicNames: targetTopic ? [targetTopic.name] : [],
      videoFile: null,
      typeOfVideo: 0,
      title: "",
      searchTerm: "",
      youtubeClipUrl: "",
      tiktokClipUrl: "",
      instagramClipUrl: "",
    }
  );

  const [createContent, { loading: loadingCreateContent }] = useMutation(
    CREATE_CONTENT_MUTATION,
    {
      refetchQueries: admin
        ? [
            {
              query: FETCH_CONTENT,
              variables: { pageSize: 10 },
            },
          ]
        : [],
      update(_, { data: { createContent: createQ } }) {
        setValues({
          ...values,
          title: "",
          topicNames: targetTopic ? [targetTopic.name] : [],
          videoFile: null,
        });
        setShouldOpenMakeContentForm(false);
        if (!admin) {
          toggleContentAddedSuccessfullyForm();
        }
        setErrors({});
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: admin ? adminClient : customerClient,
    }
  );

  function createContentCallback(e) {
    e.preventDefault();
    e.stopPropagation();
    createContent();
  }

  const { data: { getRecommendedTopicTitles: topicNames } = {} } = useQuery(
    FETCH_RECOMMENDED_TOPIC_TITLES,
    {
      client: admin ? adminClient : customerClient,
      variables: values,
      fetchPolicy: "network-only",
    }
  );

  const [previewVideoURL, setPreviewVideoURL] = useState("");
  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setShouldOpenMakeContentForm(false);
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setShouldOpenMakeContentForm(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          Upload a game clip
        </p>
        <p className="text-gray-500 w-full leading-tight ">
          A 30-60s demo video of the game
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-2 sm:space-y-4 mt-6 mb-4">
          {/* <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="topic" className="font-semibold text-gray-700 ">
                Topic(s){" "}
                {errors.topic && (
                  <span className="text-red-500">{errors.topic}</span>
                )}
              </label>
            </div>
            <select
              id="topic"
              name="topic"
              value={values.topic}
              onChange={onChange}
              disabled={loadingCreateContent}
              className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              {topics.map((topicName, index) => (
                <option key={index} value={topicName}>
                  {topicName}
                </option>
              ))}
            </select>
          </div> */}

          <div className="grid grid-cols-2 gap-2 sm:gap-4 w-full">
            <div className="flex items-start justify-start w-full flex-col">
              <div className="flex w-full items-center justify-between text-sm sm:text-base">
                <label
                  htmlFor={"videoFile"}
                  className="font-semibold text-gray-700 "
                >
                  Video{" "}
                  {errors.videoFile && (
                    <span className="text-red-500">{errors.videoFile}</span>
                  )}
                </label>
              </div>
              <div
                className={`w-full ${
                  values.typeOfVideo === 1 ? "aspect-[22/19]" : "aspect-[9/16]"
                } h-full overflow-hidden `}
              >
                <VideoInputField
                  name="videoFile"
                  onChangeMedia={onChangeMedia}
                  error={errors.videoFile}
                  disabled={loadingCreateContent}
                  previewVideoURL={previewVideoURL}
                  setPreviewVideoURL={setPreviewVideoURL}
                />
              </div>
            </div>
            <div className="flex items-start space-y-6 flex-col justify-start w-full">
              <MultiSelectTopicWSearchInputField
                error={errors.topic}
                name={"topicNames"}
                values={values}
                setValues={setValues}
                options={topicNames}
                loading={loadingCreateContent}
                title={"Topics (optional)"}
                value={values.topicNames}
              />

              <div className="w-full">
                <div className="flex items-center justify-between text-sm sm:text-base">
                  <label
                    htmlFor="typeOfVideo"
                    className="font-semibold text-gray-700 "
                  >
                    Aspect Ratio{" "}
                    {errors.typeOfVideo && (
                      <span className="text-red-500">{errors.typeOfVideo}</span>
                    )}
                  </label>
                </div>
                <select
                  id="typeOfVideo"
                  name="typeOfVideo"
                  // autoComplete=""
                  value={values.typeOfVideo}
                  onChange={(e) => {
                    onChange(e, true);
                  }}
                  disabled={loadingCreateContent}
                  className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value={0}>Vertical (1080:1920)</option>
                  <option value={1}>Wide (1080:934)</option>
                </select>
              </div>
            </div>
          </div>

          <TextInputField
            title={"Title"}
            name="title"
            type="text"
            error={errors.title}
            disabled={loadingCreateContent}
            onChange={onChange}
            maxLen={20}
            value={values.title}
          />
          <TextInputField
            title="YouTube link (optional)"
            name="youtubeClipUrl"
            type={"url"}
            error={errors.youtubeClipUrl}
            disabled={loadingCreateContent}
            onChange={onChange}
            value={values.youtubeClipUrl}
          />
          <TextInputField
            title="TikTok link (optional)"
            name="tiktokClipUrl"
            type={"url"}
            error={errors.tiktokClipUrl}
            disabled={loadingCreateContent}
            onChange={onChange}
            value={values.tiktokClipUrl}
          />
          <TextInputField
            title="Instagram link (optional)"
            name="instagramClipUrl"
            type={"url"}
            error={errors.instagramClipUrl}
            disabled={loadingCreateContent}
            onChange={onChange}
            value={values.instagramClipUrl}
          />
        </div>

        <SubmitButton
          onClickFunc={createContentCallback}
          disabled={loadingCreateContent}
          title={"Create game clip"}
          errors={errors}
        />
      </form>
    </div>
  );
}

export default MakeContentForm;
