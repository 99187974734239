import moment from "moment";
import React, { useEffect, useState } from "react";

function AnswerBubble({
  answer,
  offset,
  lastQStartedAt,
  pageLoadedAt,
  shouldShowBubbleInit,
}) {
  const [startX] = useState(Math.random() * offset);
  const [timeDifferenceInSeconds, setTimeDifferenceInSeconds] = useState(0);
  const [shouldShowBubble, setShouldShowBubble] =
    useState(shouldShowBubbleInit);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    let interval;

    if (!shouldShowBubble) {
      interval = setInterval(() => {
        const now = moment();
        var diffInMills = now.diff(moment(lastQStartedAt), "milliseconds");

        if (diffInMills > 1000 * 10) {
          diffInMills = now.diff(moment(pageLoadedAt), "milliseconds");

          if (diffInMills >= answer.timeTaken / 3) {
            setShouldShowBubble(true);
          }
        } else if (diffInMills >= answer.timeTaken) {
          setShouldShowBubble(true);
        }
        setTimeDifferenceInSeconds(diffInMills);
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    lastQStartedAt,
    answer.timeTaken,
    timeDifferenceInSeconds,
    pageLoadedAt,
    shouldShowBubble,
  ]);

  return shouldShowBubble ? (
    <div
      key={answer.id}
      className={
        !isImageLoaded
          ? "hidden"
          : ` ${
              answer.username === "humpty" || answer.username === "guest4103416"
                ? "bg-blue-300"
                : // : answer.subscriptionType === 0
                  // ? "bg-yellow-300"
                  // : answer.subscriptionType === 1
                  // ? "bg-red-400"
                  "bg-gray-100/50"
            } flex-shrink-0 motion-safe:animate-flyUpFast absolute bottom-0 transition-all -mb-0 sm:-mb-14 overflow-hidden p-1 2xl:p-1.5 rounded-full aspect-square w-10 2xl:w-14 short:w-8 flex`
      }
      style={{
        left: `${startX}%`, // Randomly position the bubbles
      }}
      // hover:bg-blue-700
    >
      <img
        src={answer.profilePicUrl}
        alt={answer.username}
        onLoad={() => setIsImageLoaded(true)}
        className="border-white bg-white short:border-[1.5px] border-2 box-border rounded-full object-cover aspect-square"
      />
    </div>
  ) : (
    <></>
  );
}

export default AnswerBubble;
