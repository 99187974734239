import { useMutation, useQuery } from "@apollo/client";

import React, { useContext, useEffect, useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";

import { defaultPicUrl, useForm } from "../../util/hooks";
import SubmitButton from "./SubmitButton";

import { CustomerAuthContext } from "../../context/customerAuth";

import {
  CREATE_OR_JOIN_QUIZ_MUTATION,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_FRIENDS,
  FETCH_GUEST_CUSTOMER,
  FETCH_RECOMMENDED_FRIENDS,
  FETCH_RECOMMENDED_ORG_SECTIONS,
  FETCH_RECOMMENDED_ORGS,
} from "../../util/gqlFunctions";

import TopicIcon from "./TopicIcon";

function CreateBattleModal({
  topic,

  navigate,
  targetCustomer,

  lastQuizId,
  loginGuestContext,
  shouldOpenQuiz,
  setShouldOpenQuiz,
  toggleBuySubscriptionForm,

  setSubPopupReason,

  toggleOneTopicBattleForm,
}) {
  const [errors, setErrors] = useState({});
  const { friendIds, onFriendIdsChange } = useContext(CustomerAuthContext);
  const { values, setValues } = useForm(createOrJoinQuizCallback, {
    topicIds: [topic.id],
    gameTimingType: 0,

    country: "",
    lastQuizId,
    friendIds: friendIds || [],
    tempFriendIds: friendIds || [],
    shouldShareLinkInChat: lastQuizId ? true : false,
    isMultiTopic: false,
    // timezone: "",
  });

  const [createOrJoinQuiz, { loading: loadingCreateOrJoinQuiz }] = useMutation(
    CREATE_OR_JOIN_QUIZ_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_RECOMMENDED_ORGS },
        { query: FETCH_RECOMMENDED_ORG_SECTIONS },
        { query: FETCH_RECOMMENDED_FRIENDS, variables: { values } },
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST_CUSTOMER },
      ],
      update(_, { data: { createOrJoinQuiz: createdQuiz } }) {
        setValues({
          ...values,
          ...createdQuiz,
        });

        if (createdQuiz.token) {
          loginGuestContext({ token: createdQuiz.token });
        }

        setErrors({});

        if (shouldOpenQuiz) {
          setShouldOpenQuiz(false);
        }
        if (!lastQuizId || !createdQuiz.sharedLinkOnCreation) {
          navigate("/battle/" + createdQuiz.id);
        } else {
          toggleOneTopicBattleForm(topic?.id);
        }
      },
      onError(err) {
        console.log(err);
        console.log(values);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          if (!targetCustomer) {
            navigate("/register");
          } else if (
            err.graphQLErrors[0].extensions.errors &&
            err.graphQLErrors[0].extensions.errors.quizCreation
          ) {
            setSubPopupReason(
              err.graphQLErrors[0].extensions.errors.quizCreation
            );

            toggleBuySubscriptionForm(0);
          }
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: { ...values, friendIds: values.tempFriendIds },
      client: targetCustomer ? customerClient : guestClient,
    }
  );

  function createOrJoinQuizCallback(e) {
    e.preventDefault();
    createOrJoinQuiz();
  }

  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const [isInviteCopied, setIsInviteCopied] = useState(null);

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Play TKnight!\n\n";

    textToShare += "It's the most fun trivia game ever!!\n\n";
    if (targetCustomer) {
      textToShare += "Extra ❤️ Code: " + targetCustomer.username + "\n\n";
    }

    textToShare += "https://tknight.live";

    if (navigator.share) {
      navigator
        .share({
          title: "Play TKnight!",
          text: textToShare,
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);
          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  useEffect(() => {
    let timeout;
    if (isInviteCopied) {
      timeout = setTimeout(() => setIsInviteCopied(false), 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied]);

  const { data: { getCustomerFriends: customerFriends } = {} } = useQuery(
    FETCH_CUSTOMER_FRIENDS,
    {
      client: customerClient,
      variables: { selectedFriendIds: values.friendIds },
      // fetchPolicy: 'cache-and-network',
    }
  );

  const [isFocusChecked, setIsFocusChecked] = useState(false);

  useEffect(() => {
    if (!isFocusChecked) {
      setValues({ ...values, tempFriendIds: friendIds, friendIds });
      setIsFocusChecked(true);
    }
  }, [friendIds, values, setValues, isFocusChecked]);
  // console.log(friendIds);
  return (
    <div className="fixed pt-16 sm:py-10 z-[60] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          toggleOneTopicBattleForm(topic?.id);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden relative max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6  bg-white shadow-xl rounded-b-none sm:rounded-3xl short:px-4 sm:p-8 sm:pb-5`}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            toggleOneTopicBattleForm(topic.id);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>
        <div
          className={`w-full flex z-40 space-x-4 px-1 sm:px-0 items-center justify-center`}
        >
          <div className="w-1/5 flex items-center justify-start space-x-2">
            <div
              className={`text-gray-700 flex items-center justify-start space-x-1.5 sm:space-x-3`}
            >
              <svg
                className="h-4 fill-current 2xl:h-6 short:h-3"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.5 4.5C4.5 2.01375 6.51375 0 9 0C11.4862 0 13.5 2.01375 13.5 4.5C13.5 6.98625 11.4862 9 9 9C6.51375 9 4.5 6.98625 4.5 4.5ZM0 14.625C0 11.6325 5.99625 10.125 9 10.125C12.0037 10.125 18 11.6325 18 14.625V18H0V14.625Z"
                />
              </svg>
              <p className="text-sm short:text-xs 2xl:text-xl font-semibold">
                {values.tempFriendIds.length + 1}
              </p>
            </div>
          </div>

          <div className="flex-1 items-center justify-center flex">
            <TopicIcon navigate={navigate} topicId={topic.id} height={"h-10"} />
          </div>
          <div className="w-1/5 flex items-center justify-end space-x-2">
            <div
              className={`text-gray-700 flex items-center justify-end space-x-1.5 transition-all sm:space-x-3 `}
            >
              <svg
                className="h-5 fill-current 2xl:h-6 short:h-3.5"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
                  fill="#F4900C"
                />
                <path
                  d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
                  fill="#FFCC4D"
                />
                <path
                  d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
                  fill="#FFE8B6"
                />
                <path
                  d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
                  fill="#FFAC33"
                />
                <path
                  d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
                  fill="#FFD983"
                />
              </svg>
              <p className="text-sm 2xl:text-lg short:text-xs font-semibold">
                {topic.minimumBet}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-start justify-start mt-5">
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="gameTimingType"
                className="font-semibold text-gray-700 "
              >
                Players{" "}
                {errors.betAmount && (
                  <span className="text-red-500">{errors.betAmount}</span>
                )}
              </label>
            </div>
            <div className="flex mt-1 overflow-x-auto items-start justify-start space-x-3 mb-2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/addFriends/topic/" + topic?.id);
                }}
                className={`py-2 group text-center text-base shrink-0 rounded-lg space-x-1 space-y-2 transition-all flex flex-col items-center justify-start`}
              >
                <div
                  className={`border-4 w-14 overflow-hidden border-blue-700 bg-blue-50 rounded-full transition-all`}
                >
                  <img
                    className="w-full object-cover aspect-square fill-current text-gray-500"
                    src={targetCustomer?.profilePicUrl || defaultPicUrl}
                    alt="You"
                  />
                </div>
                <p className=" leading-snug">You</p>
              </button>
              {customerFriends &&
                customerFriends.length > 0 &&
                customerFriends.map((friend, index) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      let list = [...values.tempFriendIds];
                      if (!list.includes(friend.id)) {
                        list.push(friend.id);
                      } else {
                        list = list.filter((id) => id !== friend.id);
                      }
                      setValues({ ...values, tempFriendIds: list });

                      onFriendIdsChange(friend.id);
                    }}
                    key={friend.id}
                    className={`py-2 group text-center text-base shrink-0 rounded-lg space-x-1 space-y-2 transition-all flex flex-col items-center justify-start`}
                  >
                    <div
                      className={` w-14 overflow-hidden ${
                        values.tempFriendIds.includes(friend.id)
                          ? "border-blue-700 bg-blue-50 border-4"
                          : "border-gray-300 border-2 group-hover:border-4 group-hover:border-gray-300 group-hover:bg-gray-50"
                      } bg-gray-50 rounded-full transition-all`}
                    >
                      <img
                        className="w-full object-cover aspect-square fill-current text-gray-500"
                        src={friend?.profilePicUrl || defaultPicUrl}
                        alt="You"
                      />
                    </div>
                    <p className="w-14 truncate leading-snug">
                      {friend?.name || friend?.username || "Friend"}
                    </p>
                  </button>
                ))}
              <button
                onClick={(e) => {
                  // e.preventDefault();
                  // console.log(targetCustomer);
                  if (!targetCustomer) {
                    navigate("/register");
                  } else {
                    navigate("/addFriends/topic/" + topic?.id);
                  }
                }}
                className={`py-2 group text-center text-base shrink-0 rounded-lg space-x-1 space-y-2 transition-all flex flex-col items-center justify-start`}
              >
                <div
                  className={`border-2 border-gray-300 group-hover:border-gray-400 group-hover:bg-gray-50 rounded-full p-3.5 transition-all`}
                >
                  <svg
                    className="w-5 aspect-square fill-current text-gray-500"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.9649 11.255H12.7549L17.7449 16.255L16.2549 17.745L11.2549 12.755V11.965L10.9849 11.685C9.84488 12.665 8.36488 13.255 6.75488 13.255C3.16488 13.255 0.254883 10.345 0.254883 6.755C0.254883 3.165 3.16488 0.255005 6.75488 0.255005C10.3449 0.255005 13.2549 3.165 13.2549 6.755C13.2549 8.365 12.6649 9.845 11.6849 10.985L11.9649 11.255ZM2.25488 6.755C2.25488 9.245 4.26488 11.255 6.75488 11.255C9.24488 11.255 11.2549 9.245 11.2549 6.755C11.2549 4.26501 9.24488 2.255 6.75488 2.255C4.26488 2.255 2.25488 4.26501 2.25488 6.755Z"
                    />
                  </svg>
                </div>
                <p className=" leading-snug">Search</p>
              </button>
              <button
                onClick={inviteFriends}
                className={`py-2 group text-center text-base shrink-0 rounded-lg space-x-1 space-y-2 transition-all flex flex-col items-center justify-start`}
              >
                <div
                  className={`border-2 border-gray-300 group-hover:border-gray-400 group-hover:bg-gray-50 rounded-full p-3.5 transition-all`}
                >
                  <svg
                    className="w-5 aspect-square fill-current text-gray-500"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_8661_576)">
                      <path d="M15 14.1365C14.24 14.1365 13.56 14.4378 13.04 14.9096L5.91 10.743C5.96 10.512 6 10.2811 6 10.0402C6 9.7992 5.96 9.56827 5.91 9.33735L12.96 5.21084C13.5 5.71285 14.21 6.0241 15 6.0241C16.66 6.0241 18 4.67871 18 3.01205C18 1.34538 16.66 0 15 0C13.34 0 12 1.34538 12 3.01205C12 3.25301 12.04 3.48394 12.09 3.71486L5.04 7.84137C4.5 7.33936 3.79 7.02811 3 7.02811C1.34 7.02811 0 8.37349 0 10.0402C0 11.7068 1.34 13.0522 3 13.0522C3.79 13.0522 4.5 12.741 5.04 12.239L12.16 16.4157C12.11 16.6265 12.08 16.8474 12.08 17.0683C12.08 18.6847 13.39 20 15 20C16.61 20 17.92 18.6847 17.92 17.0683C17.92 15.4518 16.61 14.1365 15 14.1365Z"></path>
                    </g>
                  </svg>
                </div>
                <p className="whitespace-pre-wrap leading-snug">
                  {isSharingToApps
                    ? "Shared!"
                    : isInviteCopied
                    ? "Copied!"
                    : "Invite"}
                </p>
              </button>
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="gameTimingType"
                className="font-semibold text-gray-700 "
              >
                Timer{" "}
                {errors.gameTimingType && (
                  <span className="text-red-500">{errors.gameTimingType}</span>
                )}
              </label>
            </div>
            <div className="flex mt-1 overflow-x-auto items-center justify-start space-x-3 mb-3">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setValues({ ...values, gameTimingType: 0 });
                }}
                className={`py-2 group text-center text-base shrink-0 rounded-lg space-x-1 space-y-2 transition-all flex flex-col items-center justify-start`}
              >
                <div
                  className={`border-2 ${
                    values.gameTimingType === 0
                      ? "border-blue-700 bg-blue-50"
                      : "border-gray-300 group-hover:border-gray-400 group-hover:bg-gray-50"
                  } rounded-full p-3 transition-all`}
                >
                  <svg
                    className="w-6 aspect-square fill-current text-gray-500"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.91 11.34L16.546 7.314L16.092 6.908L12.419 10.007C11.744 9.869 11.017 10.075 10.525 10.625C9.789 11.448 9.86 12.713 10.684 13.449C11.508 14.185 12.772 14.114 13.508 13.29C14 12.74 14.123 11.995 13.91 11.34ZM2 7H8V9H2V7ZM0 11H6V13H0V11ZM3 15H8V17H3V15ZM22 12C22 17.523 17.523 22 12 22C9.21 22 6.7 20.845 4.889 19H8.169C9.307 19.631 10.608 20 12 20C16.411 20 20 16.411 20 12C20 7.589 16.411 4 12 4C10.608 4 9.307 4.369 8.169 5H4.889C6.7 3.155 9.21 2 12 2C17.523 2 22 6.477 22 12Z" />
                  </svg>
                </div>
                <p className=" leading-snug">Live</p>
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setValues({ ...values, gameTimingType: 1 });
                }}
                className={`py-2 group text-center text-base shrink-0 rounded-lg space-x-1 space-y-2 transition-all flex flex-col items-center justify-start`}
              >
                <div
                  className={`border-2 ${
                    values.gameTimingType === 1
                      ? "border-blue-700 bg-blue-50"
                      : "border-gray-300 group-hover:border-gray-400 group-hover:bg-gray-50"
                  } rounded-full p-3 transition-all`}
                >
                  <svg
                    className="w-6 aspect-square fill-current text-gray-500"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.975 2.05v2.02c-1.46.18-2.79.76-3.9 1.62l-1.42-1.43a9.949 9.949 0 015.32-2.21zm2.05 7.74L9.975 7.5v9l3.05-2.29 2.95-2.21-2.95-2.21zM5.665 7.1l-1.43-1.42A9.949 9.949 0 002.025 11h2.02c.18-1.46.76-2.79 1.62-3.9zM2.025 13h2.02c.18 1.46.76 2.79 1.62 3.89l-1.43 1.43A9.949 9.949 0 012.025 13zm3.63 6.74a9.981 9.981 0 005.32 2.21v-2.02a7.941 7.941 0 01-3.9-1.62l-1.42 1.43zm7.37 2.21c5.03-.53 8.95-4.79 8.95-9.95 0-5.16-3.92-9.42-8.95-9.95v2.02c3.92.52 6.95 3.88 6.95 7.93s-3.03 7.41-6.95 7.93v2.02z"
                    />
                  </svg>
                </div>
                <p className=" leading-snug">Async</p>
              </button>
            </div>
          </div>

          <SubmitButton
            onClickFunc={createOrJoinQuizCallback}
            disabled={loadingCreateOrJoinQuiz}
            title={"Create Battle"}
            errors={errors}
          />

          <p className="text-center leading-tight w-full text-gray-600 mt-4 sm:mb-0 mb-2 text-base">
            You can always invite more players later.
          </p>
        </div>
      </form>
    </div>
  );
}

export default CreateBattleModal;
