import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { CustomerAuthContext } from "../../context/customerAuth";
import { customerClient } from "../../GraphqlApolloClients";
import {
  ACTIVE_NOTIFICATION_QUERY,
  FETCH_CUSTOMER,
  FETCH_NUM_UNCLICKED_NOTIFICATIONS,
  FETCH_QUIZ_BY_ID,
  REJECT_FRIEND_REQUEST_MUTATION,
  TOGGLE_ADD_FRIEND_TO_QUIZ_MUTATION,
  TOGGLE_FRIEND_MUTATION,
} from "../../util/gqlFunctions";

export default function PlayerSummaryBox({
  player,
  targetCustomer,
  isForCreateBattle,
  isForBattle,
  targetBattle,
}) {
  const { friendIds, onFriendIdsChange } = useContext(CustomerAuthContext);

  const [isFriendAdded, setIsFriendAdded] = useState(
    targetBattle
      ? targetBattle.friendIds.includes(player.id)
        ? true
        : false
      : targetCustomer.followingIds.includes(player.id)
  );

  const [toggleFriend, { loading: loadingToggleFriend }] = useMutation(
    TOGGLE_FRIEND_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_NUM_UNCLICKED_NOTIFICATIONS },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 0 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 1 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 2 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 3 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 5 } },
      ],
      update(_, { data: { toggleFriend: toggledFriend } }) {},
      onError(err) {
        setIsFriendAdded(!isFriendAdded);

        console.log(err);
      },

      client: customerClient,
    }
  );

  const [rejectFriendRequest, { loading: loadingRejectFriendRequest }] =
    useMutation(REJECT_FRIEND_REQUEST_MUTATION, {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_NUM_UNCLICKED_NOTIFICATIONS },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 0 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 1 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 2 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 3 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 5 } },
      ],
      update(_, { data: { rejectFriendRequest: rejectedFriend } }) {
        setIsFriendAdded(false);
      },
      onError(err) {
        console.log(err);
      },

      client: customerClient,
    });

  const [toggleAddFriendToQuiz] = useMutation(
    TOGGLE_ADD_FRIEND_TO_QUIZ_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        {
          query: FETCH_QUIZ_BY_ID,
          variables: { quizId: targetBattle?.id || "" },
        },
        { query: FETCH_NUM_UNCLICKED_NOTIFICATIONS },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 0 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 1 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 2 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 3 } },
        { query: ACTIVE_NOTIFICATION_QUERY, variables: { type: 5 } },
      ],
      update(_, { data }) {},
      onError(err) {
        setIsFriendAdded(!isFriendAdded);

        console.log(err);
      },
      variables: { quizId: targetBattle?.id, friendId: player?.id },
      client: customerClient,
    }
  );

  return (
    <div
      disabled={loadingToggleFriend}
      className="flex shrink-0 text-left group space-x-2.5 sm:space-x-3 overflow-hidden sm:hover:bg-white/20 transition-all rounded-xl sm:hover:shadow-sm items-center w-full justify-start sm:px-4 py-2"
    >
      <div className={`overflow-hidden shrink-0 shadow relative rounded-full`}>
        <img
          src={player.profilePicUrl}
          alt={player.name}
          className={`${
            isForBattle
              ? isFriendAdded
                ? "border-white"
                : "border-gray-400"
              : isForCreateBattle
              ? friendIds.includes(player.id) || targetCustomer.id === player.id
                ? "border-white"
                : "border-gray-400"
              : targetCustomer &&
                targetCustomer.followingIds &&
                targetCustomer.followingIds.includes(player.id)
              ? "border-white"
              : "border-gray-400"
          } h-10 bg-white border-4 box-border rounded-full object-cover aspect-square`}
        />
      </div>
      <div className="flex w-full truncate space-y-1 flex-col items-start justify-center">
        <p className="leading-none text-white sm:leading-none sm:text-lg short:text-sm text-base short:leading-none">
          {player?.id === targetCustomer?.id
            ? "You"
            : player?.name || player?.username || ""}
        </p>
        <div className="flex font-semibold items-center justify-start">
          <p className=" text-white text-sm short:text-xs sm:text-base short:leading-tight leading-tight">
            @{player.username}
            {/* Active {moment(player.lastActiveAt).fromNow()} */}
          </p>
        </div>
      </div>

      {isForCreateBattle || isForBattle ? (
        <button
          className={`${
            (isForBattle && isFriendAdded) ||
            (isForCreateBattle &&
              (friendIds.includes(player.id) ||
                player.id === targetCustomer.id))
              ? "bg-green-600 text-white hover:bg-green-800 bg-opacity-90"
              : "bg-white text-black hover:bg-opacity-50 bg-opacity-90"
          } px-4 h-10 short:text-sm transition-all rounded-full font-bold text-center whitespace-nowrap py-2 `}
          onClick={(e) => {
            e.preventDefault();

            if (isForBattle) {
              setIsFriendAdded(!isFriendAdded);

              toggleAddFriendToQuiz();
            } else if (player.id !== targetCustomer?.id) {
              onFriendIdsChange(player.id);
            }
          }}
        >
          {(isForBattle && isFriendAdded) ||
          (isForCreateBattle &&
            (friendIds.includes(player.id) || player.id === targetCustomer.id))
            ? !isForCreateBattle
              ? "Invited"
              : "Added"
            : !isForCreateBattle
            ? "Invite"
            : "Add"}
        </button>
      ) : (
        <>
          {!isFriendAdded &&
          !targetCustomer.rejectedCustomerIds.includes(player.id) &&
          player.followingIds.includes(targetCustomer.id) ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                console.log("dddd");
                rejectFriendRequest({
                  variables: { username: player.username },
                });
              }}
              className="bg-red-600 rounded-full shadow px-2 h-9 aspect-square items-center justify-center flex py-2 ml-2"
              disabled={loadingRejectFriendRequest}
            >
              {loadingRejectFriendRequest ? (
                <svg
                  className={`h-4 m-auto stroke-current text-white motion-safe:animate-spin`}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.636 3.636A9 9 0 0119 10"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                <svg
                  className="h-3 aspect-square fill-current text-gray-100"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                </svg>
              )}
            </button>
          ) : (
            <></>
          )}
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsFriendAdded(!isFriendAdded);
              toggleFriend({ variables: { username: player.username } });
            }}
            className={`${
              isFriendAdded
                ? player.followingIds.includes(targetCustomer.id)
                  ? "bg-green-600 text-white hover:bg-green-800 bg-opacity-90"
                  : "bg-purple-600 text-white  hover:bg-purple-800 bg-opacity-90"
                : !targetCustomer.rejectedCustomerIds.includes(player.id) &&
                  player.followingIds.includes(targetCustomer.id)
                ? "bg-blue-600 text-white hover:bg-blue-900 bg-opacity-90"
                : "bg-white text-black hover:bg-opacity-50 bg-opacity-90"
            } shrink-0 px-4 h-10 short:text-sm transition-all rounded-full group-hover:bg-opacity-50 font-bold text-center  whitespace-nowrap py-2 `}
          >
            {loadingToggleFriend ? (
              <svg
                className={`h-4 m-auto stroke-current text-white motion-safe:animate-spin`}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.636 3.636A9 9 0 0119 10"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            ) : isFriendAdded ? (
              player.followingIds.includes(targetCustomer.id) ? (
                "Added"
              ) : (
                "Requested"
              )
            ) : !targetCustomer.rejectedCustomerIds.includes(player.id) &&
              player.followingIds.includes(targetCustomer.id) ? (
              "Accept"
            ) : (
              "Add"
            )}
          </button>
        </>
      )}
    </div>
  );
}
