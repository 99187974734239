import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";
import { formatViewCount } from "../../util/hooks";
import BattleLeaderPodium from "./BattleLeaderPodium";
import BattleLeaderboardTable from "./BattleLeaderboardTable";

import { FETCH_TOPIC, FETCH_TRIVIA_VIEW_COUNT } from "../../util/gqlFunctions";

const countryFlags = {
  All: "🌍",
  Australia: "🇦🇺",
  Austria: "🇦🇹",
  Belgium: "🇧🇪",
  Brazil: "🇧🇷",
  Bulgaria: "🇧🇬",
  Canada: "🇨🇦",
  Chile: "🇨🇱",
  Croatia: "🇭🇷",
  Cyprus: "🇨🇾",
  "Czech Republic": "🇨🇿",
  Denmark: "🇩🇰",
  Estonia: "🇪🇪",
  Finland: "🇫🇮",
  France: "🇫🇷",
  Germany: "🇩🇪",
  Greece: "🇬🇷",
  China: "🇨🇳",
  Hungary: "🇭🇺",
  India: "🇮🇳",
  Indonesia: "🇮🇩",
  Ireland: "🇮🇪",
  Italy: "🇮🇹",
  Japan: "🇯🇵",
  Latvia: "🇱🇻",
  Lithuania: "🇱🇹",
  Luxembourg: "🇱🇺",
  Malaysia: "🇲🇾",
  Malta: "🇲🇹",
  Mexico: "🇲🇽",
  Netherlands: "🇳🇱",
  "New Zealand": "🇳🇿",
  Norway: "🇳🇴",
  Pakistan: "🇵🇰",
  Philippines: "🇵🇭",
  Poland: "🇵🇱",
  Portugal: "🇵🇹",
  Romania: "🇷🇴",
  Serbia: "🇷🇸",
  Singapore: "🇸🇬",
  Slovakia: "🇸🇰",
  Slovenia: "🇸🇮",
  Spain: "🇪🇸",
  Sweden: "🇸🇪",
  Switzerland: "🇨🇭",
  Thailand: "🇹🇭",
  "United Arab Emirates": "🇦🇪",
  "United Kingdom": "🇬🇧",
  "United States": "🇺🇸",
};

export default function BattleLeaderboard({
  customer,
  quiz,
  allPlayerBattleStats,
  logTriviaShare,
  playerTopicStats,
  setShouldShowBattleLeaderboard,
  triviaNight,
  triviaWin,

  bailOut,
  guest,
  targetGuestCustomer,
  targetCustomer,
}) {
  const [isCaptionCopied, setIsCaptionCopied] = useState(false);
  const [isSharingLeaderboardToApps, setIsSharingToApps] = useState(false);
  const [hasCaptionBeenCopiedBefore, setHasCaptionBeenCopiedBefore] =
    useState(false);

  const resetInterval = 1000;
  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);

  const { data: { getTopicById: targetTopic } = {} } = useQuery(FETCH_TOPIC, {
    variables: { topicId: quiz ? quiz.topicIds[0] : "" },
    client: customer ? customerClient : guestClient,
  });

  const {
    data: { getTriviaViewCount: triviaViewCount } = {},
    // loading: loadingTriviaViewCount,
  } = useQuery(FETCH_TRIVIA_VIEW_COUNT, {
    client: customer ? customerClient : guestClient,
    pollInterval: 10000,
    variables: {
      quizId: quiz ? quiz.id : null,
      triviaNightId: triviaNight ? triviaNight.id : null,
      status: 1,
    },
  });

  useEffect(() => {
    const setColumnWidths = () => {
      const tbody = document.querySelector("table > tbody");
      if (!tbody) {
        return;
      }
      const tbodyRows = tbody.querySelectorAll("tr");
      if (!tbodyRows || tbodyRows.length === 0) {
        return;
      }
      const tbodyColumns = tbodyRows[0].querySelectorAll("td");
      if (!tbodyColumns || tbodyColumns.length === 0) {
        return;
      }
      const theadColumns = document.querySelectorAll("table > thead > tr > th");
      if (!theadColumns || theadColumns.length === 0) {
        return;
      }

      theadColumns.forEach((col, i) => {
        const width = window.getComputedStyle(col).getPropertyValue("width");
        tbodyColumns[i].setAttribute("style", `width: ${width}`);
      });
    };

    // if (rankedCountries && rankedCountries.length > 0) {
    setColumnWidths();
    window.addEventListener("resize", setColumnWidths);
    // }
    return () => {
      window.removeEventListener("resize", setColumnWidths);
    };
  }, [allPlayerBattleStats]);

  const logTriviaShareEvent = async (type) => {
    logTriviaShare({
      variables: {
        quizId: quiz ? quiz.id : null,
        triviaNightId: triviaNight ? triviaNight.id : null,
        type,
      },
    });
  };

  const shareTNLeaderboard = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = null;
    var concatTopicNames = (quiz || triviaNight).topicNames.join(", ");
    var prefix = triviaNight ? "#TKL ⚔️ " : "#TKnight ⚔️ ";

    if (triviaWin) {
      textToShare = prefix + concatTopicNames + "\n";
      // +
      // (quiz.prizePerPlayer / 100).toFixed(2) +
      // " on
    } else if (bailOut) {
      textToShare = prefix + concatTopicNames + "\n\n";
    } else {
      textToShare = prefix + concatTopicNames + "\n\n";
    }

    if (allPlayerBattleStats && allPlayerBattleStats.length > 0) {
      textToShare += allPlayerBattleStats[1]
        ? countryFlags[
            allPlayerBattleStats[1].cCountry ||
              allPlayerBattleStats[1].gCountry ||
              "All"
          ] + "   "
        : "         ";
      textToShare += allPlayerBattleStats[0]
        ? countryFlags[
            allPlayerBattleStats[0].cCountry ||
              allPlayerBattleStats[0].gCountry ||
              "All"
          ] + "   "
        : "            ";
      textToShare += allPlayerBattleStats[2]
        ? countryFlags[
            allPlayerBattleStats[2].cCountry ||
              allPlayerBattleStats[2].gCountry ||
              "All"
          ] + "   "
        : "";
      textToShare += "\n";
      // if (allPlayerBattleStats[1]) {
      textToShare += "🥈   ";
      // }
      // if (allPlayerBattleStats[0]) {
      textToShare += "🥇   ";
      // }
      // if (allPlayerBattleStats[2]) {
      textToShare += "🥉";
      // }
      textToShare += "\n";
      if (
        allPlayerBattleStats[1] &&
        ((customer && allPlayerBattleStats[1].customerId === customer.id) ||
          (guest && allPlayerBattleStats[1].guestCustomerId === guest.id))
      ) {
        textToShare += "🙌";
        textToShare += "\n";
      }
      if (
        allPlayerBattleStats[0] &&
        ((customer && allPlayerBattleStats[0].customerId === customer.id) ||
          (guest && allPlayerBattleStats[0].guestCustomerId === guest.id))
      ) {
        textToShare += "         🙌";
        textToShare += "\n";
      }
      if (
        allPlayerBattleStats[2] &&
        ((customer && allPlayerBattleStats[2].customerId === customer.id) ||
          (guest && allPlayerBattleStats[2].guestCustomerId === guest.id))
      ) {
        textToShare += "                 🙌";
        textToShare += "\n";
      }

      textToShare += "\n";
    }

    if (triviaNight && targetCustomer) {
      textToShare +=
        "🪙 " +
        (triviaWin
          ? "+" + triviaNight.prizePerPlayer
          : "-" + triviaNight.betAmountPerPlayer) +
        "  | ";

      textToShare += "🏆 " + targetCustomer.numOfTKLsWon + "  / ";
      textToShare += "🎯 " + targetCustomer.accuracy + "%";
    } else if ((targetCustomer || targetGuestCustomer) && quiz) {
      textToShare +=
        "🪙 " +
        (triviaWin
          ? "+" + quiz.prizePerPlayer
          : "-" + quiz.betAmountPerPlayer) +
        "  | ";
      textToShare += "⚡️ " + playerTopicStats.curFocusStreak + "  / ";
      textToShare += "🏆 " + playerTopicStats.numOfBattlesWon + "  / ";
      // textToShare +=
      //   "❤️ " +
      //   (playerTopicStats.availableExtraLivesCount +
      //   "  / ";
      textToShare += "🎯 " + playerTopicStats.accuracy + "%";
    } else if (quiz) {
      textToShare +=
        "🪙" +
        (triviaViewCount ? triviaViewCount * quiz.betAmountPerPlayer : 0) +
        " Prize\n\n";
      textToShare += "tknight.live/battle/" + quiz.id;
    }
    // textToShare += "You should play too: TKnight.live";

    if (navigator.share) {
      navigator
        .share({
          title: "My TKnight Summary!",
          text: textToShare,
          // url: "http://worldphraze.com/",
          // url:
          //   playersPhraze && playersPhraze.isEligibleForPrize
          //     ? "W/" + playersPhraze.id
          //     : "phraze",
        })
        .then(() => {
          setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);
          // navigator.clipboard.writeText(textToShare + " worldphraze.com");
          setIsCaptionCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }

    // if (quiz.status >= 5) {
    await logTriviaShareEvent(4);
    // } else if (quiz.status === 0) {
    //   await logTriviaShareEvent(0);
    // } else {
    //   await logTriviaShareEvent(1);
    // }
  };

  let numOfPlayers = triviaNight
    ? triviaNight.prizeCoins - 1000
    : quiz
    ? quiz.prizeCoins / quiz.betAmountPerPlayer
    : 0;

  return (
    <div className="fixed pt-16 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center short:pt-12">
      <button
        onClick={(e) => {
          e.preventDefault();

          setShouldShowBattleLeaderboard(false);
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden text-center max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-md overflow-y-auto flex flex-col items-center justify-start z-40 rounded-xl px-6 pb-6 sm:p-4 sm:pb-4 short:px-3 short:pb-4 bg-white shadow-xl rounded-b-none sm:rounded-3xl `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();

            setShouldShowBattleLeaderboard(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <div className="px-0 sm:px-4 w-full shrink-0">
          <div className="flex items-center justify-start px-1 sm:px-0 short:pb-0 pb-1 sm:pt-4 space-x-4 w-full">
            <div className="flex leading-tight w-1/5 truncate items-center justify-start text-gray-700">
              <svg
                className="h-4 fill-current 2xl:h-6 short:h-3"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.24404 3.7559C3.24404 1.96349 4.69581 0.511719 6.48822 0.511719C8.28064 0.511719 9.73241 1.96349 9.73241 3.7559C9.73241 5.54832 8.28064 7.00009 6.48822 7.00009C4.69581 7.00009 3.24404 5.54832 3.24404 3.7559ZM0 11.0554C0 8.898 4.32288 7.8112 6.48837 7.8112C8.65387 7.8112 12.9767 8.898 12.9767 11.0554V13.4885H0V11.0554Z"
                />
              </svg>
              <p className="short:ml-1 ml-2 text-sm short:text-xs 2xl:text-xl font-semibold">
                {numOfPlayers}
              </p>
            </div>

            <a
              className={`${
                triviaNight ? "text-emerald-600" : "text-blue-600"
              } ${
                quiz && quiz.topicIds.length > 1 ? "" : "hover:text-blue-800"
              } text-xl short:text-lg text-center flex-1 font-semibold leading-tight`}
              href={
                quiz && quiz.topicIds.length > 1
                  ? null
                  : targetTopic
                  ? `/topic/${targetTopic.shortForm}`
                  : "/tkl"
              }
            >
              {/* Leaderboard */}
              {quiz && quiz.topicIds.length > 1
                ? "Leaderboard"
                : targetTopic
                ? targetTopic.name
                : triviaNight
                ? "TKnight LIVE"
                : ""}
            </a>
            {/* </div> */}
            <div className="flex leading-tight items-center justify-end text-right w-1/5 text-gray-700">
              <svg
                className="h-5 fill-current 2xl:h-6 short:h-3.5"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
                  fill="#F4900C"
                />
                <path
                  d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
                  fill="#FFCC4D"
                />
                <path
                  d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
                  fill="#FFE8B6"
                />
                <path
                  d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
                  fill="#FFAC33"
                />
                <path
                  d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
                  fill="#FFD983"
                />
              </svg>
              <p className="short:ml-1 ml-1.5 text-sm short:text-xs 2xl:text-xl font-semibold">
                {formatViewCount(
                  quiz
                    ? quiz.betAmountPerPlayer
                    : triviaNight
                    ? triviaNight.prizeCoins
                    : 0
                )}
                {/* {quiz.prizeCoins} prize */}
              </p>
            </div>
          </div>
          <p className="text-gray-500 w-full ">
            {/* {targetTopic.name} -  */}
            {quiz
              ? `Battle #${quiz.id}`
              : triviaNight
              ? `TKL #${triviaNight.dayNum}`
              : ""}
          </p>

          <BattleLeaderPodium
            topRanks={allPlayerBattleStats}
            countryFlags={countryFlags}
          />
        </div>

        <BattleLeaderboardTable
          rankedBattleStats={allPlayerBattleStats}
          countryFlags={countryFlags}
          quiz={quiz}
          triviaNight={triviaNight}
          targetTopic={targetTopic}
          guest={guest}
          customer={customer}
          // userId={guest || customer ? (guest || customer).id : ""}
        />

        <div className="m-2 mt-0 w-full short:mb-0 sm:mb-0 bg-gray-100 py-5 rounded-2xl">
          <button
            onClick={(e) => {
              shareTNLeaderboard(e);
            }}
            className="group mx-auto relative overflow-hidden flex-shrink-0 flex transition-all items-center justify-center hover:bg-black bg-blue-600 text-white rounded-full pl-4 pr-3 py-1"
          >
            <p
              className={`${
                isCaptionCopied
                  ? "motion-safe:animate-fadeDownFast"
                  : hasCaptionBeenCopiedBefore
                  ? "motion-safe:animate-fadeOutUpFast"
                  : "hidden"
              } pt-1 text-black font-bold bg-blue-300 inset-0 w-full absolute`}
            >
              {isSharingLeaderboardToApps ? "Shared!" : "Copied!"}
            </p>

            <div
              className={`${
                isCaptionCopied
                  ? "motion-safe:animate-fadeOutDownFast"
                  : hasCaptionBeenCopiedBefore
                  ? "motion-safe:animate-fadeUpFast"
                  : ""
              } flex items-center justify-center space-x-2`}
            >
              <p className="font-bold">Share</p>
              <svg
                className="h-2.5 fill-current"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                  d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                />
                <rect
                  className="opacity-0 group-hover:opacity-100 transition-all"
                  y="4"
                  width="9"
                  height="2"
                />
              </svg>
            </div>
          </button>
        </div>
        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}

        {/* <button
          onClick={(e) => {
            e.preventDefault();
            setShouldShowBattleLeaderboard(false);
          }}
          className="text-lg font-semibold mt-4 uppercase sm:text-xl w-full bg-blue-600 hover:bg-blue-700 transition-all rounded-full shadow-sm text-white py-3 px-2"
        >
          Awesome!
        </button> */}

        {/* {!customer && ( */}
        {/* <div className="m-2 w-full mt-4 sm:mb-0 bg-gray-100 py-5 rounded-2xl">
          <button
            onClick={(e) => {
              navigate("/login");
            }}
            className="group mx-auto flex-shrink-0 flex transition-all items-center justify-center space-x-2 hover:bg-black bg-blue-600 text-white rounded-full backdrop-blur-sm pl-4 pr-3 py-1"
          >
            <p className="font-bold">Share</p>
            <svg
              className="h-2.5 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
        </div> */}

        {/* )} */}
      </form>
    </div>
  );
}
