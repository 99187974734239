import React from "react";

export default function SubmitButton({
  onClickFunc,
  disabled,
  title,
  isRed,
  errors,
  isNotLoading,
  isFlex,
}) {
  return (
    <button
      onClick={onClickFunc}
      className={`${
        !disabled && errors && Object.keys(errors).length > 0
          ? "motion-safe:animate-shake "
          : ""
      } ${
        isRed
          ? disabled
            ? "bg-red-300"
            : "bg-red-600 hover:bg-red-700"
          : disabled
          ? "bg-blue-300"
          : "bg-blue-600 hover:bg-blue-700"
      } ${
        isFlex ? "" : "shrink-0"
      } text-xl relative font-semibold overflow-hidden uppercase mt-2 short:mt-0 w-full transition-all rounded-full shadow text-white px-2`}
      disabled={disabled}
      type="submit"
    >
      <div
        className={`${
          !disabled && errors && Object.keys(errors).length > 0
            ? "motion-safe:animate-fadeOut bg-red-600"
            : ""
        } inset-0 absolute`}
      ></div>
      <div
        className={`${
          !disabled || isNotLoading
            ? "motion-safe:animate-fadeOutUpFast"
            : "motion-safe:animate-fadeDownFast"
        } absolute inset-0 flex items-center justify-center`}
      >
        <svg
          className={`w-6 motion-safe:animate-spin`}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.636 3.636A9 9 0 0119 10"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
          />
        </svg>
      </div>

      <p
        className={`${
          disabled && !isNotLoading
            ? "motion-safe:animate-fadeOutDownFast"
            : "motion-safe:animate-fadeUpFast"
        } h-full py-4 short:py-3`}
      >
        {title}
      </p>
    </button>
  );
}
