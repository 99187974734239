import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";
import { GuestAuthContext } from "../../context/guestAuth";

import {
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_PERSONAL_QUIZ_BY_QUIZ_ID,
  FETCH_QUIZ_BY_ID,
  FETCH_TOPIC,
  FETCH_TRIVIA_VIEW_BY_CUSTOMER,
  FETCH_TRIVIA_VIEW_COUNT,
  PAY_AND_JOIN_EVENT_MUTATION,
  QUIZ_UPDATED_SUBSCRIPTION,
} from "../../util/gqlFunctions";
import { formatViewCount, useForm } from "../../util/hooks";

import HorizontalTimer from "./HorizontalTimer";

function AttachedQuizCommentBox({ navigate, comment, customer }) {
  const { loginGuest: loginGuestContext } = useContext(GuestAuthContext);
  const [errors, setErrors] = useState({});

  const { values } = useForm(payAndJoinEventCallback, {
    quizId: comment.attachedQuizId,
  });

  const [payAndJoinEvent] = useMutation(PAY_AND_JOIN_EVENT_MUTATION, {
    refetchQueries: [
      { query: FETCH_CUSTOMER },
      { query: FETCH_GUEST_CUSTOMER },
      {
        query: FETCH_QUIZ_BY_ID,
        variables: {
          quizId: comment.attachedQuizId,
        },
      },
      {
        query: FETCH_PERSONAL_QUIZ_BY_QUIZ_ID,
        variables: {
          quizId: comment.attachedQuizId,
        },
      },
      {
        query: FETCH_TRIVIA_VIEW_BY_CUSTOMER,
        variables: {
          quizId: comment.attachedQuizId,
        },
      },
      {
        query: FETCH_TRIVIA_VIEW_COUNT,
        variables: { quizId: comment.attachedQuizId, status: 1 },
      },
    ],
    update(_, { data: { payAndJoinEvent: tvWGuestToken } }) {
      setErrors({});
      console.log(errors);
      if (tvWGuestToken.token) {
        loginGuestContext({ token: tvWGuestToken.token });
      }

      navigate("/battle/" + comment.attachedQuizId);
    },
    onError(err) {
      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0].extensions.errors);

        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: customer ? customerClient : guestClient,
  });

  const {
    subscribeToMore,
    data: { getQuizById: quiz } = {},
    // loading: loadingTriviaNight,
  } = useQuery(FETCH_QUIZ_BY_ID, {
    variables: { quizId: comment.attachedQuizId },
  });

  function payAndJoinEventCallback(e) {
    if (
      (quiz && quiz.status !== 0) ||
      (custTriviaView && custTriviaView.status === 1)
    ) {
      navigate("/battle/" + quiz.id);
    } else {
      e.preventDefault();
      payAndJoinEvent();
    }
  }
  // const {
  //   data: { getJoinedPlayersByEvent: joinedPlayers } = {},
  //   // loading: loadingTriviaNight,
  // } = useQuery(FETCH_JOINED_PLAYERS_BY_EVENT, {
  //   variables: { quizId: comment.attachedQuizId },
  //   client: customer ? customerClient : guestClient,
  //   pollInterval: 500,
  // });

  const {
    // refetch: refetchCustTriviaView,
    data: { getTriviaViewByCustomer: custTriviaView } = {},
    // loading: loadingCustTriviaView,
  } = useQuery(FETCH_TRIVIA_VIEW_BY_CUSTOMER, {
    client: customer ? customerClient : guestClient,

    variables: {
      quizId: comment.attachedQuizId,
    },
  });

  useEffect(() => {
    let subscription = null;
    if (quiz) {
      subscription = subscribeToMore({
        document: QUIZ_UPDATED_SUBSCRIPTION,
        variables: { quizId: quiz.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const updatedQuiz = subscriptionData.data.quizUpdated;

          return {
            ...prev,
            getQuizById: { ...updatedQuiz },
            // getOrderById: [
            //   ...prev.getFulfillerChatsByOrder,
            //   newMessage,
            // ],
          };
        },
      });
    }

    return () => {
      if (subscription && typeof subscription.unsubscribe === "function") {
        subscription.unsubscribe();
      }
      // subscription();
    };
  }, [subscribeToMore, quiz]);

  const { data: { getTopicById: topic } = {} } = useQuery(FETCH_TOPIC, {
    variables: { topicId: quiz ? quiz.topicIds[0] : "" },
  });

  let numOfPlayers = quiz ? quiz.prizeCoins / quiz.betAmountPerPlayer : 0;

  return quiz && topic ? (
    <div
      onClick={(e) => {
        navigate("/battle/" + quiz.id);
      }}
      className="flex relative items-center justify-start space-x-2 sm:space-x-4 w-full leading-tight text-black cursor-pointer short:p-1 p-2 pr-2.5 sm:p-3 sm:pr-5 hover:bg-opacity-80 transition-all backdrop-blur bg-white rounded-xl sm:rounded-2xl"
    >
      <div className="rounded-lg sm:rounded-xl overflow-hidden bg-black h-12 sm:h-14 aspect-square">
        <img
          src={topic.logoUrl}
          className="h-full aspect-square mr-2 object-cover"
          alt={topic.name}
        />
      </div>
      <div className="flex-1 mt-0.5 sm:mt-0 shrink-0">
        <p className="sm:text-lg short:text-sm truncate font-semibold leading-tight text-black">
          {topic.name}
        </p>
        <div className="flex mb-1 leading-tight items-center justify-start text-gray-700">
          <svg
            className="short:h-3 h-4 fill-current"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.24404 3.7559C3.24404 1.96349 4.69581 0.511719 6.48822 0.511719C8.28064 0.511719 9.73241 1.96349 9.73241 3.7559C9.73241 5.54832 8.28064 7.00009 6.48822 7.00009C4.69581 7.00009 3.24404 5.54832 3.24404 3.7559ZM0 11.0554C0 8.898 4.32288 7.8112 6.48837 7.8112C8.65387 7.8112 12.9767 8.898 12.9767 11.0554V13.4885H0V11.0554Z"
            />
          </svg>
          <p className="ml-1 short:text-xs text-sm">
            {formatViewCount(numOfPlayers)}
          </p>

          <svg
            className="short:h-3.5 h-4 ml-1.5 sm:ml-2 fill-current"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
              fill="#F4900C"
            />
            <path
              d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
              fill="#FFCC4D"
            />
            <path
              d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
              fill="#FFE8B6"
            />
            <path
              d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
              fill="#FFAC33"
            />
            <path
              d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
              fill="#FFD983"
            />
          </svg>
          <p className="ml-1 short:text-xs text-sm">
            {quiz.betAmountPerPlayer}
          </p>
        </div>

        {/* <div className="flex short:hidden items-center group justify-start pr-2">
            {joinedPlayers && joinedPlayers.length > 0 ? (
              joinedPlayers.slice(0, 5).map((joinedPlayer, index) => (
                <div
                  className={`${
                    joinedPlayer.username === "humpty"
                      ? "bg-blue-300"
                      : // : joinedPlayer.subscriptionType === 0
                        // ? "bg-yellow-300"
                        // : joinedPlayer.subscriptionType === 1
                        // ? "bg-red-400"
                        "bg-gray-100/50"
                  } ${
                    index !== 0 ? "-ml-5" : ""

                    // index !== 0 ? "-ml-5 sm:group-hover:-ml-1" : ""
                  } flex-shrink-0 mr-2 2xl:mr-4 transition-all overflow-hidden  p-[0.2rem] 2xl:p-1.5 rounded-full aspect-square w-8 2xl:w-14`}
                  key={index}
                  // hover:bg-blue-700
                >
                  <img
                    src={joinedPlayer.profilePicUrl}
                    alt={joinedPlayer.username}
                    className="border-white bg-white border-[1.5px] 2xl:border-4 box-border rounded-full object-cover aspect-square"
                  />
                </div>
              ))
            ) : (
              <div
                className={`${
                  comment.commentorUsername === "humpty"
                    ? "bg-blue-300"
                    : // : comment.subscriptionType === 0
                      // ? "bg-yellow-300"
                      // : comment.subscriptionType === 1
                      // ? "bg-red-400"
                      "bg-gray-100/50"
                } flex-shrink-0 mr-2 2xl:mr-4 transition-all overflow-hidden  p-[0.2rem] 2xl:p-1.5 rounded-full aspect-square w-8 2xl:w-14`}

                // hover:bg-blue-700
              >
                <img
                  src={comment.commentorProfilePicUrl}
                  alt={comment.commentorUsername}
                  className="border-white bg-white border-[1.5px] 2xl:border-4 box-border rounded-full object-cover aspect-square"
                />
              </div>
            )}
          </div> */}
      </div>

      <button
        onClick={payAndJoinEventCallback}
        className={`${
          quiz.status !== 0
            ? "bg-black"
            : custTriviaView && custTriviaView.status === 1
            ? "bg-transparent"
            : "bg-blue-600"
        } font-semibold group relative overflow-hidden hover:bg-black transition-all sm:text-base text-sm short:py-1 short:px-3 px-4 sm:px-6 leading-tight shadow-xl rounded-full text-white p-2`}
      >
        {quiz.status === 1 && quiz.curQIndex === 1 && (
          <HorizontalTimer
            endTime={quiz.nextQStartsAt}
            lastXSeconds={quiz.nextQLastsXSeconds}
          />
        )}
        <p className="w-full">
          {quiz.status !== 0
            ? "View Battle"
            : custTriviaView && custTriviaView.status === 1
            ? "Joined Battle"
            : "Join Battle"}
        </p>
      </button>
    </div>
  ) : (
    <></>
  );
}

export default AttachedQuizCommentBox;
