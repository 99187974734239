import React from "react";
import { Link } from "react-router-dom";

export default function ParagraphTitle({ title }) {
  return (
    <div className="flex fixed flex-col items-center justify-center inset-x-0 max-w-sm mx-auto w-full">
      <div className="bg-gradient-to-b from-gray-100 to-transparent w-full h-4 absolute bottom-0 -mb-4"></div>
      <div className="px-4 pt-4 bg-gray-100 w-full items-center flex flex-col justify-center">
        <Link className="hover:opacity-75 transition-opacity mb-4" to="/">
          <svg
            className="h-4 fill-current text-blue-600"
            viewBox="0 0 140 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M69.7018 0C51.1365 0 33.3636 7.37493 20.2921 20.5025C7.22057 33.63 -0.0786027 51.4347 0.000638512 69.9999C0.0798797 88.5647 7.531 106.37 20.7136 119.497C33.8971 132.625 51.7337 140 70.2982 140C61.7401 140 53.3626 137.462 46.226 132.707C39.0902 127.952 33.5148 121.194 30.2062 113.287C26.8969 105.38 26.0032 96.679 27.637 88.2849C29.2709 79.8908 33.3595 72.1805 39.3851 66.1287C45.4116 60.077 53.1045 55.9556 61.491 54.286C69.8783 52.6163 78.5826 53.4732 86.5034 56.7484C94.4243 60.0236 101.206 65.57 105.991 72.6861C110.777 79.8019 113.35 88.1688 113.386 96.7275C113.435 108.204 108.923 119.21 100.842 127.325C92.7618 135.44 81.7751 140 70.2982 140C88.8635 140 106.636 132.625 119.708 119.497C132.779 106.37 140.079 88.5647 139.999 69.9999C139.92 51.4347 132.469 33.63 119.286 20.5025C106.103 7.37493 88.2671 0 69.7018 0Z" />
          </svg>
        </Link>
        <div className="bg-white shadow-md w-full py-2 px-3 rounded-xl z-10">
          <p className="text-center text-sm font-semibold leading-none">
            {title}
          </p>
        </div>
      </div>
    </div>
  );
}
