import React from "react";

export default function BattleLeaderPodium({ topRanks, countryFlags }) {
  const defaultPicUrl = "https://tknight-media.s3.amazonaws.com/profile.png";

  return (
    <div className="flex items-end text-lg short:text-base w-full my-6 max-w-sm justify-center space-x-4 short:space-x-0 sm:space-x-0">
      <div className="flex w-1/3 truncate flex-col items-center justify-end">
        <div
          className={`bg-gray-100 shadow-lg relative  flex items-center justify-end flex-col transition-all short:p-[0.4rem] p-2 rounded-full aspect-square w-20 short:w-[4.2rem]`}

          // hover:bg-blue-700
        >
          <img
            src={
              topRanks[1] && topRanks[1].cProfilePicUrl
                ? topRanks[1].cProfilePicUrl
                : defaultPicUrl
            }
            alt={
              topRanks[1]
                ? topRanks[1].cUsername
                  ? topRanks[1].cUsername
                  : topRanks[1].gUsername
                : "2nd place"
            }
            className="border-white bg-white border-2 box-border rounded-full object-cover aspect-square "
          />

          <p className=" absolute bottom-0 bg-white shadow rounded-full aspect-square w-8 -mb-3 flex items-center justify-center">
            <span>2</span>
          </p>
        </div>

        <p className=" font-semibold mt-12 short:mt-10 w-full text-black truncate">
          {!topRanks[1] ? "-" : topRanks[1].cName || "Guest"}
          {topRanks[1]
            ? " " +
              countryFlags[
                topRanks[1].cCountry || topRanks[1].gCountry || "All"
              ]
            : ""}
        </p>
        {/* <p className="px-4 py-0.5 bg-white/20  rounded-full  text-white">
          ${topRanks[1] ? (topRanks[1].totalEarnings / 100).toFixed(2) : "0"}
        </p> */}
      </div>

      <div className="flex flex-col items-center justify-end">
        <div
          className={`bg-gray-100 shadow-lg relative  flex items-center justify-end flex-col transition-all p-2.5 rounded-full aspect-square w-32 short:w-24 short:p-2`}

          // hover:bg-blue-700
        >
          <img
            src={
              topRanks[0] && topRanks[0].cProfilePicUrl
                ? topRanks[0].cProfilePicUrl
                : defaultPicUrl
            }
            alt={
              topRanks[0]
                ? topRanks[0].cUsername
                  ? topRanks[0].cUsername
                  : topRanks[0].gUsername
                : "1st place"
            }
            className="border-white bg-white border-2 box-border rounded-full object-cover aspect-square"
          />

          <p className=" absolute bottom-0 bg-white rounded-full aspect-square w-10 shadow -mb-3.5 flex items-center justify-center short:w-8">
            <span>1</span>
          </p>
        </div>

        <p className="font-semibold mt-8 text-black">
          {!topRanks[0] ? "-" : topRanks[0].cName || "Guest"}

          {topRanks[0]
            ? " " +
              countryFlags[
                topRanks[0].cCountry || topRanks[0].gCountry || "All"
              ]
            : ""}
        </p>
      </div>
      <div className="flex w-1/3 truncate flex-col items-center justify-end">
        <div
          className={`bg-gray-100 shadow-lg relative  flex items-center justify-end flex-col transition-all short:p-[0.4rem] p-2 rounded-full aspect-square w-20 short:w-[4.2rem]`}

          // hover:bg-blue-700
        >
          <img
            src={
              topRanks[2] && topRanks[2].cProfilePicUrl
                ? topRanks[2].cProfilePicUrl
                : defaultPicUrl
            }
            alt={
              topRanks[2]
                ? topRanks[2].cUsername
                  ? topRanks[2].cUsername
                  : topRanks[2].gUsername
                : "3rd place"
            }
            className="border-white bg-white border-2 box-border rounded-full object-cover aspect-square"
          />

          <p className=" absolute bottom-0 bg-white shadow rounded-full aspect-square w-8 -mb-3 flex items-center justify-center">
            <span>3</span>
          </p>
        </div>

        <p className="font-semibold mt-12 short:mt-10 w-full text-black truncate">
          {!topRanks[2] ? "-" : topRanks[2].cName || "Guest"}
          {topRanks[2]
            ? " " +
              countryFlags[
                topRanks[2].cCountry || topRanks[2].gCountry || "All"
              ]
            : ""}
        </p>
      </div>
    </div>
  );
}
