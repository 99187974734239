import React from "react";

export default function GenericPaymentSuccessfulForm({
  togglePaymentSuccessfulForm,
  targetCustomer,
  message,
  buttonMsg,
  popUpTitle,
  shouldEscapeForm,
  escapeForm,
  isRed,
  disabled,
}) {
  return (
    <div className="fixed pt-16 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center short:pt-12">
      <button
        onClick={(e) => {
          e.preventDefault();

          if (shouldEscapeForm) {
            escapeForm();
          } else {
            togglePaymentSuccessfulForm();
          }
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden  text-center sm:max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-md overflow-y-auto flex flex-col items-center justify-center z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();

            togglePaymentSuccessfulForm();
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          {popUpTitle ? popUpTitle : "Payment Successful!"}
        </p>
        <p className="text-gray-500 w-full leading-tight text-lg short:text-base short:leading-tight mt-1">
          {message}
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}

        <button
          onClick={(e) => {
            e.preventDefault();
            togglePaymentSuccessfulForm();
          }}
          className={`text-lg font-semibold mt-4 uppercase sm:text-xl w-full ${
            isRed
              ? disabled
                ? "bg-red-300"
                : "bg-red-600 hover:bg-red-700"
              : disabled
              ? "bg-blue-300"
              : "bg-blue-600 hover:bg-blue-700"
          } transition-all rounded-full shadow-sm text-white py-3 px-2`}
        >
          {buttonMsg}
        </button>
      </form>
    </div>
  );
}
