import React, { useEffect, useState } from "react";

import { countryDetails, validEmail5 } from "../../util/hooks";

export default function TextInputField({
  id,
  placeholder,
  title,
  name,
  value,
  onChange,
  miniSuffix,
  error,
  values,
  setValues,
  type,
  isError,
  disabled,
  successMsg,
  moreInfo,
  fontSize,
  min,
  onChangeNumber,
  suffix,
  onClickSuffix,
  oldPasswordVal,
  max,
  showForgotPasswordLink,
  disableSuffixButton,
  navigate,
  fieldsToEdit,
  setFieldsToEdit,
  phoneCodePrefix,
  phoneCountryCode,
  editFunc,
  prefix,
  isCorrectAnswer,
  setCorrectAnswer,
  maxLen,
  rows,
  actionBtnTitle,
  onActionBtnPress,
}) {
  const maxLength = maxLen
    ? maxLen
    : name === "caption"
    ? 20
    : name === "email"
    ? 50
    : name === "password" || name === "newPassword"
    ? 30
    : name === "passwordResetCode" || name === "verificationCode"
    ? 6
    : null;
  const [shouldOpenCountrySelector, setShouldOpenCountrySelector] =
    useState(false);

  const [hideErrorMsg, setHideErrorMsg] = useState(false);
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);
  useEffect(() => {
    if (value && error && error === "- Required" && !hideErrorMsg) {
      setHideErrorMsg(true);
    } else if (!value && hideErrorMsg && error === "- Required") {
      setHideErrorMsg(false);
    } else if (hideErrorMsg && error && error !== "- Required") {
      if (name === "email" && validEmail5.test(value)) {
        setHideErrorMsg(true);
        return;
      } else {
        setHideErrorMsg(false);
      }
    }
  }, [value, name, maxLength, hideErrorMsg, error]);

  const resetInterval = 500;

  const [isSuffixClicked, setIsSuffixClicked] = useState(null);

  useEffect(() => {
    let timeout;
    if (isSuffixClicked && resetInterval) {
      timeout = setTimeout(() => setIsSuffixClicked(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuffixClicked, resetInterval]);

  return (
    <div
      className={`${
        type !== "number" ? "flex-col items-start" : "items-center"
      } w-full flex  justify-start ${fontSize || "sm:text-base text-sm"}`}
    >
      <div className="w-full flex items-baseline justify-between">
        <label
          htmlFor={name}
          className={`${
            type === "number" ? "flex-1" : ""
          } flex-shrink-0 font-semibold text-gray-700`}
        >
          {title}{" "}
          {error &&
            (!fieldsToEdit ||
              fieldsToEdit.length === 0 ||
              (fieldsToEdit && fieldsToEdit.includes(name))) && (
              <span className="text-red-500 sm:text-base text-sm short:hidden">
                {error}
              </span>
            )}
          {successMsg && !error && !disabled && (
            <span className="text-green-500 sm:text-base text-sm">
              {successMsg}
            </span>
          )}
        </label>
        <div className="flex-1"></div>
        {showForgotPasswordLink && (
          <button
            onClick={(e) => {
              navigate(showForgotPasswordLink);
            }}
            tabIndex={-1}
            className="font-semibold text-blue-600 flex-shrink-0 hover:text-blue-800"
          >
            Forgot your password?
          </button>
        )}
        {actionBtnTitle && (
          <button
            onClick={onActionBtnPress}
            tabIndex={-1}
            className="font-semibold text-blue-600 text-sm flex-shrink-0 hover:text-blue-800"
          >
            {actionBtnTitle}
          </button>
        )}
        {maxLength && type === "multiline" ? (
          <div
            className={`mr-4 ${
              fieldsToEdit ? "" : "short:mr-0"
            } tracking-wider text-gray-400 text-sm uppercase py-1 rounded-md`}
          >
            {value ? value.length : 0}/{maxLength}
          </div>
        ) : (
          <></>
        )}
        {moreInfo && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsMoreInfoOpen(!isMoreInfoOpen);
            }}
            className={`${
              isMoreInfoOpen ? "text-gray-500" : ""
            } m-auto text-gray-400 hover:text-gray-600 transition-all`}
          >
            <svg
              className="h-4 fill-current "
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 5V7H11V5H9ZM9 9V15H11V9H9ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
              />
            </svg>
          </button>
        )}
        {fieldsToEdit && fieldsToEdit.length === 0 && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setFieldsToEdit(name);
            }}
            className="text-blue-500 ml-auto text-right transition-all font-semibold hover:text-blue-700"
          >
            Edit
          </button>
        )}
        {isCorrectAnswer !== null && isCorrectAnswer !== undefined ? (
          isCorrectAnswer ? (
            <div
              className={`bg-green-600 ${
                fieldsToEdit && fieldsToEdit.length === 0 ? "ml-2" : "mr-2"
              } font-semibold tracking-wider text-white text-xs uppercase px-2 py-1 rounded-md`}
            >
              Correct
            </div>
          ) : (
            <button
              onClick={setCorrectAnswer}
              className="text-blue-500 ml-4 transition-all font-semibold hover:text-blue-700"
            >
              Set correct
            </button>
          )
        ) : (
          <></>
        )}
        {fieldsToEdit && fieldsToEdit.includes(name) && (
          <div className="flex items-center justify-end space-x-2">
            <button
              onClick={(e) => {
                e.preventDefault();

                setFieldsToEdit(name);
              }}
              className="text-red-500 transition-all font-semibold hover:text-red-700"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                editFunc();
              }}
              className="text-green-500 transition-all font-semibold hover:text-green-700"
            >
              Save
            </button>
          </div>
        )}
      </div>

      {type === "password" &&
        fieldsToEdit &&
        fieldsToEdit.includes("password") && (
          <div className="w-full relative mb-2">
            <input
              type={shouldShowPassword ? "text" : type || "text"}
              name={"oldPassword"}
              id={"oldPassword"}
              value={oldPasswordVal}
              onChange={onChange}
              className="sm:text-lg mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500"
              placeholder={"Old password"}
              disabled={
                disabled || (fieldsToEdit && !fieldsToEdit.includes("password"))
              }
            />
            <button
              className="right-0 text-gray-400 hover:text-gray-600 absolute my-auto inset-y-0 px-2 sm:px-4 top-1"
              type="button"
              onClick={(e) => {
                e.preventDefault();

                if (shouldShowPassword) {
                  setShouldShowPassword(false);
                } else {
                  setShouldShowPassword(true);
                }
              }}
            >
              {shouldShowPassword ? (
                <svg
                  className="h-6 focus:outline-none fill-current transition-all"
                  clipRule="evenodd"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m17.069 6.546 2.684-2.359c.143-.125.32-.187.497-.187.418 0 .75.34.75.75 0 .207-.086.414-.254.562l-16.5 14.501c-.142.126-.319.187-.496.187-.415 0-.75-.334-.75-.75 0-.207.086-.414.253-.562l2.438-2.143c-1.414-1.132-2.627-2.552-3.547-4.028-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 1.815 0 3.536.593 5.071 1.546zm2.318 1.83c.967.943 1.804 2.013 2.475 3.117.092.156.138.332.138.507s-.046.351-.138.507c-2.068 3.403-5.721 6.493-9.864 6.493-1.298 0-2.553-.313-3.73-.849l2.624-2.307c.352.102.724.156 1.108.156 2.208 0 4-1.792 4-4 0-.206-.016-.408-.046-.606zm-4.932.467c-.678-.528-1.53-.843-2.455-.843-2.208 0-4 1.792-4 4 0 .741.202 1.435.553 2.03l1.16-1.019c-.137-.31-.213-.651-.213-1.011 0-1.38 1.12-2.5 2.5-2.5.474 0 .918.132 1.296.362z"
                    fillRule="nonzero"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 focus:outline-none fill-current transition-all"
                  clipRule="evenodd"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z"
                    fillRule="nonzero"
                  />
                </svg>
              )}
            </button>
          </div>
        )}

      {suffix ? (
        <div className="flex items-center justify-center w-full mt-1">
          <input
            type={type || "text"}
            name={name}
            id={id || name}
            value={value}
            onChange={onChange}
            className={`${
              name === "verificationCode" || name === "passwordResetCode"
                ? "tracking-widest uppercase"
                : ""
            } ${
              name === "username" ? "lowercase" : ""
            } sm:text-lg w-full shadow-sm flex-1 px-3 py-2 rounded-l-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
            placeholder={placeholder}
            disabled={
              disabled || (fieldsToEdit && !fieldsToEdit.includes(name))
            }
            maxLength={maxLength}
          />

          <button
            className={`${isSuffixClicked ? "motion-safe:animate-popUp" : ""} ${
              !disableSuffixButton && !disabled && isError
                ? " motion-safe:animate-shake "
                : ""
            } bg-blue-600 group relative flex items-center justify-center disabled:bg-blue-300 hover:bg-blue-700 font-semibold  transition-all text-white shadow-sm rounded-r-md sm:text-lg sm:pl-6 pl-4 pr-3 sm:pr-5 py-2 border-gray-200 border border-l-0`}
            onClick={(e) => {
              setIsSuffixClicked(true);
              onClickSuffix(e);
            }}
            disabled={disableSuffixButton}
          >
            <div
              className={`${
                !disableSuffixButton && !disabled && isError
                  ? "motion-safe:animate-fadeOut bg-red-600"
                  : ""
              } inset-0 absolute `}
            ></div>
            <p className="z-50">{suffix}</p>
            <svg
              className="sm:h-3 h-2.5 ml-1.5 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
        </div>
      ) : prefix ? (
        <div className="flex items-center justify-center w-full mt-1">
          <span
            className={`bg-gray-50 text-gray-500 shadow-sm rounded-l-md sm:text-lg px-3 py-2 border-gray-200 border border-r-0`}
          >
            {prefix}
          </span>

          <input
            type={type || "text"}
            name={name}
            id={id || name}
            value={value}
            onChange={onChange}
            className={`${
              name === "verificationCode" || name === "passwordResetCode"
                ? "tracking-widest uppercase"
                : ""
            } ${
              name === "username" ? "lowercase" : ""
            } sm:text-lg w-full shadow-sm flex-1 px-3 py-2 rounded-r-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
            placeholder={placeholder}
            disabled={
              disabled || (fieldsToEdit && !fieldsToEdit.includes(name))
            }
            maxLength={maxLength}
          />
        </div>
      ) : type === "multiline" ? (
        <textarea
          name={name}
          id={id || name}
          rows={rows ? rows : "3"}
          value={value}
          onChange={onChange}
          className="sm:text-lg mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500"
          placeholder={placeholder}
          maxLength={maxLen}
          disabled={disabled || (fieldsToEdit && !fieldsToEdit.includes(name))}
        ></textarea>
      ) : type === "money" ? (
        <div
          className={`${
            disabled || (fieldsToEdit && !fieldsToEdit.includes(name))
              ? "bg-gray-50"
              : "bg-white"
          } sm:text-lg mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500 flex items-baseline justify-start`}
        >
          <p>US$</p>
          <input
            type={"number"}
            min={min}
            max={max}
            name={name}
            id={id || name}
            // step={5}
            value={(value / 100).toFixed(2)}
            className={`disabled:bg-gray-50 bg-white w-full focus:outline-none `}
            onChange={onChange}
            placeholder={placeholder}
            disabled={
              disabled || (fieldsToEdit && !fieldsToEdit.includes(name))
            }
          />
        </div>
      ) : type === "number" ? (
        <>
          <button
            onClick={(e) => {
              e.preventDefault();

              onChangeNumber(name, value - 1);
            }}
            disabled={
              disabled ||
              (fieldsToEdit && !fieldsToEdit.includes(name)) ||
              value <= 0
            }
            className="h-10 ml-6 w-10 disabled:bg-gray-200 group shadow-sm rounded-l-md transition-all border border-r-0 border-gray-200 bg-gray-50 hover:bg-gray-100"
          >
            <svg
              className="w-5 fill-current group-disabled:opacity-0 opacity-100 text-gray-700 m-auto"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19 13H5V11H19V13Z" />
            </svg>
          </button>
          {miniSuffix ? (
            <div className=" shadow-sm border-gray-200 border focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 flex items-center justify-center sm:text-lg w-24 h-10 pl-2 pr-3 py-2 ">
              <input
                type={type || "text"}
                min={min}
                max={max}
                className=" bg-white text-center border-0 m-0 p-0 bg-none"
                name={name}
                id={id || name}
                step={1}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                disabled={
                  disabled || (fieldsToEdit && !fieldsToEdit.includes(name))
                }
              />
              <p className="text-left">{miniSuffix}</p>
            </div>
          ) : (
            <input
              type={type || "text"}
              min={min}
              max={max}
              name={name}
              id={id || name}
              step={1}
              value={value}
              onChange={onChange}
              className="sm:text-lg w-24 h-10 text-center shadow-sm px-3 py-2 border-gray-200 border focus:outline-none focus:border-indigo-500 focus:ring-indigo-500"
              placeholder={placeholder}
              disabled={
                disabled || (fieldsToEdit && !fieldsToEdit.includes(name))
              }
            />
          )}

          <button
            onClick={(e) => {
              e.preventDefault();

              onChangeNumber(name, value + 1);
            }}
            disabled={
              disabled ||
              (fieldsToEdit && !fieldsToEdit.includes(name)) ||
              value >= max
            }
            className="h-10 w-10 disabled:bg-gray-200 group shadow-sm rounded-r-md border-l-0 transition-all border border-gray-200 bg-gray-50 hover:bg-gray-100"
          >
            <svg
              className="w-5 fill-current group-disabled:opacity-0 opacity-100 text-gray-700 m-auto"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
            </svg>
          </button>
        </>
      ) : type === "tel" ? (
        <div className="flex relative items-center justify-start rounded-md  mt-1 border-gray-200 overflow-hidden border w-full shadow-sm ">
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShouldOpenCountrySelector(!shouldOpenCountrySelector);
            }}
            className={`bg-gray-50 whitespace-nowrap rounded-l-md hover:bg-gray-100 transition-all space-x-2 flex items-center justify-start text-gray-500  h-full sm:text-lg px-3 py-2 border-gray-200 border-r`}
          >
            <p>{phoneCodePrefix}</p>
            <svg
              className="w-2.5 aspect-square mt-0.5 fill-current"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0.5L5 5.5L10 0.5H0Z" />
            </svg>
          </button>
          <input
            type="tel"
            name={name}
            id={id || name}
            value={value}
            onChange={(e) => {
              let newText = e.target.value || "";
              newText = newText.replace("-", "");
              newText = newText.replace(" ", "");
              newText = newText.replace(" ", "");
              if (phoneCountryCode && newText.includes(phoneCountryCode)) {
                newText = newText.replace(phoneCountryCode, "");
              }
              setValues({ ...values, [name]: newText });
            }}
            className={`sm:text-lg flex-1 px-3 py-2 w-full focus:outline-none `}
            placeholder={placeholder}
            disabled={
              disabled || (fieldsToEdit && !fieldsToEdit.includes(name))
            }
            maxLength={maxLength}
            autoComplete="mobile tel"
          />

          {shouldOpenCountrySelector && (
            <div className="bg-white shadow-lg mt-2 z-10 border-gray-200 border flex flex-col items-start justify-start absolute left-0 top-full max-w-sm max-h-36 rounded overflow-y-auto">
              {countryDetails.map((country) => (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setValues({
                      ...values,
                      phoneCountryCode: country.phoneCountryCode,
                      phoneCountryShortForm: country.phoneCountryShortForm,
                    });
                    setShouldOpenCountrySelector(false);
                  }}
                  type="button"
                  className={`${
                    values.phoneCountryCode === country.phoneCountryCode
                      ? "bg-blue-100"
                      : "hover:bg-gray-200"
                  } inline-flex space-x-4 w-full justify-between px-3 py-2 text-gray-700 transition-all`}
                >
                  <p className="whitespace-nowrap">
                    {country.emoji} {country.countryName}
                  </p>
                  <p>{country.phoneCountryCode}</p>
                </button>
              ))}
            </div>
          )}
        </div>
      ) : type === "password" ? (
        <div className="w-full relative">
          <input
            type={shouldShowPassword ? "text" : type || "text"}
            name={name}
            id={name}
            value={value}
            onChange={onChange}
            className="sm:text-lg mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500"
            placeholder={
              fieldsToEdit && fieldsToEdit.includes("password")
                ? "New password"
                : placeholder
            }
            disabled={
              disabled || (fieldsToEdit && !fieldsToEdit.includes(name))
            }
          />
          {!fieldsToEdit && (
            <button
              className="right-0 text-gray-400 hover:text-gray-600 absolute my-auto inset-y-0 px-2 sm:px-4 top-1"
              type="button"
              onClick={(e) => {
                e.preventDefault();

                if (shouldShowPassword) {
                  setShouldShowPassword(false);
                } else {
                  setShouldShowPassword(true);
                }
              }}
            >
              {shouldShowPassword ? (
                <svg
                  className="h-6 focus:outline-none fill-current transition-all"
                  clipRule="evenodd"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m17.069 6.546 2.684-2.359c.143-.125.32-.187.497-.187.418 0 .75.34.75.75 0 .207-.086.414-.254.562l-16.5 14.501c-.142.126-.319.187-.496.187-.415 0-.75-.334-.75-.75 0-.207.086-.414.253-.562l2.438-2.143c-1.414-1.132-2.627-2.552-3.547-4.028-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 1.815 0 3.536.593 5.071 1.546zm2.318 1.83c.967.943 1.804 2.013 2.475 3.117.092.156.138.332.138.507s-.046.351-.138.507c-2.068 3.403-5.721 6.493-9.864 6.493-1.298 0-2.553-.313-3.73-.849l2.624-2.307c.352.102.724.156 1.108.156 2.208 0 4-1.792 4-4 0-.206-.016-.408-.046-.606zm-4.932.467c-.678-.528-1.53-.843-2.455-.843-2.208 0-4 1.792-4 4 0 .741.202 1.435.553 2.03l1.16-1.019c-.137-.31-.213-.651-.213-1.011 0-1.38 1.12-2.5 2.5-2.5.474 0 .918.132 1.296.362z"
                    fillRule="nonzero"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 focus:outline-none fill-current transition-all"
                  clipRule="evenodd"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z"
                    fillRule="nonzero"
                  />
                </svg>
              )}
            </button>
          )}
        </div>
      ) : (
        <input
          type={type || "text"}
          name={name}
          id={id || name}
          value={value}
          onChange={onChange}
          className={`${
            name === "verificationCode" || name === "passwordResetCode"
              ? "tracking-widest uppercase"
              : ""
          } sm:text-lg mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
          placeholder={placeholder}
          disabled={disabled || (fieldsToEdit && !fieldsToEdit.includes(name))}
          maxLength={maxLength}
          autoComplete="off"
        />
      )}

      {moreInfo && isMoreInfoOpen && (
        <div className="mt-2">
          {moreInfo.map((info) => (
            <p className="leading-tight">&#8227; {info}</p>
          ))}
        </div>
      )}
      {name === "verificationCode" &&
        !value &&
        (suffix === "Resend" || (error && error.includes("wait"))) && (
          <p className="mt-1">
            Please search "TKnight" in your email inbox and check the promotions
            section if you're having trouble find the code.
          </p>
        )}
    </div>
  );
}
