import React, { useEffect, useState } from "react";
import { useForm } from "../../util/hooks";

import { useMutation } from "@apollo/client";

import { customerClient } from "../../GraphqlApolloClients";

import moment from "moment";
import {
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_SUBSCRIPTION,
  FETCH_FRIENDS_BY_SUBSCRIPTION,
  REMOVE_MEMBER_FROM_SUB_PLAN_MUTATION,
} from "../../util/gqlFunctions";

function EditProMembersForm({
  targetCustomer,
  togglePaymentSuccessfulForm,
  subscriptionType,
  toggleBuySubscriptionForm,
  customerSubscription,
  toggleManageMembersForm,
  members,
}) {
  const [errors, setErrors] = useState({});

  const { values, setValues } = useForm(
    removeCustomerFromSubscriptionPlanCallback,
    {
      customerId: null,
    }
  );

  const [
    removeCustomerFromSubscriptionPlan,
    { loading: loadingRemoveCustomerFromSubscriptionPlan },
  ] = useMutation(REMOVE_MEMBER_FROM_SUB_PLAN_MUTATION, {
    refetchQueries: [
      { query: FETCH_CUSTOMER },
      { query: FETCH_CUSTOMER_SUBSCRIPTION, variables: { status: 1 } },
      { query: FETCH_FRIENDS_BY_SUBSCRIPTION },
    ],
    update(_, { data: { toggleFriend: toggledFriend } }) {
      setValues({ ...values, customerId: null });
    },
    onError(err) {
      console.log(err);
      setValues({ ...values, customerId: null });
      console.log(errors);
    },
    variables: values,
    client: customerClient,
  });

  function removeCustomerFromSubscriptionPlanCallback() {
    removeCustomerFromSubscriptionPlan();
  }

  const [isCaptionCopied, setIsCaptionCopied] = useState(false);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const [hasCaptionBeenCopiedBefore, setHasCaptionBeenCopiedBefore] =
    useState(false);

  useEffect(() => {
    let timeout;
    if (isCaptionCopied) {
      timeout = setTimeout(() => setIsCaptionCopied(false), 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied]);
  const shareCustSubInvite = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = null;

    textToShare =
      customerSubscription.subscriptionType === 1
        ? "Join my TKnight Pro duo with code: " + customerSubscription.id
        : "Join our TKnight Pro family with code: " + customerSubscription.id;

    if (navigator.share) {
      navigator
        .share({
          title: "Your TKnight Pro Invite",
          text: textToShare,
        })
        .then(() => {
          setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);

          setIsCaptionCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }
  };

  return (
    <div className="fixed pt-16 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center short:pt-12">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          toggleManageMembersForm();
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeIn flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden h-full sm:auto max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-4 pb-6 sm:p-8 sm:pb-0 bg-white shadow-xl rounded-b-none sm:rounded-3xl `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            toggleManageMembersForm();
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl short:text-xl text-center font-bold w-full leading-tight short:leading-tight">
          Edit Plan Members
        </p>
        <p className="text-gray-500 w-full leading-tight mt-1 text-center mb-4">
          Once you remove them, they will no longer be able to use Pro features
        </p>

        <div className="w-full flex-1 my-2 flex flex-col items-start justify-start truncate">
          {members &&
            members.length > 0 &&
            members
              .filter((member) => member.id === targetCustomer.id)
              .map((member, index) => (
                <div
                  key={member.id}
                  className="flex shrink-0 text-left group space-x-2 sm:space-x-4 overflow-hidden transition-all rounded-xl items-center w-full justify-start py-2"
                >
                  <div
                    className={`${
                      member.username === "humpty"
                        ? "bg-blue-300"
                        : // : member.isPaidSubscriber
                          // ? member.subscriptionType === 0
                          //   ? "bg-yellow-300"
                          //   : "bg-red-400"
                          "bg-gray-100/50"
                    } overflow-hidden shrink-0 shadow relative rounded-full`}
                  >
                    {/* <div className="inset-1 sm:inset-1.5 overflow-hidden rounded-full absolute">
                      {/* <div className="sm:w-12 sm:h-12 w-8 h-8 border-white border-2 transition-all bg-opacity-0 flex items-center justify-center bg-black rounded-full">
                        {loadingRemoveCustomerFromSubscriptionPlan &&
                        values.customerId === member.id ? (
                          <svg
                            className="w-4 sm:w-5  text-white stroke-current motion-safe:animate-spin"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.636 3.636A9 9 0 0119 10"
                              strokeWidth={2}
                              strokeLinecap="round"
                            />
                          </svg>
                        ) : (
                          <></>
                        )}
                      </div> 
                    </div> */}

                    <img
                      src={member.profilePicUrl}
                      alt={member.name}
                      className="border-white h-8 sm:h-10 bg-white border-2 box-border rounded-full object-cover aspect-square"
                    />
                  </div>
                  <div className="flex w-full truncate space-y-1 flex-col items-start justify-center">
                    <p className="font-semibold leading-none text-black sm:leading-none sm:text-lg">
                      {member.name}
                    </p>
                    <p className=" text-black text-sm sm:text-base leading-tight">
                      Plan manager
                    </p>
                  </div>

                  <p className="uppercase text-gray-400">You</p>
                </div>
              ))}
          {members &&
            members.length > 0 &&
            members
              .filter((member) => member.id !== targetCustomer.id)
              .map((member, index) => (
                <div
                  key={member.id}
                  className="flex shrink-0 text-left group space-x-2 sm:space-x-4 overflow-hidden transition-all rounded-xl items-center w-full justify-start py-2"
                >
                  <div
                    className={`${
                      member.username === "humpty"
                        ? "bg-blue-300"
                        : // : member.isPaidSubscriber
                          // ? member.subscriptionType === 0
                          //   ? "bg-yellow-300"
                          //   : "bg-red-400"
                          "bg-gray-100/50"
                    } overflow-hidden shrink-0 p-1 sm:p-1.5 shadow relative rounded-full`}
                  >
                    <div className="inset-1 sm:inset-1.5 overflow-hidden rounded-full absolute">
                      <div className="sm:w-12 sm:h-12 w-8 h-8 border-white border-2 transition-all bg-opacity-0 flex items-center justify-center bg-black rounded-full">
                        {loadingRemoveCustomerFromSubscriptionPlan &&
                        values.customerId === member.id ? (
                          <svg
                            className="w-4 sm:w-5  text-white stroke-current motion-safe:animate-spin"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.636 3.636A9 9 0 0119 10"
                              strokeWidth={2}
                              strokeLinecap="round"
                            />
                          </svg>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <img
                      src={member.profilePicUrl}
                      alt={member.name}
                      className="border-white h-8 sm:h-10 bg-white border-2 box-border rounded-full object-cover aspect-square"
                    />
                  </div>
                  <div className="flex w-full truncate space-y-1 flex-col items-start justify-center">
                    <p className="font-semibold leading-none text-black sm:leading-none sm:text-lg">
                      {member.name}
                    </p>
                    <p className=" text-black text-sm sm:text-base leading-tight">
                      Active {moment(member.lastActiveAt).fromNow()}
                    </p>
                  </div>

                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      setValues({ ...values, customerId: member.id });
                      removeCustomerFromSubscriptionPlan({
                        variables: { customerId: member.id },
                      });
                    }}
                    disabled={loadingRemoveCustomerFromSubscriptionPlan}
                    className={`transition-all rounded-lg group-hover:bg-opacity-50 font-bold text-center  whitespace-nowrap py-2 px-0.5 sm:px-1 `}
                  >
                    <svg
                      className="w-5 fill-current text-red-600 hover:text-red-700 transition-all"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM5 11H15V9H5V11Z"
                      />
                    </svg>
                  </button>
                </div>
              ))}
        </div>

        {customerSubscription.subscriptionType !== 0 &&
          customerSubscription.numOfMembers <
            customerSubscription.maxNumOfMembers && (
            <button
              onClick={(e) => {
                shareCustSubInvite(e);
              }}
              type="button"
              className={`${
                isCaptionCopied ? " motion-safe:animate-popUp " : ""
              } text-white relative text-xl 2xl:text-2xl hover:bg-black overflow-hidden shadow-sm  group w-full font-bold uppercase flex transition-all items-center justify-center bg-blue-600 rounded-full pl-4 pr-3 py-2`}
            >
              <p
                className={`${
                  isCaptionCopied
                    ? "motion-safe:animate-fadeDownFast"
                    : hasCaptionBeenCopiedBefore
                    ? "motion-safe:animate-fadeOutUpFast"
                    : "hidden"
                } bg-black pb-3 text-white inset-0 pt-3.5 w-full absolute`}
              >
                {isSharingToApps ? "Shared!" : "Copied"}
              </p>

              <p
                className={`${
                  isCaptionCopied
                    ? "motion-safe:animate-fadeOutDownFast"
                    : hasCaptionBeenCopiedBefore
                    ? "motion-safe:animate-fadeUpFast"
                    : ""
                } py-2`}
              >
                Share Pro Code
              </p>
            </button>
          )}
        <p className="text-center w-full text-gray-600 mt-4 mb-0 sm:mb-6 text-base">
          Have a question?{" "}
          <a
            href="mailto:info@tknight.live"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:text-black transition-all"
          >
            Contact support
          </a>
        </p>
      </form>
    </div>
  );
}

export default EditProMembersForm;
