import React, { useEffect } from "react";
import DownloadAppPopup from "../../components/creator/DownloadAppPopup";
import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";
import { getMobileOperatingSystem } from "../../util/hooks";

export default function DownloadAppRedirect() {
  const OS = getMobileOperatingSystem();
  useEffect(() => {
    if (OS === "Android" || OS === "iOS") {
      const appStoreLink =
        OS === "iOS"
          ? "https://apps.apple.com/us/app/tknight/id6476206206"
          : "https://play.google.com/store/apps/details?id=live.tknight";

      window.location.href = appStoreLink;
    }
  }, [OS]);

  return (
    <div
      className={`h-full relative w-screen overflow-hidden min-h-screen bg-gray-50`}
    >
      <LiveStreamGradBack gradIndex={1} />
      <DownloadAppPopup
        toggleDownloadAppPopup={() => {}}
        title="Download TKnight"
      />
    </div>
  );
}
