import React from "react";
import { useDropzone } from "react-dropzone";

export default function ProductVideoInputField({
  title,
  value,
  name,
  onChangeMedia,
  editFunc,
  error,
  shouldEdit,
  values,
  previewVideoURL,
  setPreviewVideoURL,
}) {
  const onDrop = async (files) => {
    if (files && files.length > 0) {
      var fileType = files[0].type;

      onChangeMedia(name, files[0], fileType);

      const videoTempUrl = URL.createObjectURL(files[0]);

      setPreviewVideoURL(videoTempUrl);

      if (shouldEdit) {
        editFunc({
          variables: { ...values, fieldsToEdit: [name], [name]: files[0] },
        });
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: { "video/mp4": [".mp4"], "video/mov": [".mov"] },
    // maxSize: 10485760, //10 MB
    multiple: false,
  });

  // useEffect(() => {
  //   if (value) {
  //     setPreviewVideoURL(value);
  //   }
  // }, [value]);

  return (
    <div className="flex w-full items-start sm:flex-1 shrink-0 flex-col justify-start space-y-2 sm:text-base text-sm">
      <label className="font-semibold whitespace-nowrap truncate text-gray-700">
        {title} {error && <span className="text-red-500">{error}</span>}
      </label>
      <button
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
        })}
        type="button"
        className={`${
          isDragActive ? "bg-blue-700 animate-pulse" : ""
        } w-full group relative flex-shrink-0 transition-all aspect-video snap-center hover:text-gray-500 text-gray-400 flex items-center justify-center bg-gray-50 border border-gray-200 rounded-2xl hover:bg-gray-200  overflow-hidden p-2`}
      >
        <input {...getInputProps()} />

        <div className="inset-0  m-auto items-center flex justify-center absolute transition-opacity ">
          <svg
            className="h-10 m-auto fill-current text-gray-400 group-hover:text-gray-500 transition-all"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM2.99878 17.25L14.0588 6.19L17.8088 9.94L6.74878 21H2.99878V17.25Z"
            />
          </svg>
        </div>

        {(previewVideoURL || value) && (
          <video
            className="flex-grow-0 w-full h-full top-0 object-contain z-10"
            // controls={false}
            autoPlay={true}
            muted={true}
            loop={true}
          >
            <source src={previewVideoURL || value} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </button>
    </div>
  );
}
