import React, { useContext, useEffect, useState } from "react";

import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate, useParams } from "react-router";
import TextInputField from "../../components/creator/TextInputField";

import { useMutation } from "@apollo/client";
import BlueFlatGradBack from "../../components/creator/BlueFlatGradBack";
import SubmitButton from "../../components/creator/SubmitButton";

import LoginFooter from "../../components/creator/LoginFooter";
import TopBar from "../../components/creator/TopBar";
import { CustomerAuthContext } from "../../context/customerAuth";
import { GuestAuthContext } from "../../context/guestAuth";
import { LOGIN_CUSTOMER_MUTATION } from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";

function LoginCustomer(props) {
  usePageTracking();

  let navigate = useNavigate();

  const { prevPath } = useParams();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.title = "Login | TKnight";
  }, []);

  const {
    customer,

    loginCustomer: loginCustomerContext,
  } = useContext(CustomerAuthContext);

  const { guest, logoutGuest } = useContext(GuestAuthContext);

  const { onChange, values, setValues } = useForm(loginCustomerCallback, {
    email: "",
    phone: "",
    password: "",
    usingPhone: true,
    phoneCountryCode: "+1",
    phoneCountryShortForm: "CA",
  });

  const [didCustomerJustLogin, setDidCustomerJustLogin] = useState(false);

  const [loginCustomer, { loading }] = useMutation(LOGIN_CUSTOMER_MUTATION, {
    update(_, { data: { loginCustomer: customerData } }) {
      if (guest) {
        logoutGuest();
      }
      loginCustomerContext(customerData);
      setDidCustomerJustLogin(true);
    },
    onError(err) {
      console.log(err);
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
  });

  useEffect(() => {
    if (customer && !loading) {
      if (prevPath && didCustomerJustLogin) {
        navigate("/" + prevPath.trim().split(">").join("/"));
      } else {
        navigate("/");
      }
    }
  }, [customer, prevPath, navigate, didCustomerJustLogin, loading]);

  function loginCustomerCallback(e) {
    e.preventDefault();
    loginCustomer({
      variables: { ...values, phone: values.phoneCountryCode + values.phone },
    });
  }

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-gray-50">
      <BlueFlatGradBack gradIndex={0} />
      <div
        className={`short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl grow-0 relative h-full z-10 flex flex-col items-center justify-start mx-auto xl:px-0 px-4 pb-4 lg:pb-0 min-h-screen`}
      >
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          page="Login"
          prevPath={prevPath}
        />
        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-10 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
            Sign in to your account
          </p>

          <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6 mb-8">
            {values.usingPhone ? (
              <TextInputField
                title="Phone"
                name="phone"
                type="tel"
                placeholder=""
                error={errors.phone}
                disabled={loading}
                values={values}
                onChange={onChange}
                setValues={setValues}
                value={values.phone}
                phoneCodePrefix={
                  values.phoneCountryShortForm + " " + values.phoneCountryCode
                }
                phoneCountryCode={values.phoneCountryCode}
                actionBtnTitle={"Sign in with email or username"}
                onActionBtnPress={(e) => {
                  e.preventDefault();
                  setValues({ ...values, usingPhone: !values.usingPhone });
                }}
              />
            ) : (
              <TextInputField
                title="Email or username"
                name="email"
                type="email"
                error={errors.email || errors.name}
                disabled={loading}
                onChange={onChange}
                value={values.email}
                actionBtnTitle={"Sign in with phone"}
                onActionBtnPress={(e) => {
                  e.preventDefault();
                  setValues({ ...values, usingPhone: !values.usingPhone });
                }}
              />
            )}
            <TextInputField
              title="Password"
              name="password"
              type="password"
              error={errors.password}
              disabled={loading}
              onChange={onChange}
              value={values.password}
              showForgotPasswordLink={"/resetPassword"}
              navigate={navigate}
            />
          </div>

          <SubmitButton
            onClickFunc={loginCustomerCallback}
            disabled={loading}
            title={"Continue"}
            errors={errors}
          />

          <p className="text-center text-gray-600 mt-4 sm:mb-0 mb-2 text-sm sm:text-base">
            Don't have an account?{" "}
            <button
              onClick={(e) => {
                navigate(
                  "/register" + (prevPath ? "/redirect/" + prevPath : "")
                );
              }}
              className="text-blue-600 hover:text-black transition-all"
            >
              Sign up
            </button>
          </p>
        </div>

        <LoginFooter navigate={navigate} />
      </div>
    </div>
  );
}

export default LoginCustomer;
