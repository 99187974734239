import React, { useEffect, useState } from "react";

export default function VideoBox({ url }) {
  const [newUrl, setNewUrl] = useState("");
  const [key, setKey] = useState(0); // Key state for remounting the video element

  useEffect(() => {
    if (url && newUrl !== url) {
      setNewUrl(url);
      setKey(key + 1); // Update the key to trigger a remount
    }
  }, [url, newUrl, key]);

  return (
    <video
      id="adVideo"
      key={key} // Key prop for remounting
      className="flex-grow-0 w-full h-full top-0 object-cover z-10"
      controls={false}
      autoPlay={true}
      muted={true}
      loop={true}
    >
      <source src={newUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}
