import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isRunningInRNWebView } from "../../util/hooks";

export default function MobileFooter({ navigate, page, finishedLoading }) {
  const location = useLocation();
  const [lastPage, setLastPage] = useState(
    location.state?.from?.pathname
      ? location.state?.from?.pathname.split("/")[1]
      : null
  );

  useEffect(() => {
    if (location.state?.from?.pathname) {
      let tempLastPage = location.state?.from?.pathname;
      if (tempLastPage) {
        tempLastPage = tempLastPage.split("/")[1];
        if (tempLastPage) {
          setLastPage(tempLastPage);
        }
      }
    }
  }, [location]);

  return (
    <div
      className={`w-full text-sm fixed xshort:hidden lg:hidden h-[5.3rem] short:h-[4rem] px-6 bottom-0 bg-gradient-to-t  inset-x-0 z-10 grid grid-cols-3 ${
        page === "topic"
          ? "text-black from-gray-300 to-transparent via-gray-300/80"
          : "text-white from-black to-transparent via-black/60"
      }`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          navigate("/focus", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className="short:h-6 h-8 rounded-full overflow-hidden"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="16"
            cy="22.4"
            r="8"
            className={`${
              page === "focus" || lastPage === "focus" ? "block" : "hidden"
            } ${
              page === "focus"
                ? !finishedLoading
                  ? "translate-y-[120%]"
                  : `animate-moveUpFast translate-y-[120%] ${
                      isRunningInRNWebView && !lastPage
                        ? "animation-delay-[1s]"
                        : ""
                    }`
                : "motion-safe:animate-scaleOut"
            } fill-current origin-bottom`}
          />
          <circle
            cx="16"
            cy="16"
            r="15"
            className="stroke-current"
            strokeWidth="2"
          />
        </svg>

        <p className="leading-none text-center tracking-wide">Focus</p>
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          navigate("/search", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className="short:h-6 h-8 stroke-current"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="30" height="30" rx="5" strokeWidth="2" />
          <circle
            cx="16"
            cy="16"
            r="5"
            className={`${
              page === "search" || lastPage === "search" ? "block " : "hidden"
            } ${
              page === "search" || page === "topic"
                ? "animate-moveUpFast translate-y-[120%] animation-delay-[0.25s]"
                : "animate-scaleOut"
            } fill-current origin-bottom`}
          />
        </svg>

        <p className="leading-none text-center tracking-wide">Topics</p>
      </button>

      <button
        onClick={(e) => {
          e.preventDefault();
          navigate("/tkl", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className="short:h-6 h-8 rounded-full"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="16"
            cy="22.4"
            r="8"
            className={`${
              page === "tkl" || lastPage === "tkl" ? "block" : "hidden"
            } ${
              page === "tkl"
                ? "animate-moveUpFast translate-y-[120%] animation-delay-[0.25s]"
                : "animate-scaleOut"
            } fill-current origin-bottom`}
          />

          <circle
            cx="16"
            cy="16"
            r="15"
            className="stroke-current"
            strokeWidth="2"
          />
        </svg>

        <p className="leading-none text-center tracking-wide">LIVE</p>
      </button>
    </div>
  );
}
