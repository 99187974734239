import React, { useEffect, useState } from "react";

export default function HorizontalTimer({ endTime, lastXSeconds }) {
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  useEffect(() => {
    // Calculate the remaining seconds until the endTime
    const now = new Date();
    const difference = endTime - now;
    const seconds = Math.ceil(difference / 1000);
    setRemainingSeconds(seconds >= 0 ? seconds : 0);

    // Update the timer every second
    const timer = setInterval(() => {
      const newSeconds = remainingSeconds - 1;
      setRemainingSeconds(newSeconds >= 0 ? newSeconds : 0);
    }, 1000);

    // Clear the interval when the component is unmounted or remainingSeconds reaches 0
    return () => clearInterval(timer);
  }, [endTime, remainingSeconds]);

  const progressBarWidth = (remainingSeconds / lastXSeconds) * 100 + "%";

  return (
    <div
      className="absolute top-0  left-0 h-full bg-blue-800 group-hover:bg-blue-600 transition-all"
      style={{ width: progressBarWidth }}
    ></div>
  );
}
