import React from "react";

export default function WinsIcon({ width }) {
  return (
    <svg
      className={`aspect-square ml-0.5 ${width || "w-4"}`}
      viewBox="0 0 s s"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2H14V0H4V2H2C0.9 2 0 2.9 0 4V5C0 7.55 1.92 9.63 4.39 9.94C5.02 11.44 6.37 12.57 8 12.9V16H4V18H14V16H10V12.9C11.63 12.57 12.98 11.44 13.61 9.94C16.08 9.63 18 7.55 18 5V4C18 2.9 17.1 2 16 2ZM2 5V4H4V7.82C2.84 7.4 2 6.3 2 5ZM16 5C16 6.3 15.16 7.4 14 7.82V4H16V5Z"
        fill="#FFA439"
      />
      <path d="M11 3L10 2H12L11 5V3Z" fill="white" />
      <rect x="4" y="17.5" width="10" height="0.5" fill="black" />
    </svg>
  );
}
