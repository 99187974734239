import { useQuery } from "@apollo/client";
import React from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";
import { FETCH_PLAYER_TOPIC_STATS } from "../../util/gqlFunctions";
import { useWindowSize } from "../../util/hooks";
import CircularProgressBar from "./CircularProgressBar";
import EmptyCircularProgressBar from "./EmptyCircularProgressBar";

function TopicLevelCircle({
  topic,
  targetCustomer,
  shouldShowCaption,
  radius,
}) {
  const { data: { getPlayerTopicStatsByTopic: playerTopicStats } = {} } =
    useQuery(FETCH_PLAYER_TOPIC_STATS, {
      client: targetCustomer ? customerClient : guestClient,
      variables: {
        topicId: topic ? topic.id : "",
      },
    });
  const size = useWindowSize();

  return playerTopicStats && topic ? (
    <CircularProgressBar
      percentage={Math.floor(
        playerTopicStats && topic
          ? ((playerTopicStats.totalQsAnsweredCorrect || 0) /
              topic.numOfQuestions) *
              100
          : 0
      )}
      text={playerTopicStats ? playerTopicStats.totalQsAnsweredCorrect : 0}
      radius={radius ? radius : size.height <= 672 ? 18 : 20}
      shouldShowCaption={shouldShowCaption}
    />
  ) : (
    <EmptyCircularProgressBar
      radius={radius ? radius : size.height <= 672 ? 18 : 20}
    />
  );
}

export default TopicLevelCircle;
