import moment from "moment";
import React from "react";
import { defaultPicUrl } from "../../util/hooks";
import RateForumPostBox from "./RateForumPostBox";
import ReplyDrawer from "./ReplyDrawer";

export default function CommentBox({
  comment,
  index,
  targetCustomer,
  values,
  setValues,
  isFloating,
  navigate,
  newReplies,
}) {
  //   const [hideComment, { loading: loadingHideComment }] = useMutation(
  //     HIDE_COMMENT_MUTATION,
  //     {
  //       update(_, { data: { hideComment: hiddenComment } }) {},
  //       onError(err) {
  //         console.log(err);
  //       },
  //       variables: { commentId: comment.id },
  //       client: customerClient,
  //     }
  //   );

  return (
    <div
      key={comment.id}
      className="flex text-left shrink-0 w-full items-start justify-start mb-4"
    >
      <div
        className={`${
          comment.id === values.forumPostRepliedToId
            ? "bg-blue-300"
            : "bg-gray-100/50"
        } flex-shrink-0 mr-2 2xl:mr-4 transition-all overflow-hidden short:p-[.2rem] p-0.5 2xl:p-1 rounded-full aspect-square w-9 2xl:w-14 short:w-8 flex`}

        // hover:bg-blue-700
      >
        <img
          src={comment.profilePicUrl || defaultPicUrl}
          alt={comment.displayName}
          className="border-white bg-white short:border-[1.5px] border-2 box-border rounded-full object-cover aspect-square"
        />
      </div>

      <div className="flex-1 w-full ">
        <p className="font-semibold 2xl:text-lg short:text-xs text-sm leading-tight w-full mb-1.5">
          {comment.displayName}
        </p>
        <p className="text-lg line-clamp-5 2xl:text-2xl short:text-sm leading-tight w-full mb-1.5">
          {comment.details}
        </p>
        <div className="flex 2xl:text-lg short:text-xs text-sm items-center justify-start space-x-2">
          <p className="leading-tight">{moment(comment.createdAt).fromNow()}</p>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (values.forumPostRepliedToId === comment.id) {
                setValues({
                  ...values,
                  repliedToDisplayName: null,
                  forumPostRepliedToId: null,
                  type: 0,
                });
              } else {
                setValues({
                  ...values,
                  repliedToDisplayName: comment.displayName,
                  forumPostRepliedToId: comment.id,
                  type: 3,
                });
              }
            }}
            className="hover:text-gray-300 transition-all leading-tight font-semibold"
          >
            Reply
          </button>
          <div className="flex-1"></div>
          <RateForumPostBox
            forumPostId={comment.id}
            targetCustomer={targetCustomer}
            isFloating={isFloating}
            isMini={true}
          />
        </div>

        {comment.rNumOfComments && comment.type === 0 ? (
          <ReplyDrawer
            comment={comment}
            navigate={navigate}
            setValues={setValues}
            values={values}
            targetCustomer={targetCustomer}
            newReplies={newReplies}
            isFloating={isFloating}
          />
        ) : (
          <></>
        )}
      </div>
      {/* {targetCustomer && targetCustomer.isCommentMod ? (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            hideComment({ variables: { commentId: comment.id } });
          }}
          disabled={loadingHideComment}
          className="text-white  px-5 py-1 hover:text-opacity-50 transition-all"
        >
          <svg
            className="w-5 2xl:w-7 fill-current"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.5 3.5H13.5L13.9 5.5H19.5V15.5H12.5L12.1 13.5H6.5V20.5H4.5V3.5ZM12.26 7.5L11.86 5.5H6.5V11.5H13.74L14.14 13.5H17.5V7.5H12.26Z"
            />
          </svg>
        </button>
      ) : (
        <></>
      )} */}
    </div>
  );
}
