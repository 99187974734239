import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import CommentBox from "./CommentBox";

function JustModalComments({
  targetCustomer,
  targetGuestCustomer,
  comments,
  setValues,
  values,
  navigate,
  country,

  questionId,
  newComments,
  headCommentToNewReplies,
  loadingComments,
  fetchMoreComments,
  fetchMoreParams,
}) {
  // const [lastQuestionId, setLastQuestionId] = useState(null);

  // const [hideComment, { loading: loadingHideComment }] = useMutation(
  //   HIDE_COMMENT_MUTATION,
  //   {
  //     update(_, { data: { hideComment: hiddenComment } }) {},
  //     onError(err) {},
  //     variables: values,
  //     client: customerClient,
  //   }
  // );

  // function hideCommentCallback(e) {
  //   e.preventDefault();
  //   hideComment();
  // }

  const commentsContainerRef = useRef();

  useLayoutEffect(() => {
    // Scroll to the bottom after rendering
    if (
      comments &&
      comments.length > 0 &&
      commentsContainerRef &&
      commentsContainerRef.current
    ) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [comments]);

  useEffect(() => {
    if (
      comments &&
      comments.length > 0 &&
      commentsContainerRef &&
      commentsContainerRef.current
    ) {
      var timer = setTimeout(() => {
        // Scroll to the bottom after a delay

        commentsContainerRef.current.scrollTop =
          commentsContainerRef.current.scrollHeight;
      }, 200); // Adjust the delay time as needed
    }
    return () => clearTimeout(timer);
  }, [comments]);

  const [fetchMoreCommentsRef, isFetchMoreCommentsVisible] = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (
      !loadingComments &&
      isFetchMoreCommentsVisible &&
      comments &&
      comments.length > 10
    ) {
      // console.log("ji");
      const newSeenIds = comments.map((f) => f.id);

      // setValues({ ...values, seenIds: newSeenIds });
      fetchMoreComments({
        variables: {
          ...fetchMoreParams,
          seenIds: newSeenIds,
        },

        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newCommentRecs =
            fetchMoreResult.getRecommendedForumPostsBySearch;

          return {
            ...prev,

            getRecommendedForumPostsBySearch: [...comments, ...newCommentRecs],
          };
        },
      });
    }
  }, [
    isFetchMoreCommentsVisible,

    fetchMoreComments,
    comments,
    fetchMoreParams,
    loadingComments,
  ]);

  return (
    <div className="relative h-full overflow-x-hidden overflow-y-auto flex w-full text-black flex-col items-start">
      <div
        ref={commentsContainerRef}
        className="motion-safe:animate-fadeIn scroll-smooth h-full overflow-y-auto flex w-full text-black flex-col items-start"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setValues({
            ...values,
            commentRepliedToId: null,
            repliedToUsername: null,
            // message:
            //   "@" + comment.commentorUsername + ": " + values.message,
          });
        }}
      >
        {(comments && comments.length > 0
          ? [...newComments, ...comments]
          : [...newComments]
        ).map((comment, index) => (
          <div
            className="flex items-center justify-start w-full flex-col"
            key={comment.id}
          >
            {comment.details ? (
              <CommentBox
                comment={comment}
                targetCustomer={targetCustomer}
                index={index}
                values={values}
                setValues={setValues}
                navigate={navigate}
                newReplies={headCommentToNewReplies[comment.id] || []}
              />
            ) : (
              <></>
            )}
          </div>
        ))}
        <div ref={fetchMoreCommentsRef}></div>
      </div>
      <div className="h-4 w-full bg-gradient-to-t from-white to-transparent absolute bottom-0 inset-x-0"></div>
    </div>
  );
}

export default JustModalComments;
