import { useMutation } from "@apollo/client";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { customerClient } from "../../GraphqlApolloClients";
import AttachedQuizCommentBox from "./AttachedQuizCommentBox";

import {
  COMMENTS_SUBSCRIPTION,
  HIDE_COMMENT_MUTATION,
} from "../../util/gqlFunctions";
import QRCodeBox from "./QRCodeBox";

function JustComments({
  subscribeToNewComments,
  targetCustomer,

  comments,

  values,
  navigate,
  country,

  quizId,
  shouldOpenQRCodeBox,

  toggleShowQRCode,

  quiz,
  triviaNightId,
  productId,
}) {
  const [startedCommentsSubscription, setStartedCommentsSubscription] =
    useState(null);

  useEffect(() => {
    let subscription = null;
    if (
      (triviaNightId || quizId || productId) &&
      startedCommentsSubscription !== (triviaNightId || quizId || productId)
    ) {
      if (
        startedCommentsSubscription &&
        subscription &&
        typeof subscription.unsubscribe === "function"
      ) {
        subscription.unsubscribe();
      }

      subscription = subscribeToNewComments({
        document: COMMENTS_SUBSCRIPTION,
        variables: { eventId: triviaNightId || quizId || productId },

        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const newComment = subscriptionData.data.commentAdded;
          const newArray = prev.getCommentsByEvent.filter(
            (obj) => obj.id !== newComment.id
          );

          return {
            ...prev,
            getCommentsByEvent: [...newArray, newComment],
          };
        },
      });

      setStartedCommentsSubscription(triviaNightId || quizId || productId);
    }
    return () => {
      if (subscription && typeof subscription.unsubscribe === "function") {
        subscription.unsubscribe();
      }
    };
  }, [
    subscribeToNewComments,
    startedCommentsSubscription,
    quizId,
    productId,
    triviaNightId,
  ]);

  const [hideComment, { loading: loadingHideComment }] = useMutation(
    HIDE_COMMENT_MUTATION,
    {
      update(_, { data: { hideComment: hiddenComment } }) {},
      onError(err) {},
      variables: values,
      client: customerClient,
    }
  );

  const commentsContainerRef = useRef();

  useLayoutEffect(() => {
    // Scroll to the bottom after rendering
    if (
      comments &&
      comments.length > 0 &&
      commentsContainerRef &&
      commentsContainerRef.current
    ) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [comments]);

  useEffect(() => {
    if (
      comments &&
      comments.length > 0 &&
      commentsContainerRef &&
      commentsContainerRef.current
    ) {
      var timer = setTimeout(() => {
        // Scroll to the bottom after a delay

        commentsContainerRef.current.scrollTop =
          commentsContainerRef.current.scrollHeight;
      }, 200); // Adjust the delay time as needed
    }
    return () => clearTimeout(timer);
  }, [comments]);

  return (
    <div className="relative h-full overflow-x-hidden overflow-y-auto flex w-full text-white flex-col items-start">
      <div
        ref={commentsContainerRef}
        className="z-10 mb-4 short:mb-2 space-y-2 motion-safe:animate-fadeIn scroll-smooth rounded-xl h-full overflow-y-auto flex w-full text-white flex-col items-start"
      >
        {comments &&
          comments.length > 0 &&
          comments.map((comment, index) => (
            <div
              className="flex items-center justify-start w-full flex-col"
              key={comment.id}
            >
              {comment.message ? (
                <div
                  key={comment.id}
                  className="flex shrink-0 w-full items-start justify-start"
                >
                  <div
                    className={`${
                      comment.commentorUsername === "humpty"
                        ? "bg-blue-300"
                        : // : comment.subscriptionType === 0
                          // ? "bg-yellow-300"
                          // : comment.subscriptionType === 1
                          // ? "bg-red-400"
                          "bg-gray-100/50"
                    } flex-shrink-0 mr-2 2xl:mr-4 transition-all overflow-hidden short:p-[.2rem] p-0.5 2xl:p-1 rounded-full aspect-square w-8 2xl:w-10 short:w-8 flex`}
                    key={index}
                    // hover:bg-blue-700
                  >
                    <img
                      src={comment.commentorProfilePicUrl}
                      alt={comment.commentorUsername}
                      className="border-white bg-white short:border-[1.5px] border-2 box-border rounded-full object-cover aspect-square"
                    />
                  </div>

                  {comment.attachedQuizId ? (
                    <AttachedQuizCommentBox
                      comment={comment}
                      navigate={navigate}
                      country={country}
                      customer={targetCustomer}
                    />
                  ) : (
                    <p className="flex-1 mt-1 text-lg 2xl:text-2xl short:text-sm leading-tight w-full">
                      {comment.repliedToUsername ? (
                        <span className="font-semibold">
                          @{comment.repliedToUsername}{" "}
                        </span>
                      ) : (
                        <></>
                      )}
                      {comment.highlightedPrefix ? (
                        <span className="font-semibold">
                          {comment.highlightedPrefix}{" "}
                        </span>
                      ) : (
                        <></>
                      )}
                      {comment.message}
                      {comment.deltaXP ? (
                        <span className="font-semibold">
                          {comment.deltaXP > 0 ? " +" : " "}
                          {Math.round(comment.deltaXP * 1000)} XP
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  )}
                  {targetCustomer && targetCustomer.isCommentMod ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();

                        hideComment({ variables: { commentId: comment.id } });
                      }}
                      disabled={loadingHideComment}
                      className="text-white px-5 py-1 hover:text-opacity-50 transition-all"
                    >
                      <svg
                        className="w-5 2xl:w-7 fill-current"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.5 3.5H13.5L13.9 5.5H19.5V15.5H12.5L12.1 13.5H6.5V20.5H4.5V3.5ZM12.26 7.5L11.86 5.5H6.5V11.5H13.74L14.14 13.5H17.5V7.5H12.26Z"
                        />
                      </svg>
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
      </div>
      <QRCodeBox
        quizId={quiz ? quiz.id : ""}
        toggleShowQRCode={toggleShowQRCode}
        shouldOpenQRCodeBox={shouldOpenQRCodeBox}
      />
    </div>
  );
}

export default JustComments;
