import React, { useEffect, useRef, useState } from "react";

export default function MultiSelectTextWSearchInputField({
  error,
  name,
  values,
  targetAd,
  setValues,
  setFieldsToEdit,
  setTextInputValue,
  options,
  edit,
  loading,
  textInputValue,
  title,
  canEdit,
  value,
  fieldsToEdit,
}) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownRef = useRef(null);

  // Function to handle clicks outside the component
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownVisible(false);
    }
  };
  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between text-sm sm:text-base">
        <label htmlFor={name} className="font-semibold text-gray-700 ">
          {title}{" "}
          {error &&
            (!fieldsToEdit ||
              (fieldsToEdit && fieldsToEdit.includes(name))) && (
              <span className="text-red-500">{error}</span>
            )}
        </label>
        {canEdit && fieldsToEdit && fieldsToEdit.length === 0 && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setValues({
                ...values,
                fieldsToEdit: [name],
              });
            }}
            className="text-blue-500 transition-all font-semibold hover:text-blue-700"
          >
            Edit
          </button>
        )}
        {fieldsToEdit && fieldsToEdit.includes(name) && (
          <div className="flex items-center justify-end space-x-2">
            <button
              onClick={(e) => {
                e.preventDefault();
                setFieldsToEdit(name);
                setTextInputValue("");
                setIsDropdownVisible(false);
              }}
              className="text-red-500 transition-all font-semibold hover:text-red-700"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                edit();
                setTextInputValue("");
                setIsDropdownVisible(false);
              }}
              className="text-green-500 transition-all font-semibold hover:text-green-700"
            >
              Save
            </button>
          </div>
        )}
      </div>

      {value && value.length > 0 && (
        <div className={`gap-2 flex-wrap flex items-center justify-start mt-2`}>
          {value.map((option, index) => (
            <div
              key={index}
              className={`${
                fieldsToEdit && fieldsToEdit.includes(name)
                  ? "pl-4 pr-3"
                  : "pl-4 pr-2"
              } rounded-full flex items-center space-x-2 transition-all justify-start border-blue-600 bg-blue-50 overflow-hidden py-1`}
            >
              <p>{options[option]}</p>
              <button
                onClick={(e) => {
                  e.preventDefault();

                  const optionsCopy = value ? [...value] : [];
                  if (optionsCopy[index]) {
                    optionsCopy.splice(index, 1);
                  }
                  setValues({ ...values, [name]: optionsCopy });
                }}
                disabled={
                  loading || (fieldsToEdit && !fieldsToEdit.includes(name))
                }
                className="text-gray-400 disabled:opacity-50 hover:text-gray-500 transition-all"
              >
                {fieldsToEdit && fieldsToEdit.includes(name) && (
                  <svg
                    className="w-4 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                  </svg>
                )}
              </button>
            </div>
          ))}
        </div>
      )}
      {fieldsToEdit && fieldsToEdit.includes(name) && (
        <input
          className={`sm:text-lg mt-3 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
          type={"text"}
          value={textInputValue}
          onFocus={() => setIsDropdownVisible(true)} // Toggle the drop-down visibility
          // onBlur={() => setIsDropdownVisible(false)}
          onChange={(e) => {
            const value = e.target.value;
            setTextInputValue(value);
          }}
          ref={dropdownRef}
          placeholder={""}
          disabled={loading || (fieldsToEdit && !fieldsToEdit.includes(name))}
          maxLength={200}
          list={"options"}
        />
      )}
      {/* Show the drop-down only if isDropdownVisible is true */}
      {isDropdownVisible || textInputValue ? (
        <div className="relative w-full">
          <div className="max-h-40 rounded overscroll-contain shadow-xl w-full overflow-y-auto absolute bg-white z-50">
            {Object.keys(options)
              .filter((option) =>
                options[option]
                  .toLowerCase()
                  .includes(textInputValue.toLowerCase())
              )
              .map((option, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (value) {
                      if (value.includes(option)) {
                      } else {
                        setValues({
                          ...values,
                          [name]: [...value, option],
                        });
                      }
                    } else {
                      setValues({
                        ...values,
                        [name]: [option],
                      });
                    }
                    setTextInputValue("");
                    setIsDropdownVisible(false); // Close the drop-down after selecting a option
                  }}
                  className="w-full flex items-center justify-between text-lg hover:bg-gray-50 text-left py-2 px-3"
                >
                  <p>{options[option]}</p>
                  {value && value.includes(option) && (
                    <svg
                      className="fill-current h-4"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8.79508 15.875L4.62508 11.705L3.20508 13.115L8.79508 18.705L20.7951 6.70498L19.3851 5.29498L8.79508 15.875Z" />
                    </svg>
                  )}
                </button>
              ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
