import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { useInView } from "react-intersection-observer";
import { adminClient } from "../../GraphqlApolloClients";

import {
  EDIT_QUESTION_MUTATION,
  FETCH_QUESTIONS,
  FETCH_TOPIC,
} from "../../util/gqlFunctions";

function AdminQuestionBox({
  index,

  question,
  questionIndexToScrollIntoView,

  setQuestionIndexInView,
  setTargetQuestion,
  disabled,
}) {
  const orgRef = useRef();

  const {
    ref: boxRef,
    inView: isBoxVisible,
    // entry,
  } = useInView({
    /* Optional options */
    threshold: 0.7,
  });

  const [confirmedReject, setConfirmedReject] = useState(false);
  useEffect(() => {
    if (index === questionIndexToScrollIntoView) {
      orgRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [questionIndexToScrollIntoView, index]);

  useEffect(() => {
    if (isBoxVisible && !disabled) {
      setQuestionIndexInView(index);
    }
  }, [isBoxVisible, disabled, index, setQuestionIndexInView]);

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const resetInterval = 1000;

  useEffect(() => {
    let timeout;
    if (isLinkCopied && resetInterval) {
      timeout = setTimeout(() => setIsLinkCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isLinkCopied, resetInterval]);

  const { data: { getTopicById: topic } = {} } = useQuery(FETCH_TOPIC, {
    variables: { topicId: question ? question.topicId : "" },
  });

  const [editQuestion, { loading: loadingEditQuestion }] = useMutation(
    EDIT_QUESTION_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_QUESTIONS,
          variables: {
            seenIds: [],
            pageSize: 10,
          },
        },
      ],
      update(_, { data: { editQuestion: editedQuestion } }) {
        console.log("jii");
      },
      onError(err) {
        console.log(err);
        // if (err.graphQLErrors[0]) {
        //   console.log(err.graphQLErrors[0].extensions.errors);

        //   setErrors({
        //     ...err.graphQLErrors[0].extensions.errors,
        //   });
        // }
      },
      client: adminClient,
    }
  );
  // const [errors, setErrors] = useState({});

  // const { values } = useForm(editOrgCallback, {
  //   orgId: question.id,
  //   name: question.name,
  //   shortForm: question.shortForm,
  //   city: question.city,
  //   province: question.province,
  //   country: question.country,
  //   type: question.type,
  //   fieldsToEdit: [],
  //   rejectionReason:
  //     "Please make sure all the provided details about your organization are accurate and reapply.",
  //   ...question,
  // });

  // const [approveOrg] = useMutation(APPROVE_PRODUCT_MUTATION, {
  //   // refetchQueries: [{ query: FETCH_RECOMMENDED_ORGS_BY_SEARCH }],
  //   update(_, { data: { approveOrg: approvedOrg } }) {
  //     question = { ...question, ...approvedOrg };
  //     console.log(errors);
  //   },
  //   onError(err) {
  //     console.log(err);
  //     // setValues({ ...values, shouldDelete: false });
  //     if (err.graphQLErrors[0]) {
  //       setErrors({
  //         ...err.graphQLErrors[0].extensions.errors,
  //       });
  //     }
  //   },
  //   variables: { orgId: question.id },
  //   client: adminClient,
  // });

  // console.log(question.status === 0 && question.id);
  return (
    <div ref={orgRef} className="w-full shrink-0">
      <div
        ref={boxRef}
        className="w-full sm:px-10 px-5 snap-center shrink-0 pb-5 pt-4 sm:pb-10 sm:pt-9 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl short:px-2 short:py-2"
      >
        <div className="flex items-center space-x-2 justify-start w-full truncate">
          {topic && topic.logoUrl && (
            <img
              src={topic.logoUrl}
              className="h-12 aspect-square object-cover object-center origin-center rounded-md mr-1"
              alt={topic.name}
            />
          )}
          <div className="flex-1 w-full flex flex-col items-start justify-start truncate">
            <p className="font-semibold text-gray-700 text-xl short:text-lg leading-tight short:leading-tight w-full truncate ">
              {question.topicName}
            </p>
            <p className=" text-gray-500 leading-tight">
              Edited {moment(question.lastEditedAt).fromNow()}
            </p>
          </div>

          {/* <a
            href={"/question/" + question.shortForm}
            target="_blank"
            rel="noreferrer"
            className="bg-black shrink-0 transition-all w-12 hover:bg-opacity-50 aspect-square rounded-full "
          >
            <svg
              className="p-3 fill-current text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 5V19H19V12H21V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H12V5H5ZM14 5V3H21V10H19V6.41L9.17 16.24L7.76 14.83L17.59 5H14Z"
              />
            </svg>
          </a> */}
        </div>
        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-4 w-full rounded-lg border-gray-200 border">
          <p>ID</p>
          <p>{question.id}</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Creator</p>
          <p>{question.createdByCustomerId}</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Accuracy</p>
          <p>{question.rAccuracy}%</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Avr time</p>
          <p>{question.rAvrTimeTaken}s</p>
        </div>
        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Corr ans time </p>
          <p>{question.rAvrTimeTakenForCorrectAns}s</p>
        </div>
        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Corr ans best time </p>
          <p>{question.rLeastTimeTakenForCorrectAns}s</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Net vote</p>
          <p>{question.rNetVote}</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Num of votes</p>
          <p>{question.rNumOfVotes}</p>
        </div>
        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Upvote %</p>
          <p>{question.rUpvotePercentage}%</p>
        </div>
        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Corr attempt ratio</p>
          <p>
            {question.rNumOfCorrectAttempts}/{question.rNumOfAttempts}
          </p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Status</p>
          <p>
            {question.status === -2
              ? "Invalid"
              : question.status === -1
              ? "Rejected"
              : question.status === 0
              ? "Editing"
              : "LIVE"}
          </p>
        </div>

        {/* {question.status <= 4 && question.status !== -2 && ( */}
        <div className="mt-4 text-center sm:text-lg flex items-center  text-gray-600 justify-start w-full overflow-hidden rounded-lg border-gray-200 border">
          {/* {question.status === 0 && ( */}
          <button
            onClick={(e) => {
              e.preventDefault();
              if (confirmedReject) {
                editQuestion({
                  variables: {
                    questionId: question.id,
                    fieldsToEdit: ["status"],
                    status: -1,
                  },
                });
              } else {
                setConfirmedReject(true);
              }
              // toggleMakeRevisionPaymentRequestForm(index);
            }}
            onBlur={() => {
              if (confirmedReject) {
                setConfirmedReject(false);
              }
            }}
            className={`${
              confirmedReject
                ? "bg-red-600 text-white hover:bg-red-700"
                : "hover:bg-red-50"
            } px-4 border-r border-gray-200 shrink-0 py-3 font-semibold   transition-all`}
          >
            {loadingEditQuestion ? (
              <svg
                className={`h-7 m-auto stroke-current text-white motion-safe:animate-spin`}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.636 3.636A9 9 0 0119 10"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            ) : (
              <p className="">{question.status === 0 ? "Reject" : "Archive"}</p>
            )}
          </button>
          {/* )} */}

          {(question.status === 0 ||
            question.status === -1 ||
            question.status === -2) && (
            <button
              onClick={(e) => {
                e.preventDefault();
                editQuestion({
                  variables: {
                    questionId: question.id,
                    fieldsToEdit: ["status"],
                    status: 1,
                  },
                });
                // toggleMakeRevisionPaymentRequestForm(index);
              }}
              className={`px-4 shrink-0  border-r border-gray-200 py-3 font-semibold  hover:bg-red-50 transition-all`}
            >
              <p className="">Approve</p>
            </button>
          )}

          <button
            // onClick={copyTrackingLink}
            onClick={(e) => {
              e.preventDefault();
              setTargetQuestion(index);
            }}
            className={` ${
              question.status === 0 ? "border-x" : ""
            } flex-1 flex items-center justify-center relative font-semibold border-gray-200 hover:bg-gray-50 transition-all`}
          >
            <p className={`py-3`}>Edit</p>
          </button>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default AdminQuestionBox;
