import React, { useEffect, useRef, useState } from "react";
import TopicLineBox from "./TopicLineBox";

export default function MultiSelectAndOrderTopicsWSearch({
  error,
  name,
  values,

  setValues,

  options,

  loading,

  title,
  value,
  disabled,
}) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownRef = useRef(null);

  // Function to handle clicks outside the component
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownVisible(false);
    }
  };

  const deleteTopic = (index) => {
    let tempTopicIds = values.topicIds;
    tempTopicIds.splice(index, 1);
    setValues({ ...values, topicIds: tempTopicIds });
  };

  const moveTopicDown = (index) => {
    // Swap the topics
    let tempTopicIds = [...values.topicIds]; // Create a copy of topicIds array

    // Swap the elements
    [tempTopicIds[index], tempTopicIds[index + 1]] = [
      tempTopicIds[index + 1],
      tempTopicIds[index],
    ];

    setValues({ ...values, topicIds: tempTopicIds });
  };

  const moveTopicUp = (index) => {
    // Swap the topics
    let tempTopicIds = [...values.topicIds]; // Create a copy of topicIds array

    // Swap the elements
    [tempTopicIds[index], tempTopicIds[index - 1]] = [
      tempTopicIds[index - 1],
      tempTopicIds[index],
    ];

    setValues({ ...values, topicIds: tempTopicIds });
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="w-full mb-4">
      <div className="flex items-center justify-between text-sm sm:text-base">
        <label htmlFor={name} className="font-semibold text-gray-700 ">
          {title} {error && <span className="text-red-500">{error}</span>}
        </label>
      </div>

      {!disabled && (
        <input
          className={`sm:text-lg mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
          type={"text"}
          value={values.searchTerm}
          onFocus={() => setIsDropdownVisible(true)} // Toggle the drop-down visibility
          // onBlur={() => setIsDropdownVisible(false)}
          onChange={(e) => {
            const newSearchTerm = e.target.value;

            setValues({ ...values, searchTerm: newSearchTerm });
          }}
          ref={dropdownRef}
          placeholder={""}
          disabled={loading}
          maxLength={200}
          list={"options"}
        />
      )}

      {/* Show the drop-down only if isDropdownVisible is true */}
      {isDropdownVisible || values.searchTerm ? (
        <div className="relative w-full">
          <div className="max-h-40 rounded overscroll-contain shadow-xl w-full overflow-y-auto absolute bg-white z-50">
            {options &&
              options
                // .filter((option) =>
                //   option.toLowerCase().includes(values.searchTerm.toLowerCase())
                // )
                .map((option, index) => (
                  <button
                    key={option.id}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (value) {
                        // if (value.includes(option.id)) {
                        // } else {
                        //   // setValues({
                        //   //   ...values,
                        //   //   [name]: [...value, option],
                        //   //   searchTerm: "",
                        //   // });

                        let tempValue = [...value];
                        if (value.length >= 7) {
                          tempValue = tempValue.slice(-6);
                        }

                        if (values.fieldsToEdit) {
                          const newFieldsToEdit = values.fieldsToEdit.includes(
                            name
                          )
                            ? [...values.fieldsToEdit]
                            : [...values.fieldsToEdit, name];
                          setValues({
                            ...values,
                            [name]: [...tempValue, option.id],
                            searchTerm: "",
                            fieldsToEdit: newFieldsToEdit,
                          });
                        } else {
                          setValues({
                            ...values,
                            [name]: [...tempValue, option.id],
                            searchTerm: "",
                          });
                        }
                        // }
                      } else {
                        // setValues({
                        //   ...values,
                        //   [name]: [option],
                        //   searchTerm: "",
                        // });

                        if (values.fieldsToEdit) {
                          const newFieldsToEdit = values.fieldsToEdit.includes(
                            name
                          )
                            ? [...values.fieldsToEdit]
                            : [...values.fieldsToEdit, name];
                          setValues({
                            ...values,
                            [name]: [option.id],
                            searchTerm: "",
                            fieldsToEdit: newFieldsToEdit,
                          });
                        } else {
                          setValues({
                            ...values,
                            [name]: [option.id],
                            searchTerm: "",
                          });
                        }
                      }
                      // setValues({ ...values, searchTerm: "" });
                      setIsDropdownVisible(false); // Close the drop-down after selecting a option
                    }}
                    className="w-full flex items-center justify-between text-lg hover:bg-gray-50 text-left py-2 px-3"
                  >
                    <p>{option.name}</p>
                    {/* {value && value.includes(option.id) && (
                      <svg
                        className="fill-current h-4"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M8.79508 15.875L4.62508 11.705L3.20508 13.115L8.79508 18.705L20.7951 6.70498L19.3851 5.29498L8.79508 15.875Z" />
                      </svg>
                    )} */}
                  </button>
                ))}
          </div>
        </div>
      ) : (
        <></>
      )}

      {value && value.length > 0 && (
        <div
          className={`gap-2 flex-col w-full flex items-center justify-start mt-2`}
        >
          {value.map((option, index) => (
            <TopicLineBox
              topicId={option}
              key={index}
              index={index}
              deleteTopic={deleteTopic}
              topicIds={values.topicIds}
              moveTopicDown={moveTopicDown}
              moveTopicUp={moveTopicUp}
            />
          ))}
        </div>
      )}
    </div>
  );
}
