import React from "react";

export default function AccuracyIcon({ width }) {
  return (
    <svg
      className={`aspect-square  ${width || "w-4"}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
    >
      <circle fill="#DD2E44" cx={18} cy={18} r={18} />
      <circle fill="#FFF" cx={18} cy={18} r={13.5} />
      <circle fill="#DD2E44" cx={18} cy={18} r={10} />
      <circle fill="#FFF" cx={18} cy={18} r={6} />
      <circle fill="#DD2E44" cx={18} cy={18} r={3} />
      <path
        opacity={0.2}
        d="m18.24 18.282 13.144 11.754s-2.647 3.376-7.89 5.109L17.579 18.42l.661-.138z"
      />
      <path
        fill="#FFAC33"
        d="M18.294 19a.994.994 0 0 1-.704-1.699l.563-.563a.995.995 0 0 1 1.408 1.407l-.564.563a.987.987 0 0 1-.703.292z"
      />
      <path
        fill="#55ACEE"
        d="M24.016 6.981c-.403 2.079 0 4.691 0 4.691l7.054-7.388c.291-1.454-.528-3.932-1.718-4.238-1.19-.306-4.079.803-5.336 6.935zm5.003 5.003c-2.079.403-4.691 0-4.691 0l7.388-7.054c1.454-.291 3.932.528 4.238 1.718.306 1.19-.803 4.079-6.935 5.336z"
      />
      <path
        fill="#3A87C2"
        d="M32.798 4.485 21.176 17.587c-.362.362-1.673.882-2.51.046-.836-.836-.419-2.08-.057-2.443L31.815 3.501s.676-.635 1.159-.152-.176 1.136-.176 1.136z"
      />
    </svg>
  );
}
