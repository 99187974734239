import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { customerClient } from "../../GraphqlApolloClients";
import { CustomerAuthContext } from "../../context/customerAuth";
import { GuestAuthContext } from "../../context/guestAuth";

import {
  ACCEPT_CUST_SUB_PLAN_INVITE,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_SUBSCRIPTION,
  FETCH_FRIENDS_BY_SUBSCRIPTION,
  VERIFY_CUSTOMER,
} from "../../util/gqlFunctions";
import SubmitButton from "./SubmitButton";

export default function CodeForm({
  title,
  name,
  isCodeBoxOpen,
  setIsCodeBoxOpen,
  typeOfCode,
  navigate,
  toggleJoinedProPlanPopup,
  values,
  setValues,
  prevPath,
  caption,
  linkCaption,
  linkFunction,
}) {
  const { guest, logoutGuest } = useContext(GuestAuthContext);
  const { customer, loginCustomer } = useContext(CustomerAuthContext);

  const [hasBeenOnOnce, setHasBeenOnOnce] = useState(false);
  const [errors, setErrors] = useState({});

  const [
    acceptCustomerSubscriptionPlanInvite,
    { loading: loadingAcceptCustomerSubPlanInvite },
  ] = useMutation(ACCEPT_CUST_SUB_PLAN_INVITE, {
    refetchQueries: [
      { query: FETCH_CUSTOMER },
      { query: FETCH_CUSTOMER_SUBSCRIPTION, variables: { status: 1 } },
      { query: FETCH_FRIENDS_BY_SUBSCRIPTION },
    ],
    update(
      proxy,
      { data: { acceptCustomerSubscriptionPlanInvite: acceptedCustSub } }
    ) {
      setValues({ ...values, inviteCode: "" });

      toggleJoinedProPlanPopup();
    },
    onError(err) {
      console.log(err);
      setValues({ ...values, inviteCode: "" });

      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: customerClient,
  });
  function acceptCustomerSubscriptionPlanInviteCallback() {
    setErrors({});

    acceptCustomerSubscriptionPlanInvite();
  }

  const [verifyCustomer, { loading: loadingVerifyCustomer }] = useMutation(
    VERIFY_CUSTOMER,
    {
      refetchQueries: [{ query: FETCH_CUSTOMER }],
      update(proxy, { data: { verifyCustomer: tokenizedCustomer } }) {
        if (guest) {
          logoutGuest();
        }
        if (!customer) {
          loginCustomer(tokenizedCustomer);
        }
        // setIsCodeBoxOpen(false);
        navigate(
          "/completeRegistration/0" + (prevPath ? "/redirect/" + prevPath : "")
        );
      },
      onError(err) {
        // setValues({ ...values, verificationCode: "" });

        console.log(err);
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },

      client: customerClient,
    }
  );

  useEffect(() => {
    if (isCodeBoxOpen && !hasBeenOnOnce) {
      setHasBeenOnOnce(true);
    }
  }, [isCodeBoxOpen, hasBeenOnOnce]);

  // useEffect(() => {
  //   if (typeOfCode === 1 && customer) {
  //     navigate(
  //       "/completeRegistration/0" + (prevPath ? "/redirect/" + prevPath : "")
  //     );
  //   }
  // }, [typeOfCode, prevPath, navigate, customer]);

  const firstCharRef = useRef();
  const secondCharRef = useRef();
  const thirdCharRef = useRef();
  const fourthCharRef = useRef();
  const fifthCharRef = useRef();
  const sixthCharRef = useRef();
  const inputRefs = [
    firstCharRef,
    secondCharRef,
    thirdCharRef,
    fourthCharRef,
    fifthCharRef,
    sixthCharRef,
  ];
  const [initializedCodeModal, setInitializedCodeModal] = useState(false);
  const handleInputChange = (event, index) => {
    var newValue = event.target.value ? event.target.value.trim() : " ";
    if (
      values[name][index] &&
      values[name][index] !== " " &&
      newValue.length > 1 &&
      newValue &&
      newValue !== " "
    ) {
      var tempVal =
        newValue.length === 2 && newValue[0] === newValue[1] ? newValue[0] : "";

      newValue = newValue.split(values[name][index]).join("") || tempVal;
    }
    // Update the values in your state or wherever you store them
    const updatedValues = [...values[name].split("")];
    updatedValues[index] = newValue;

    // Update the state with the new values
    setValues({
      ...values,
      [name]: updatedValues.join("").slice(0, 6).toUpperCase(),
    });

    // Move focus to the next input if available
    if (newValue && newValue !== " " && index < 5) {
      const nextInputRef = index < 5 ? inputRefs[index + 1] : null;
      if (nextInputRef) {
        nextInputRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (!initializedCodeModal && isCodeBoxOpen) {
      if (firstCharRef.current) {
        firstCharRef.current.focus();
      }

      setInitializedCodeModal(true);
    }
  }, [initializedCodeModal, isCodeBoxOpen, typeOfCode, firstCharRef]);

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text");
    const formattedPastedText = pastedText.slice(0, 6).toUpperCase(); // Restrict to the first 6 characters

    if (formattedPastedText && formattedPastedText.length === 6) {
      setValues({ ...values, [name]: formattedPastedText });
    }

    if (typeOfCode === 0) {
      setErrors({});
      acceptCustomerSubscriptionPlanInvite({
        variables: {
          inviteCode: formattedPastedText,
        },
      });
    } else if (typeOfCode === 1) {
      setErrors({});

      verifyCustomer({
        variables: {
          ...values,
          [name]: formattedPastedText,
        },
      });
    } else {
      setErrors({});
      // applyReferralCode({
      //   variables: {
      //     email: values.email,
      //     referralCode: formattedPastedText,
      //   },
      // });
    }
  };

  return (
    <div
      className={`pointer-events-none fixed pt-16 inset-0 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          setIsCodeBoxOpen(false);
        }}
        className={`${
          isCodeBoxOpen
            ? "motion-safe:animate-fadeInFast"
            : hasBeenOnOnce
            ? "motion-safe:animate-fadeOutFast"
            : "hidden"
        } pointer-events-auto bg-gray-500 hide-button-flash bg-opacity-10 overscroll-contain motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute  backdrop-blur-sm`}
        disabled={false}
      />
      <div
        className={`${
          isCodeBoxOpen
            ? "motion-safe:animate-fadeUpFast pointer-events-auto"
            : hasBeenOnOnce
            ? "motion-safe:animate-fadeOutDownFast pointer-events-none"
            : "hidden pointer-events-none"
        } h-auto overflow-x-hidden max-h-full flex-shrink overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl ${
          errors[name] ? "sm:pb-5 p-8" : "p-8"
        } pt-0 short:px-4 short:pb-4 sm:p-10 bg-white shadow-xl rounded-b-none sm:rounded-3xl`}
      >
        <button
          onClick={(e) => {
            e.preventDefault();

            if (typeOfCode === 0) {
              toggleJoinedProPlanPopup();
            } else if (typeOfCode === 1) {
              setIsCodeBoxOpen(false);
            }
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>
        <p className="text-gray-700 text-2xl short:text-xl text-center font-bold w-full leading-tight short:leading-tight">
          {title.split(" (optional)")[0]}
        </p>
        {title === "Verification code" ? (
          <p className="text-gray-500 text-center w-full leading-tight short:text-sm">
            {caption || "Sent to your email"}
            {linkCaption ? ". " : <></>}
            {linkCaption ? (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  linkFunction();
                }}
                className="font-semibold text-blue-600 hover:text-blue-700 cursor-pointer"
              >
                {linkCaption}
              </span>
            ) : (
              <></>
            )}
          </p>
        ) : (
          <></>
        )}

        <div className="flex flex-1 flex-row uppercase items-start z-40 justify-center space-x-2 sm:space-x-4 mx-auto w-full my-8 short:my-5">
          {inputRefs.map((inputRef, index) => (
            <div className="w-16 h-14 sm:h-16" key={index}>
              <input
                className="w-full h-full flex flex-col uppercase items-center justify-center text-center short:px-2 px-4 sm:px-5 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                type="text"
                name=""
                ref={inputRef}
                value={
                  values[name] && values[name][index] ? values[name][index] : ""
                }
                onChange={(event) => handleInputChange(event, index)}
                onKeyDown={(event) => {
                  if (
                    event.key === "Backspace" &&
                    (!event.target.value || event.target.value === " ") &&
                    index > 0
                  ) {
                    const prevInputRef = inputRefs[index - 1];
                    if (prevInputRef) {
                      prevInputRef.current.focus();
                    }
                  }
                }}
                onPaste={handlePaste}
              />
            </div>
          ))}
        </div>

        <SubmitButton
          onClickFunc={(e) => {
            e.preventDefault();

            if (typeOfCode === 0) {
              acceptCustomerSubscriptionPlanInviteCallback();
            } else if (typeOfCode === 1) {
              verifyCustomer({
                variables: {
                  ...values,
                  phone:
                    values.phone && values.phoneCountryCode
                      ? values.phoneCountryCode + values.phone
                      : values.phone,
                },
              });
            } else {
              setIsCodeBoxOpen(false);
            }
          }}
          title={typeOfCode === 0 || typeOfCode === 1 ? "Submit" : "Apply"}
          errors={errors}
          disabled={loadingAcceptCustomerSubPlanInvite || loadingVerifyCustomer}
        />

        {errors && errors[name] ? (
          <p
            className={`${errors ? "text-red-600" : "text-gray-900"} ${
              errors[name] ? "" : "invisible"
            } text-center font-semibold leading-tight w-full  text-sm sm:text-base z-40 mt-4 sm:mb-0 mb-2`}
          >
            {errors[name].replace("- ", "")}
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
