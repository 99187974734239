import React, { useState } from "react";

export default function SelectTopicWSearchInputField({
  error,
  name,
  values,

  setValues,

  options,

  loading,

  title,

  value,
  disabled,
  topicInputValue,
  setTopicInputValue,
}) {
  const [isAutocompleteTopicNameOpen, setIsAutocompleteTopicNameOpen] =
    useState(false);

  return (
    <div className="w-full">
      {/* <div className="flex items-center justify-between text-sm sm:text-base">
        <label htmlFor="topic" className="font-semibold text-gray-700 ">
          {title} {error && <span className="text-red-500">{error}</span>}
        </label>
      </div> */}

      <div className="flex space-x-2 items-center justify-start w-full rounded-md  shadow-sm mt-1  pr-3 pl-2 py-2 border-gray-200 border focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
        {values.topicLogoUrl && topicInputValue ? (
          <img
            className="w-8 h-8 object-contain rounded bg-black"
            src={values.topicLogoUrl}
            alt={"Org"}
          />
        ) : (
          <div className="w-8 h-8 rounded bg-gray-200"></div>
        )}
        <input
          name="topic"
          className={`sm:text-lg w-full flex-1 focus:outline-none`}
          type={"text"}
          value={topicInputValue}
          onChange={(e) => {
            const value = e.target.value;
            setTopicInputValue(value);
            setValues({
              ...values,
              searchTerm: value,
              topicLogoUrl: !value ? null : values.topicLogoUrl,
            });

            setIsAutocompleteTopicNameOpen(true);
          }}
          autoComplete="off"
          placeholder={"SCH 4U or Grade 9 Science"}
          // disabled={loading}
          maxLength={200}
          list={"topics"}
        />
      </div>
      {topicInputValue && isAutocompleteTopicNameOpen ? (
        <div className="relative w-full">
          <div className="max-h-36 w-full overflow-y-auto absolute bg-white">
            {options &&
              options
                // .filter((topic) =>
                //   topic.name
                //     .toLowerCase()
                //     .includes(topicInputValue.toLowerCase())
                // )
                .map((topic, index) => (
                  <button
                    key={index}
                    onClick={(e) => {
                      setValues({
                        ...values,
                        topicId: topic.id,
                        topicLogoUrl: topic.logoUrl,
                        topicNames: [topic.name],
                      });

                      setTopicInputValue(topic.name);
                      setIsAutocompleteTopicNameOpen(false);
                    }}
                    className="w-full flex items-center justify-start space-x-2 text-lg hover:bg-gray-50 text-left p-2"
                  >
                    <img
                      className="w-8 rounded bg-black"
                      src={topic.logoUrl}
                      alt={topic.name}
                    />
                    <p>{topic.name}</p>
                  </button>
                ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
