import React, { useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate } from "react-router";
import BlueFullGradBack from "../../components/creator/BlueFullGradBack";
import TextInputField from "../../components/creator/TextInputField";

import { useMutation, useQuery } from "@apollo/client";
import { brandContactClient } from "../../GraphqlApolloClients";
import { useForm, useWindowSize } from "../../util/hooks";

import ProfilePicInputField from "../../components/creator/ProfilePicInputField";

import Confetti from "react-confetti";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import { BrandContactAuthContext } from "../../context/brandContactAuth";
import { EDIT_SELLER, FETCH_SELLER } from "../../util/gqlFunctions";

const countries = [
  "",
  "Argentina",
  "Australia",
  "Austria",
  "Belgium",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Chile",
  "China",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "China",
  "Hungary",
  "India",
  "Indonesia",
  "Ireland",
  "Italy",
  "Japan",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malaysia",
  "Malta",
  "Mexico",
  "Netherlands",
  "New Zealand",
  "Norway",
  "Pakistan",
  "Philippines",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "Serbia",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "Thailand",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
];

function SellerAccountSettings(props) {
  usePageTracking();

  let navigate = useNavigate();

  const { brandContact, logoutBrandContact: logout } = useContext(
    BrandContactAuthContext
  );
  useEffect(() => {
    if (!brandContact) {
      navigate("/loginSeller");
    }
  }, [brandContact, navigate]);

  // const { shouldUnsubscribe } = useParams();

  useEffect(() => {
    document.title = "Settings | TKnight";
  }, []);

  const [errors, setErrors] = useState({});

  const [valsAreInitialized, setValsAreInitialized] = useState(false);

  const { onChange, values, setValues, onChangeMedia } = useForm(
    editSellerCallback,
    {
      name: "",
      username: "",
      profilePicUrl: "https://tknight-media.s3.amazonaws.com/profile.png",
      email: "",
      phone: "",
      password: "",
      country: "",
      // remindersToReceive: [0, 1, 2],
      fieldsToEdit: [],
      oldPassword: "",
      birthday: null,
      gender: null,
    }
  );

  const { data: { getBrandContact: sellerInfo } = {}, loading: loadingSeller } =
    useQuery(FETCH_SELLER, {
      client: brandContactClient,
    });

  const [editBrandContact, { loading: loadingEditBrandContact }] = useMutation(
    EDIT_SELLER,
    {
      refetchQueries: [{ query: FETCH_SELLER }],
      update(_, { data: { editBrandContact: brandContactData } }) {
        setValues({
          ...values,
          name: brandContactData.name,
          email: brandContactData.email,
          phone: brandContactData.phone,
          country: brandContactData.country || "",
          fieldsToEdit: [],
          oldPassword: "",
          password: "",
          username: brandContactData.username,
          profilePicUrl: brandContactData.profilePicUrl,
          accentColor: brandContactData.accentColor || 0,
          remindersToReceive: brandContactData.remindersToReceive,
          birthday: brandContactData.birthday || null,
          gender: brandContactData.gender || null,
        });
        setErrors({});
        navigate("/sellerSettings");
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: brandContactClient,
    }
  );

  function editSellerCallback(e) {
    e.preventDefault();
    editBrandContact();
  }

  function setFieldsToEdit(name) {
    if (
      values.fieldsToEdit &&
      values.fieldsToEdit.includes(name) &&
      sellerInfo
    ) {
      if (name === "name") {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: sellerInfo.name || "",
        });
      } else {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: sellerInfo[name] || "",
        });
      }
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }

  const [checkedIsSellerLoggedIn, setCheckedIsSellerLoggedIn] = useState(false);

  useEffect(() => {
    if (
      brandContact &&
      !loadingSeller &&
      !sellerInfo &&
      !checkedIsSellerLoggedIn
    ) {
      logout();
      setCheckedIsSellerLoggedIn(true);
    }
  }, [
    brandContact,
    loadingSeller,
    sellerInfo,
    logout,
    checkedIsSellerLoggedIn,
  ]);

  useEffect(() => {
    if (
      !valsAreInitialized &&
      sellerInfo &&
      !loadingEditBrandContact &&
      !loadingSeller
    ) {
      setValues({
        ...values,
        name: sellerInfo.name,
        email: sellerInfo.email,
        phone: sellerInfo.phone,
        country: sellerInfo.country || "",
        oldPassword: "",
        username: sellerInfo.username,
        accentColor: sellerInfo.accentColor,
        profilePicUrl: sellerInfo.profilePicUrl,
        profilePicFile: null,

        remindersToReceive: sellerInfo.remindersToReceive,
        // birthday: sellerInfo.birthday || null,
        // gender: sellerInfo.gender,
      });
      setValsAreInitialized(true);
    }
  }, [
    brandContact,
    loadingSeller,
    values,
    setValues,
    sellerInfo,
    loadingEditBrandContact,
    valsAreInitialized,
  ]);
  // const [isUnsubscribedChecked, setIsUnsubscribedChecked] = useState(false);

  // useEffect(() => {
  //   if (
  //     shouldUnsubscribe === "unsubscribe" &&
  //     valsAreInitialized &&
  //     !isUnsubscribedChecked
  //   ) {
  //     editBrandContact({
  //       variables: {
  //         fieldsToEdit: ["remindersToReceive"],
  //         remindersToReceive: [],
  //       },
  //     });
  //     setValues({
  //       ...values,
  //       fieldsToEdit: ["remindersToReceive"],
  //       remindersToReceive: [],
  //     });
  //     setIsUnsubscribedChecked(true);
  //     setIsUnsubscribePopupOpen(true);
  //   }
  // }, [
  //   editBrandContact,
  //   shouldUnsubscribe,
  //   valsAreInitialized,
  //   values,
  //   setValues,
  //   isUnsubscribedChecked,
  // ]);

  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);

  const togglePaymentSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };

  const [isUnsubscribePopupOpen, setIsUnsubscribePopupOpen] = useState(false);

  const resetInterval = 3000;

  useEffect(() => {
    let timeout;
    if (isUnsubscribePopupOpen && resetInterval) {
      timeout = setTimeout(
        () => setIsUnsubscribePopupOpen(false),
        resetInterval
      );
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isUnsubscribePopupOpen, resetInterval]);

  // const handleChangeDate = (event) => {
  //   event.preventDefault();

  //   // Get the selected date and time in the user's timezone
  //   const localDate = moment(event.target.value).toDate();

  //   // Convert the local date and time to UTC
  //   const utcDate = moment.utc(localDate);

  //   // Save the UTC date and time to the state
  //   setValues({ ...values, birthday: utcDate });
  // };

  // const displayBirthday = () => {
  //   // Format and display the birthday value in the user's timezone
  //   return moment(values.birthday).local().format("YYYY-MM-DD");
  // };
  const { width, height } = useWindowSize();

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-gray-50">
      <BlueFullGradBack gradIndex={7} />

      {shouldOpenPaymentSuccessfulForm && sellerInfo && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
            targetCustomer={sellerInfo}
            message={"Welcome to TKnight Pro, " + sellerInfo.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      <div className="relative z-10 short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-10 lg:pb-0">
        {isUnsubscribePopupOpen ? (
          <div className="fixed px-4 sm:px-0 left-0 w-full sm:max-w-xl sm:inset-x-auto top-5 sm:top-4 z-50">
            <div className="w-full motion-safe:animate-fadeDown font-semibold text-center text-lg sm:max-w-xl rounded-lg py-2 bg-red-600 text-white">
              Unsubscribed from TN reminder emails
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="space-x-10 relative z-20 px-2 sm:px-5 pt-4 pb-6 short:py-2 text-white flex items-center justify-between w-full">
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/sellers");
            }}
            className="transition-opacity hover:opacity-50 py-1 focus:outline-none"
          >
            <svg
              className="h-5 fill-current "
              viewBox="0 0 140 140"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M69.7018 0C51.1365 0 33.3636 7.37493 20.2921 20.5025C7.22057 33.63 -0.0786027 51.4347 0.000638512 69.9999C0.0798797 88.5647 7.531 106.37 20.7136 119.497C33.8971 132.625 51.7337 140 70.2982 140C61.7401 140 53.3626 137.462 46.226 132.707C39.0902 127.952 33.5148 121.194 30.2062 113.287C26.8969 105.38 26.0032 96.679 27.637 88.2849C29.2709 79.8908 33.3595 72.1805 39.3851 66.1287C45.4116 60.077 53.1045 55.9556 61.491 54.286C69.8783 52.6163 78.5826 53.4732 86.5034 56.7484C94.4243 60.0236 101.206 65.57 105.991 72.6861C110.777 79.8019 113.35 88.1688 113.386 96.7275C113.435 108.204 108.923 119.21 100.842 127.325C92.7618 135.44 81.7751 140 70.2982 140C88.8635 140 106.636 132.625 119.708 119.497C132.779 106.37 140.079 88.5647 139.999 69.9999C139.92 51.4347 132.469 33.63 119.286 20.5025C106.103 7.37493 88.2671 0 69.7018 0Z" />
            </svg>
          </button>

          <button
            onClick={(e) => {
              navigate("/sellerDashboard");
            }}
            className="group flex-shrink-0 flex hover:bg-opacity-30 transition-all items-center justify-center space-x-2 bg-white bg-opacity-20 rounded-full backdrop-blur-sm px-5 py-2.5"
          >
            <svg
              className="h-4"
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.333252 10.5835L11.9999 0.0834961L23.6666 10.5835H20.1666V19.9168H13.1666V12.9168H10.8333V19.9168H3.83325V10.5835H0.333252ZM17.8333 8.47183L11.9999 3.22183L6.16659 8.47183V17.5835H8.49992V10.5835H15.4999V17.5835H17.8333V8.47183Z"
                fill="white"
              />
            </svg>
          </button>
        </div>

        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-10 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
            Seller account settings
          </p>

          <div className="w-full flex flex-col items-start justify-start space-y-6 my-8 short:mb-4">
            <TextInputField
              title="Username"
              name="username"
              value={values.username}
              onChange={onChange}
              error={errors.username}
              disabled={loadingSeller || loadingEditBrandContact}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBrandContact}
              prefix="@"
              maxLen={30}
            />

            <div className="flex w-full items-start justify-start space-x-8 short:space-x-3">
              <ProfilePicInputField
                title="Profile picture"
                name="profilePicFile"
                onChangeMedia={onChangeMedia}
                value={values.profilePicUrl}
                editFunc={editBrandContact}
                values={values}
                error={errors.profilePicFile}
              />
              <div className="flex items-start flex-col justify-start space-y-2 sm:text-base text-sm">
                <label className="font-semibold text-gray-700">
                  Accent colour
                </label>
                <div className="grid grid-cols-4 sm:grid-cols-6 w-full gap-2 h-full">
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                    (colour, index) => (
                      <button
                        className={`${
                          index === values.accentColor
                            ? "border-black border-4 box-border"
                            : "hover:border-gray-200 hover:border-4"
                        } sm:w-10 aspect-square w-8 rounded-full bg-accent-${index} bg-gradient-to-br from-[--gradient-color-1] via-purple-500 to-pink-500 transition-all`}
                        onClick={(e) => {
                          e.preventDefault();

                          editBrandContact({
                            variables: {
                              fieldsToEdit: ["accentColor"],
                              accentColor: index,
                            },
                          });
                          // Gradient.apply
                        }}
                        key={index}
                      ></button>
                    )
                  )}
                </div>
              </div>
            </div>
            <TextInputField
              title="Name"
              name="name"
              value={values.name}
              onChange={onChange}
              error={errors.name}
              disabled={loadingSeller || loadingEditBrandContact}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBrandContact}
            />
            <TextInputField
              title="Email"
              name="email"
              type="email"
              value={values.email}
              onChange={onChange}
              error={errors.email}
              disabled={loadingSeller || loadingEditBrandContact}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBrandContact}
            />

            <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label
                  htmlFor="country"
                  className="font-semibold text-gray-700 "
                >
                  Country{" "}
                  {errors.country &&
                    (!values.fieldsToEdit ||
                      (values.fieldsToEdit &&
                        values.fieldsToEdit.includes("country"))) && (
                      <span className="text-red-500">{errors.country}</span>
                    )}
                </label>
                {values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["country"],
                      });
                    }}
                    className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                  >
                    Edit
                  </button>
                )}
                {values.fieldsToEdit.includes("country") && (
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldsToEdit("country");
                      }}
                      className="text-red-500 transition-all font-semibold hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        editBrandContact();
                      }}
                      className="text-green-500 transition-all font-semibold hover:text-green-700"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
              <select
                id="country"
                name="country"
                autoComplete="country-name"
                value={values.country}
                onChange={onChange}
                disabled={
                  loadingSeller ||
                  loadingEditBrandContact ||
                  (values.fieldsToEdit &&
                    !values.fieldsToEdit.includes("country"))
                }
                className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
              >
                {countries.map((countryName, index) => (
                  <option key={index} value={countryName}>
                    {countryName}
                  </option>
                ))}
              </select>
            </div>

            {/* <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label
                  htmlFor="birthday"
                  className="font-semibold text-gray-700 "
                >
                  Birthday{" "}
                  {errors.birthday &&
                    (!values.fieldsToEdit ||
                      (values.fieldsToEdit &&
                        values.fieldsToEdit.includes("birthday"))) && (
                      <span className="text-red-500">{errors.birthday}</span>
                    )}
                </label>
                {values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["birthday"],
                      });
                    }}
                    className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                  >
                    Edit
                  </button>
                )}
                {values.fieldsToEdit.includes("birthday") && (
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldsToEdit("birthday");
                      }}
                      className="text-red-500 transition-all font-semibold hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        editBrandContact();
                      }}
                      className="text-green-500 transition-all font-semibold hover:text-green-700"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>

              <input
                type="date"
                id="date"
                min={moment(new Date())
                  .subtract(100, "years")
                  .format("YYYY-MM-DD")}
                // max={moment(new Date())
                //   .subtract(8, "years")
                //   .format("YYYY-MM-DD")}
                className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
                value={displayBirthday()}
                onChange={handleChangeDate}
                disabled={
                  loadingEditBrandContact ||
                  (values.fieldsToEdit &&
                    !values.fieldsToEdit.includes("birthday"))
                }
              />
            </div> */}
            {/* <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label
                  htmlFor="gender"
                  className="font-semibold text-gray-700 "
                >
                  Gender{" "}
                  {errors.gender &&
                    (!values.fieldsToEdit ||
                      (values.fieldsToEdit &&
                        values.fieldsToEdit.includes("gender"))) && (
                      <span className="text-red-500">{errors.gender}</span>
                    )}
                </label>
                {values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["gender"],
                      });
                    }}
                    className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                  >
                    Edit
                  </button>
                )}
                {values.fieldsToEdit.includes("gender") && (
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldsToEdit("gender");
                      }}
                      className="text-red-500 transition-all font-semibold hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={editSellerCallback}
                      className="text-green-500 transition-all font-semibold hover:text-green-700"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
              <select
                id="gender"
                name="gender"
                // autoComplete=""
                value={values.gender}
                onChange={(e) => {
                  onChange(e, true);
                }}
                disabled={
                  loadingEditBrandContact ||
                  (values.fieldsToEdit &&
                    !values.fieldsToEdit.includes("gender"))
                }
                className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value={null}></option>

                <option value={0}>Boy</option>
                <option value={1}>Girl</option>
                <option value={2}>Non-binary</option>
              </select>
            </div> */}
            <TextInputField
              title="Phone"
              name="phone"
              type="tel"
              value={values.phone}
              onChange={onChange}
              error={errors.phone}
              disabled={loadingSeller || loadingEditBrandContact}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBrandContact}
            />

            <TextInputField
              title="Password"
              name="password"
              type="password"
              value={values.password}
              oldPasswordVal={values.oldPassword}
              onChange={onChange}
              error={errors.password}
              placeholder="••••••••••"
              disabled={loadingSeller || loadingEditBrandContact}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBrandContact}
            />
          </div>
        </div>

        <p className="text-gray-500 transition-all short:px-3 px-6 font-semibold mb-10 short:mb-4 text-sm sm:text-base">
          <button
            className="hover:text-gray-700 font-semibold transition-all"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            © TKnight
          </button>{" "}
          <span className="text-gray-200 mx-1 sm:mx-2"> - </span>
          <a
            className="hover:text-gray-700 font-semibold transition-all"
            href={"mailto:info@tknight.live"}
            target="_blank"
            rel="noreferrer"
          >
            Contact
          </a>
          <span className="text-gray-200 mx-1 sm:mx-2"> - </span>
          <button
            className="hover:text-gray-700 font-semibold transition-all"
            onClick={(e) => {
              // e.preventDefault();
              logout();
            }}
          >
            Logout
          </button>
        </p>
      </div>
    </div>
  );
}

export default SellerAccountSettings;
